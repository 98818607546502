import CONFIG from './config.js';

const API_PENGURUS_PP_ENDPOINT = {
	
	IP_SERVER: `${CONFIG.BASE_URL}login/ipServer`,
	LOGIN: (role) => `${CONFIG.BASE_URL}login/${role}`,
	LOGOUT : `${CONFIG.BASE_URL}logout`,
	GET_ANGGOTA_BY_ID: (uuid) => `${CONFIG.BASE_URL}users/${uuid}`,
	GET_ANGGOTA_LOG: (uuid) => `${CONFIG.BASE_URL}log/user/${uuid}`,
	ROLE_USERS_DET : (role_name, id_cabang, id_daerah) => `${CONFIG.BASE_URL}roles/users?role_name=${role_name}&id_cabang=${id_cabang}&id_daerah=${id_daerah}`,
	REMOVE_ROLE : (id) => `${CONFIG.BASE_URL}roles/${id}/remove`,
	RESTORE_ANGGOTA : `${CONFIG.BASE_URL}users/restore`,

	APPROVE_BLANGKO : `${CONFIG.BASE_URL}blangko/approval`,

	GET_REQ_BLANGKO_PP : (status, startDate, endDate) => `${CONFIG.BASE_URL}blangko/list/pp?start_date=${startDate}&end_date=${endDate}&status=${status}`,
	GET_REPORT_BLANGKO : `${CONFIG.BASE_URL}blangko/report`,
    
	PENGAJUAN_KTAN_DEFAULT: `${CONFIG.BASE_URL}pengajuan/admin/pp?jenis_pengajuan=membership`,
	PENGAJUAN_KTAN_FILTERED: (idCabang,idDaerah,status) => `${CONFIG.BASE_URL}pengajuan/admin/pp?jenis_pengajuan=membership&id_cabang=${idCabang}&id_daerah=${idDaerah}&status=${status}`,
	APPROVE_KTAN:  (pengajuanID) => `${CONFIG.BASE_URL}pengajuan/approval/pp/${pengajuanID}`,
	UPDATE_ANGGOTA_PP_BY_ID:  (uuid) => `${CONFIG.BASE_URL}users/${uuid}`,
	APPROVE_REGISTRATION:  (registrationID) => `${CONFIG.BASE_URL}approval/paid/register/${registrationID}`,
	APPROVE_All_KTAN: `${CONFIG.BASE_URL}pengajuan/approval/pp/bulk`,
	APPROVE_All_RREGISTRATION: `${CONFIG.BASE_URL}approval/paid/register/bulk`,
	GET_All_ROLE: `${CONFIG.BASE_URL}roles`,
	ASSIGN_ROLE: (roleId) => `${CONFIG.BASE_URL}roles/${roleId}/assign`,
	GET_USER_BY_KTAN: (IdKTAN) => `${CONFIG.BASE_URL}users/ktan/${IdKTAN}`,
	SEND_COMMENT: `${CONFIG.BASE_URL}comment/admin/pp`,
	GET_COMMENT_PENGAJUAN: (userID,commentFor,pengajuanID) => `${CONFIG.BASE_URL}comment/admin/${userID}/${commentFor}/${pengajuanID}`,
	
	REGISTRASI_AKUN: (statusRegis,idCabang,idDaerah,statusInvoice) => `${CONFIG.BASE_URL}users/register/pusat?status_regis=${statusRegis}&id_cabang=${idCabang}&id_daerah=${idDaerah}&status_invoice=${statusInvoice}`,
	REGISTRASI_AKUN_OLD: (statusRegis,idCabang,idDaerah) => `${CONFIG.BASE_URL}users/register/old?status_regis=${statusRegis}&id_cabang=${idCabang}&id_daerah=${idDaerah}`,

	
	APPROVE_REGIS_OLD:  (pengajuanID) => `${CONFIG.BASE_URL}approval/register/old/${pengajuanID}`,

	GET_ALL_USER_KTAN_1: (status_ktan,id_cabang,id_daerah, jenjang, status_regist, jenisPekerjaan) => `${CONFIG.BASE_URL}users/pusat?status_regis=${status_regist}&status_ktan=${status_ktan}&id_cabang=${id_cabang}&id_daerah=${id_daerah}&jenjang=${jenjang}&jenis_tempat_kerja=${jenisPekerjaan}`,
	GET_BLOCK_ANGGOTA : (id_cabang, id_daerah) => `${CONFIG.BASE_URL}users/pusat/archive?id_cabang=${id_cabang}&id_daerah=${id_daerah}`,
	
	PERIODE_KEPENGURUSAN: `${CONFIG.BASE_URL}setting/kepengurusan/periode/pp`,
	PERIODE_KEPENGURUSAN_BY_ID: (id) => `${CONFIG.BASE_URL}setting/kepengurusan/periode/${id}`,
	UPDATE_PERIODE_KEPENGURUSAN: (id) => `${CONFIG.BASE_URL}setting/kepengurusan/periode/pp/${id}`,
	DELETE_PERIODE_KEPENGURUSAN: (id) => `${CONFIG.BASE_URL}setting/kepengurusan/periode/${id}`,

	
	ANGGOTA: (status_regis,status_ktan) => `${CONFIG.BASE_URL}users/pusat?status_regis=${status_regis}&status_ktan=${status_ktan}`,
	
	
	PENGURUS : `${CONFIG.BASE_URL}setting/kepengurusan/pengurus/pp`,
	PENGURUS_BY_ID : (id) => `${CONFIG.BASE_URL}setting/kepengurusan/pengurus/${id}`,
	UPDATE_PENGURUS: (id) => `${CONFIG.BASE_URL}setting/kepengurusan/pengurus/pp/${id}`,
	DELETE_PENGURUS: (id) => `$s{CONFIG.BASE_URL}setting/kepengurusan/pengurus/${id}`,
	UPDATE_TTD: (id) => `${CONFIG.BASE_URL}setting/kepengurusan/pengurus/media/${id}`,
	GET_SETTING_GENERAL:`${CONFIG.BASE_URL}setting/pusat/general`,
	UPDATE_GENERAL_SETTING: (id) =>`${CONFIG.BASE_URL}setting/pusat/general/${id}`,
	GET_SETTING_BIAYA_SEMINAR:`${CONFIG.BASE_URL}setting/pusat/general/seminar_invoice_pd`,
	GET_REPORT_BY_PENDIDIKAN:(filter, role) => `${CONFIG.BASE_URL}users/${role}/${filter}`,
};

export default API_PENGURUS_PP_ENDPOINT;
