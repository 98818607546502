
import API_ENDPOINT_PENGURUS_PP from "../../config/globals/menu_pengurus_pp_endpoint.js";
import NotificationModal from "../../utils/initial_notification.js";
import CONFIG from "../../config/globals/config.js";
import ApiPengajuanKTANPP from '../../api/api_pengurus_pp/pengajuan-ktan.js';
import ApiRegister from "../../api/data-register.js";
import ApiKepengurusanPP from "../../api/api_pengurus_pp/kepengurusan-pp.js";

const AnggotaPPPresenter = {

	async init() {
		// here
		await StorageAnggotaPP.makeStore()
		await StorageAnggotaPPRegis.makeStore()
		await this._setView();
		await this._getDataPrivate();
		await this._getPengajuanKTAN();
		await this._getPengajuanRegistrasiFiltered();
		await this._getPengajuanRegistrasiOld();
		await this._checkAllEvent();
		await this._checkAllEventRegis();
		await this._approvePengajuanKTAN();
		await this._approvePengajuanRegistrasi();
		await this._initAllApprove()
		await this._initAllApproveRegis();
		await this._approveRegistrasiOld();
		await this._initCommentKTAN();
	},


	async _setView() {
		document.getElementById('main-content').classList.remove('bg-gradient-primary');
		document.getElementById('main-content').style.removeProperty("height");
		document.getElementById('main-content').style.removeProperty("padding-top");
		document.getElementById('content-wrapper').style.removeProperty("overflow-x");
		document.getElementById('appbar').classList.remove('d-none');
		document.getElementById('accordionSidebar').classList.remove('d-none');
		document.getElementById("main-content").style.height = "";
	},

	async _getPengajuanRegistrasiOld() {
		$("#pengajuan-registrasi-old-tab").one("click", async function () {
			mRefresh.refresh()
		var id_cabang = '';
		var id_daerah = '';
		var status_regis = 0;
		var status_ktan = 'All';
		var savedOrderColumn = 'name';
		var savedOrderDirection = 'asc';

		let table = await $('#tablePengajuanRegistrasiOld').DataTable({
			"pageLength": 10,
			"lengthMenu": [10, 25, 50, 100],
			"processing": true,
			"destroy": true,

			dom: 'lBfrtip<"actions">',
			buttons: [
				{
					extend: 'copy',
					text: '<i class="fas fa-copy"></i>',
					exportOptions: {
						columns: ':visible'
					},
					className: "btn btn-light border-primary",
					titleAttr: "Copy to clipboard"
				},
				{
					extend: 'csv',
					text: '<i class="fas fa-file-csv"></i>',
					exportOptions: {
						columns: ':visible'
					},
					className: "btn btn-light border-primary",
					titleAttr: "Download file csv"
				},
				{
					extend: 'excel',
					text: '<i class="fas fa-file-excel"></i>',
					exportOptions: {
						columns: ':visible'
					},
					className: "btn btn-light border-primary",
					titleAttr: "Download file excel"
				},
				{
					extend: 'pdf',
					text: '<i class="fas fa-file-pdf"></i>',
					exportOptions: {
						columns: ':visible'
					},
					className: "btn btn-light border-primary",
					titleAttr: "Download file pdf"
				},
				{
					extend: 'print',
					text: '<i class="fas fa-print"></i>',
					exportOptions: {
						columns: ':visible'
					},
					className: "btn btn-light border-primary",
					titleAttr: "Print",
				},
			],
			'language': {
				'loadingRecords': '&nbsp;',
				'processing': '<div class="spinner"></div>'
			},
			"serverSide": true,
			"searchDelay": 1000,
			"ajax": {
				"url": `${API_ENDPOINT_PENGURUS_PP.REGISTRASI_AKUN_OLD(status_regis, id_cabang, id_daerah)}`,
				"type": "GET",
				"beforeSend": function (xhr) {
					xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem(CONFIG.TOKEN_USER));
				},
				"data" : function (d) {
					d.orderColumn = savedOrderColumn;
    				d.orderDirection = savedOrderDirection;
				}
			},

			"columns": [
				{ data: 'created_at' },
				{ data: 'name' },
				{ data: 'phone_number', render : function (data) {
					let content = ``;
					if (data != null) {	
						let nomorTeleponBaru = data.replace(/^0/, "62");
						content = `${data} <a href="https://api.whatsapp.com/send?phone=${nomorTeleponBaru}" class="text-success" target="blank()"><i class="fab fa-whatsapp"></i> Chat</a>`;
					}else {
						content = '-';
					}

					return content
				}},
				{ data: 'ktan' },
				{ data: 'nik' },
				{ data: 'email' },
				{ data: 'daerah_name' },
				{ data: 'cabang_name' },
				{ data: 'ktan_expires_at' },
				{
					data: 'old_ktan',
					render: function (data) {
						if (data == null || data == "") {
							return '-'
						} else {
							return '<img loading="lazy" id="show" src="' + data + '" onerror="this.onerror=null; this.src=\'./src/public/img/image-corrupt-min.png\';" data-original="' + data + '" width="40px">'
						}
					}
				},
				{ data: 'periode_iuran.bulan_iuran_awal' },
				{ data: 'periode_iuran.bulan_iuran_akhir' },
				{
					data: 'bukti_iuran_terakhir',
					render: function (data) {
						if (data == null || data == "") {
							return '-'
						} else {
							return '<img loading="lazy" id="show" src="' + data + '" onerror="this.onerror=null; this.src=\'./src/public/img/image-corrupt-min.png\';" data-original="' + data + '" width="40px">'
						}
					}
				},
				{
					data: 'status_pc', render: function (data) {
						if (data == 'pending') {
							return `
							<div class="badge badge-warning text-capitalize">${data}</div>
						`
						} else {
							return `
							<div class="badge badge-success text-capitalize">${data}</div>
						`
						}
					}
				},
				{
					data: 'status_pc', render: function (data) {
						if (data == 'pending') {
							return `
						<div style="display:flex">
							<button style="margin:1px" class='btn btn-primary btn-sm' id='approve' title='Approve'><i class='fas fa-check'></i></button>
							<button style="margin:1px" class='btn btn-danger btn-sm' data-toggle="modal" data-target="#commentModalRegistOld" id='rejectedRegist' title='Tolak Regitrasi'><i class='fas fa-times'></i></button>
						</div>
						`
						} else {
							return `
						<button style="margin:1px" class='btn btn-danger btn-sm' data-toggle="modal" data-target="#commentModalRegistOld" id='rejectedRegist' title='Tolak Regitrasi'><i class='fas fa-times'></i></button>
						`
						}
					}
				},




			],
			"initComplete": function () {
				$('#tablePengajuanRegistrasiOld_filter input').unbind();
					$('#tablePengajuanRegistrasiOld_filter input').bind('keyup', function(e) {
						if(e.keyCode == 13) {
							table.search($('#tablePengajuanRegistrasiOld_filter input').val()).draw();
						}
					}); 
			},
			select: {
				style: 'multi',
				selector: 'td:first-child'
			},
			"columnDefs": [
				{
					"targets": [0],
					"visible": true,
					"searchable": true,
					"orderable": true
				},
				{
					"targets": [3,5,6,7,8,9,10,11,13],
					"orderable": false
				},
				{
					"targets": [1],
					"visible": true,
					"type": "datetime"
				},
				{
					"targets": 3,
					className: 'd-none',
					"visible": false
				},
			]
		});


		$("#selectPengajuanRegisPCOld").on("change", async function (e) {
			id_cabang = $(this).val();
			let table = await $('#tablePengajuanRegistrasiOld').DataTable({
				"pageLength": 10,
				"lengthMenu": [10, 25, 50, 100],
				"processing": true,
				"destroy": true,

				dom: 'lBfrtip<"actions">',
				buttons: [
					{
						extend: 'copy',
						text: '<i class="fas fa-copy"></i>',
						exportOptions: {
							columns: ':visible'
						},
						className: "btn btn-light border-primary",
						titleAttr: "Copy to clipboard"
					},
					{
						extend: 'csv',
						text: '<i class="fas fa-file-csv"></i>',
						exportOptions: {
							columns: ':visible'
						},
						className: "btn btn-light border-primary",
						titleAttr: "Download file csv"
					},
					{
						extend: 'excel',
						text: '<i class="fas fa-file-excel"></i>',
						exportOptions: {
							columns: ':visible'
						},
						className: "btn btn-light border-primary",
						titleAttr: "Download file excel"
					},
					{
						extend: 'pdf',
						text: '<i class="fas fa-file-pdf"></i>',
						exportOptions: {
							columns: ':visible'
						},
						className: "btn btn-light border-primary",
						titleAttr: "Download file pdf"
					},
					{
						extend: 'print',
						text: '<i class="fas fa-print"></i>',
						exportOptions: {
							columns: ':visible'
						},
						className: "btn btn-light border-primary",
						titleAttr: "Print",
					},
				],
				'language': {
					'loadingRecords': '&nbsp;',
					'processing': '<div class="spinner"></div>'
				},
				"serverSide": true,
				"searchDelay": 1000,
				
				"ajax": {
					"url": `${API_ENDPOINT_PENGURUS_PP.REGISTRASI_AKUN_OLD(status_regis, id_cabang, id_daerah)}`,
					"type": "GET",
					"beforeSend": function (xhr) {
						xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem(CONFIG.TOKEN_USER));
					}
				},

				"columns": [
					{ data: 'created_at' },
					{ data: 'name' },
					{ data: 'phone_number', render : function (data) {
						let content = ``;
						if (data != null) {	
							let nomorTeleponBaru = data.replace(/^0/, "62");
							content = `${data} <a href="https://api.whatsapp.com/send?phone=${nomorTeleponBaru}" class="text-success" target="blank()"><i class="fab fa-whatsapp"></i> Chat</a>`;
						}else {
							content = '-';
						}
	
						return content
					}},
					{ data: 'ktan' },
					{ data: 'nik' },
					{ data: 'email' },
					{ data: 'daerah_name' },
					{ data: 'cabang_name' },
					{ data: 'ktan_expires_at' },
					{
						data: 'old_ktan',
						render: function (data) {
							if (data == null || data == "") {
								return '-'
							} else {
								return '<img loading="lazy" id="show" src="' + data + '" onerror="this.onerror=null; this.src=\'./src/public/img/image-corrupt-min.png\';" data-original="' + data + '" width="40px">'
							}
						}
					},
					{ data: 'periode_iuran.bulan_iuran_awal' },
					{ data: 'periode_iuran.bulan_iuran_akhir' },
					{
						data: 'bukti_iuran_terakhir',
						render: function (data) {
							if (data == null || data == "") {
								return '-'
							} else {
								return '<img loading="lazy" id="show" src="' + data + '" onerror="this.onerror=null; this.src=\'./src/public/img/image-corrupt-min.png\';" data-original="' + data + '" width="40px">'
							}
						}
					},
					{
						data: 'status_pc', render: function (data) {
							if (data == 'pending') {
								return `
								<div class="badge badge-warning text-capitalize">${data}</div>
							`
							} else {
								return `
								<div class="badge badge-success text-capitalize">${data}</div>
							`
							}
						}
					},
					{
						data: 'status_pc', render: function (data) {
							if (data == 'pending') {
								return `
							<div style="display:flex">
								<button style="margin:1px" class='btn btn-primary btn-sm' id='approve' title='Approve'><i class='fas fa-check'></i></button>
								<button style="margin:1px" class='btn btn-danger btn-sm' data-toggle="modal" data-target="#commentModalRegistOld" id='rejectedRegist' title='Tolak Regitrasi'><i class='fas fa-times'></i></button>
							</div>
							`
							} else {
								return `
							<button style="margin:1px" class='btn btn-danger btn-sm' data-toggle="modal" data-target="#commentModalRegistOld" id='rejectedRegist' title='Tolak Regitrasi'><i class='fas fa-times'></i></button>
							`
							}
						}
					},




				],
				select: {
					style: 'multi',
					selector: 'td:first-child'
				},
				"initComplete": function () {
					$('#tablePengajuanRegistrasiOld_filter input').unbind();
						$('#tablePengajuanRegistrasiOld_filter input').bind('keyup', function(e) {
							if(e.keyCode == 13) {
								table.search($('#tablePengajuanRegistrasiOld_filter input').val()).draw();
							}
						}); 
				},
				"columnDefs": [
					{
						"targets": [0],
						"visible": true,
						"searchable": true,
						"orderable": true,
					},
					{
						"targets": [1],
						"visible": true,
						"type": "datetime"
					},
					{
						"targets": 3,
						className: 'd-none',
						"visible": false
					},
				]
			});


		});

		$("#selectPengajuanRegisPDOld").on("change", async function (e) {
			id_daerah = $(this).val();
			id_cabang = ''
			let table = await $('#tablePengajuanRegistrasiOld').DataTable({
				"pageLength": 10,
				"lengthMenu": [10, 25, 50, 100],
				"processing": true,
				"destroy": true,

				dom: 'lBfrtip<"actions">',
				buttons: [
					{
						extend: 'copy',
						text: '<i class="fas fa-copy"></i>',
						exportOptions: {
							columns: ':visible'
						},
						className: "btn btn-light border-primary",
						titleAttr: "Copy to clipboard"
					},
					{
						extend: 'csv',
						text: '<i class="fas fa-file-csv"></i>',
						exportOptions: {
							columns: ':visible'
						},
						className: "btn btn-light border-primary",
						titleAttr: "Download file csv"
					},
					{
						extend: 'excel',
						text: '<i class="fas fa-file-excel"></i>',
						exportOptions: {
							columns: ':visible'
						},
						className: "btn btn-light border-primary",
						titleAttr: "Download file excel"
					},
					{
						extend: 'pdf',
						text: '<i class="fas fa-file-pdf"></i>',
						exportOptions: {
							columns: ':visible'
						},
						className: "btn btn-light border-primary",
						titleAttr: "Download file pdf"
					},
					{
						extend: 'print',
						text: '<i class="fas fa-print"></i>',
						exportOptions: {
							columns: ':visible'
						},
						className: "btn btn-light border-primary",
						titleAttr: "Print",
					},
				],
				'language': {
					'loadingRecords': '&nbsp;',
					'processing': '<div class="spinner"></div>'
				},
				"serverSide": true,
				"searchDelay": 1000,
				"ajax": {
					"url": `${API_ENDPOINT_PENGURUS_PP.REGISTRASI_AKUN_OLD(status_regis, id_cabang, id_daerah)}`,
					"type": "GET",
					"beforeSend": function (xhr) {
						xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem(CONFIG.TOKEN_USER));
					}
				},

				"columns": [
					{ data: 'created_at' },
					{ data: 'name' },
					{ data: 'phone_number', render : function (data) {
						let content = ``;
						if (data != null) {	
							let nomorTeleponBaru = data.replace(/^0/, "62");
							content = `${data} <a href="https://api.whatsapp.com/send?phone=${nomorTeleponBaru}" class="text-success" target="blank()"><i class="fab fa-whatsapp"></i> Chat</a>`;
						}else {
							content = '-';
						}
	
						return content
					}},
					{ data: 'ktan' },
					{ data: 'nik' },
					{ data: 'email' },
					{ data: 'daerah_name' },
					{ data: 'cabang_name' },
					{ data: 'ktan_expires_at' },
					{
						data: 'old_ktan',
						render: function (data) {
							if (data == null || data == "") {
								return '-'
							} else {
								return '<img loading="lazy" id="show" src="' + data + '" onerror="this.onerror=null; this.src=\'./src/public/img/image-corrupt-min.png\';" data-original="' + data + '" width="40px">'
							}
						}
					},
					{ data: 'periode_iuran.bulan_iuran_awal' },
					{ data: 'periode_iuran.bulan_iuran_akhir' },
					{
						data: 'bukti_iuran_terakhir',
						render: function (data) {
							if (data == null || data == "") {
								return '-'
							} else {
								return '<img loading="lazy" id="show" src="' + data + '" onerror="this.onerror=null; this.src=\'./src/public/img/image-corrupt-min.png\';" data-original="' + data + '" width="40px">'
							}
						}
					},
					{
						data: 'status_pc', render: function (data) {
							if (data == 'pending') {
								return `
								<div class="badge badge-warning text-capitalize">${data}</div>
							`
							} else {
								return `
								<div class="badge badge-success text-capitalize">${data}</div>
							`
							}
						}
					},
					{
						data: 'status_pc', render: function (data) {
							if (data == 'pending') {
								return `
							<div style="display:flex">
								<button style="margin:1px" class='btn btn-primary btn-sm' id='approve' title='Approve'><i class='fas fa-check'></i></button>
								<button style="margin:1px" class='btn btn-danger btn-sm' data-toggle="modal" data-target="#commentModalRegistOld" id='rejectedRegist' title='Tolak Regitrasi'><i class='fas fa-times'></i></button>
							</div>
							`
							} else {
								return `
							<button style="margin:1px" class='btn btn-danger btn-sm' data-toggle="modal" data-target="#commentModalRegistOld" id='rejectedRegist' title='Tolak Regitrasi'><i class='fas fa-times'></i></button>
							`
							}
						}
					},




				],

				"initComplete": function () {
					$('#tablePengajuanRegistrasiOld_filter input').unbind();
						$('#tablePengajuanRegistrasiOld_filter input').bind('keyup', function(e) {
							if(e.keyCode == 13) {
								table.search($('#tablePengajuanRegistrasiOld_filter input').val()).draw();
							}
						}); 
				},

				select: {
					style: 'multi',
					selector: 'td:first-child'
				},
				"columnDefs": [{
					"targets": 0,
					"orderable": false,
					className: 'select-checkbox',
					"data": null,
					"defaultContent": ``
				},
				{
					"targets": [0],
					"visible": true,
					"searchable": false
				},
				{
					"targets": [1],
					"visible": true,
					"type": "datetime"
				},
				{
					"targets": 3,
					className: 'd-none',
					"visible": false
				},
				]
			});



		})


		$(".btn-jenis-permohonan-regis-old").on("click", async function () {

			$("#selectPengajuanRegisPDOld").val("");
			$("#selectPengajuanRegisPCOld").val("");
			status_regis = $(this).val();
			var startDate = '';
			var endDate = '';
			var status_text = '';
			let buttonAllElement = document.querySelectorAll('.btn-jenis-permohonan-regis-old')

			buttonAllElement.forEach((element) => {
				element.classList.remove('btn-primary');
				element.classList.add('btn-outline-primary');
			});

			this.classList.remove('btn-outline-primary')
			this.classList.add('btn-primary')


			if (status_regis == '1') {
				status_text = 'Approve'
				var bg = 'bg-success'
				$('#btnVerifikasiRegisAllDt').hide()
				var disabled = 'd-none';
			} else if (status_regis == '0') {
				status_text = 'Pending'
				var bg = 'bg-danger'
				$('#btnVerifikasiRegisAllDt').show()
			} else {
				status_text = 'Semua'
				var bg = 'bg-light text-dark border border-primary'
				$('#btnVerifikasiRegisAllDt').show()
			}

			await StorageAnggotaPPRegis.makeStore()

			let table = await $('#tablePengajuanRegistrasiOld').DataTable({
				"pageLength": 10,
				"lengthMenu": [10, 25, 50, 100],
				"processing": true,
				"destroy": true,

				dom: 'lBfrtip<"actions">',
				buttons: [
					{
						extend: 'copy',
						text: '<i class="fas fa-copy"></i>',
						exportOptions: {
							columns: ':visible'
						},
						className: "btn btn-light border-primary",
						titleAttr: "Copy to clipboard"
					},
					{
						extend: 'csv',
						text: '<i class="fas fa-file-csv"></i>',
						exportOptions: {
							columns: ':visible'
						},
						className: "btn btn-light border-primary",
						titleAttr: "Download file csv"
					},
					{
						extend: 'excel',
						text: '<i class="fas fa-file-excel"></i>',
						exportOptions: {
							columns: ':visible'
						},
						className: "btn btn-light border-primary",
						titleAttr: "Download file excel"
					},
					{
						extend: 'pdf',
						text: '<i class="fas fa-file-pdf"></i>',
						exportOptions: {
							columns: ':visible'
						},
						className: "btn btn-light border-primary",
						titleAttr: "Download file pdf"
					},
					{
						extend: 'print',
						text: '<i class="fas fa-print"></i>',
						exportOptions: {
							columns: ':visible'
						},
						className: "btn btn-light border-primary",
						titleAttr: "Print",
					},
				],
				'language': {
					'loadingRecords': '&nbsp;',
					'processing': '<div class="spinner"></div>'
				},
				"serverSide": true,
				"searchDelay": 1000,
				"ajax": {
					"url": `${API_ENDPOINT_PENGURUS_PP.REGISTRASI_AKUN_OLD(status_regis, id_cabang, id_daerah)}`,
					"type": "GET",
					"beforeSend": function (xhr) {
						xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem(CONFIG.TOKEN_USER));
					},
					"data" : function (d) {
						d.orderColumn = savedOrderColumn;
						d.orderDirection = savedOrderDirection;
					}
				},

				"columns": [
					{ data: 'created_at' },
					{ data: 'name' },
					{ data: 'phone_number', render : function (data) {
						let content = ``;
						if (data != null) {	
							let nomorTeleponBaru = data.replace(/^0/, "62");
							content = `${data} <a href="https://api.whatsapp.com/send?phone=${nomorTeleponBaru}" class="text-success" target="blank()"><i class="fab fa-whatsapp"></i> Chat</a>`;
						}else {
							content = '-';
						}
	
						return content
					}},
					{ data: 'ktan' },
					{ data: 'nik' },
					{ data: 'email' },
					{ data: 'daerah_name' },
					{ data: 'cabang_name' },
					{ data: 'ktan_expires_at' },
					{
						data: 'old_ktan',
						render: function (data) {
							if (data == null || data == "") {
								return '-'
							} else {
								return '<img loading="lazy" id="show" src="' + data + '" onerror="this.onerror=null; this.src=\'./src/public/img/image-corrupt-min.png\';" data-original="' + data + '" width="40px">'
							}
						}
					},
					{ data: 'periode_iuran.bulan_iuran_awal' },
					{ data: 'periode_iuran.bulan_iuran_akhir' },
					{
						data: 'bukti_iuran_terakhir',
						render: function (data) {
							if (data == null || data == "") {
								return '-'
							} else {
								return '<img loading="lazy" id="show" src="' + data + '" onerror="this.onerror=null; this.src=\'./src/public/img/image-corrupt-min.png\';" data-original="' + data + '" width="40px">'
							}
						}
					},
					{
						data: 'status_pc', render: function (data) {
							if (data == 'pending') {
								return `
								<div class="badge badge-warning text-capitalize">${data}</div>
							`
							} else {
								return `
								<div class="badge badge-success text-capitalize">${data}</div>
							`
							}
						}
					},
					{
						data: 'status_pc', render: function (data) {
							if (data == 'pending') {
								return `
							<div style="display:flex">
								<button style="margin:1px" class='btn btn-primary btn-sm' id='approve' title='Approve'><i class='fas fa-check'></i></button>
								<button style="margin:1px" class='btn btn-danger btn-sm' data-toggle="modal" data-target="#commentModalRegistOld" id='rejectedRegist' title='Tolak Regitrasi'><i class='fas fa-times'></i></button>
							</div>
							`
							} else {
								return `
							<button style="margin:1px" class='btn btn-danger btn-sm' data-toggle="modal" data-target="#commentModalRegistOld" id='rejectedRegist' title='Tolak Regitrasi'><i class='fas fa-times'></i></button>
							`
							}
						}
					},




				],
				"initComplete": function () {
					$('#tablePengajuanRegistrasiOld_filter input').unbind();
						$('#tablePengajuanRegistrasiOld_filter input').bind('keyup', function(e) {
							if(e.keyCode == 13) {
								table.search($('#tablePengajuanRegistrasiOld_filter input').val()).draw();
							}
						}); 
				},
				select: {
					style: 'multi',
					selector: 'td:first-child'
				},
				"columnDefs": [
					{
						"targets": [0],
						"visible": true,
						"searchable": true,
						"orderable": true
					},
					{
						"targets": [3,5,6,7,8,9,10,11,13],
						"orderable": false
					},
					{
						"targets": [1],
						"visible": true,
						"type": "datetime"
					},
					{
						"targets": 3,
						className: 'd-none',
						"visible": false
					},
				]
			});

			$('#tablePengajuanRegistrasiOld thead').off('click', 'th').on('click', 'th', function () {
				let chachedCoulumnName = savedOrderColumn;
				const columnIndex = $(this).index();
				const columnHeaders = $('#tablePengajuanRegistrasiOld thead th');
				const columnName = $(columnHeaders[columnIndex]).data('column');
				var order =  table.order()[0][1];
				
				if (order == "asc") {
					order = "desc"
				}else{
					order = "asc"
				}
				if(chachedCoulumnName != columnName){
					order = "asc"
				}
				savedOrderColumn = columnName;
				chachedCoulumnName = savedOrderColumn;
				savedOrderDirection = order;
			});



			$("#selectPengajuanRegisPC").on("change", async function (e) {
				id_cabang = $(this).val();
			});

			$("#selectPengajuanRegisPD").on("change", async function (e) {
				id_daerah = $(this).val();
			})

		})


		$('#tablePengajuanRegistrasiOld thead').off('click', 'th').on('click', 'th', function () {
			let chachedCoulumnName = savedOrderColumn;
			const columnIndex = $(this).index();
			const columnHeaders = $('#tablePengajuanRegistrasiOld thead th');
			const columnName = $(columnHeaders[columnIndex]).data('column');
			var order =  table.order()[0][1];
			
			if (order == "asc") {
				order = "desc"
			}else{
				order = "asc"
			}
			if(chachedCoulumnName != columnName){
				order = "asc"
			}
			savedOrderColumn = columnName;
			chachedCoulumnName = savedOrderColumn;
			savedOrderDirection = order;
		});

		mRefresh.resolve()

	});
	},

	async _approveRegistrasiOld() {

		let idPengajuan = 0;
		let approval = '';
		$('#tablePengajuanRegistrasiOld tbody').on('click', '#approve', async function () {
			$(this).html('<img loading="lazy" src="images/loading-gif.gif" width="18px">');
			const table = $('#tablePengajuanRegistrasiOld').DataTable();
			let data = table.row($(this).parents('tr')).data();
			idPengajuan = data.uuid;
			approval = 'approve';
			swal.fire({
				title: 'Setujui Registrasi Anggota Lama',
				html: "Yakin menyetujui anggota lama ini? <br> <strong>Nama Anggota </strong>: " + data.name,
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Ya, Setujui'
			}).then(async (result) => {
				if (result.value) {

					const approveAnggota = await ApiKepengurusanPP.approveRegisOld({
						id: idPengajuan,
						approval: approval
					});
					if (approveAnggota.status_code == 200) {
						swal.fire({
							title: 'Success',
							text: 'Data Berhasil di Approve',
							icon: 'success',
							showConfirmButton: false,
							timer: 1000
						});

						$(this).html("<i class='fas fa-check'></i>");
						$('#tablePengajuanRegistrasiOld').DataTable().ajax.reload();

					} else {

						swal.fire({
							title: 'Error',
							text: `${approveAnggota.message}`,
							icon: 'error',
							showConfirmButton: false,
							timer: 1000
						});
						$(this).html("<i class='fas fa-check'></i>");
						$('#tablePengajuanRegistrasiOld').DataTable().ajax.reload();
					}
				} else if (result.dismiss == 'cancel') {
					$(this).html("<i class='fas fa-check'></i>");
				};
			});



		});


		$('#tablePengajuanRegistrasiOld tbody').on('click', '#rejectedRegist', async function () {
			const table = $('#tablePengajuanRegistrasiOld').DataTable();
			let data = table.row($(this).parents('tr')).data();
			idPengajuan = data.uuid;
			approval = 'reject';
		});

		$("#btnSendKomentarRegistrasiOld").on("click", async function (e) {
			e.stopPropagation()
			$(this).html(`<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>`);
			let comment = $("#komentarRegistrasiOld").val();
			if (comment === "" || null) {
				NotificationModal.show(`Komentar tidak boleh kosong !`, 'error');
				$(this).html("<i class='fas fa-comment'></i> Kirim");
			} else {
				const approveAnggota = await ApiKepengurusanPP.approveRegisOld({
					id: idPengajuan,
					approval: approval,
					reason: comment
				})
				if (approveAnggota.status_code != 200) {
					NotificationModal.show(`${approveAnggota.message}`, 'error');
					$(this).html("<i class='fas fa-comment'></i> Revisi & Berikan Komentar");
					$("#komentarRegistrasiOld").val("")
					$('#commentModalRegistOld').modal('hide');
				} else {
					NotificationModal.show(`${approveAnggota.message}`, 'success');
					$(this).html("<i class='fas fa-comment'></i> Revisi & Berikan Komentar");
					$("#komentarRegistrasiOld").val("")
					$('#commentModalRegistOld').modal('hide');
					$('#tablePengajuanRegistrasiOld').DataTable().ajax.reload();
				}
			}

		});



	},

	async _getDataPrivate() {
		const dataProvince = await ApiRegister.getProvince()
		if (dataProvince.status_code == 200) {
			dataProvince.data.forEach(data => {
				document.getElementById("selectPengajuanPD").add(new Option(data.province_name, data.province_code))
				document.getElementById("selectPengajuanRegisPD").add(new Option(data.province_name, data.province_code))
				document.getElementById("selectPengajuanRegisPDOld").add(new Option(data.province_name, data.province_code))
			});
		}

		const eventChange = async (e) => {
			document.getElementById("selectPengajuanPC").add((new Option("Loading Kabupaten / Kota ...", "")))
			let valueSelected = document.getElementById("selectPengajuanPD").value
			
			const dataRegistry = await ApiRegister.getRegistry(valueSelected)

			var selectPC = document.getElementById("selectPengajuanPC");
			var lengthOptionPc = selectPC.options.length;
			for (let i = lengthOptionPc - 1; i >= 0; i--) {
				selectPC.options[i] = null;
			}

			document.getElementById("selectPengajuanPC").add((new Option("Pilih Cabang", "")))
			
			try {
				
			dataRegistry.data.forEach(data => {
				document.getElementById("selectPengajuanPC").add(new Option(data.regency_name, data.id))
			});

			} catch (error) {
				
			}


			e.preventDefault();
		}
		const eventChangeRegis = async (e) => {
			document.getElementById("selectPengajuanRegisPC").add((new Option("Loading Kabupaten / Kota ...", "")))
			let valueSelected = document.getElementById("selectPengajuanRegisPD").value
			const dataRegistry = await ApiRegister.getRegistry(valueSelected)

			var selectPC = document.getElementById("selectPengajuanRegisPC");
			var lengthOptionPc = selectPC.options.length;
			for (let i = lengthOptionPc - 1; i >= 0; i--) {
				selectPC.options[i] = null;
			}
			document.getElementById("selectPengajuanRegisPC").add((new Option("Pilih Cabang", "")))
			dataRegistry.data.forEach(data => {
				document.getElementById("selectPengajuanRegisPC").add(new Option(data.regency_name, data.id))
			});


			e.preventDefault();
		}


		const eventChangeRegisOld = async (e) => {
			document.getElementById("selectPengajuanRegisPCOld").add((new Option("Loading Kabupaten / Kota ...", "")))
			let valueSelected = document.getElementById("selectPengajuanRegisPDOld").value
			const dataRegistry = await ApiRegister.getRegistry(valueSelected)

			var selectPC = document.getElementById("selectPengajuanRegisPCOld");
			var lengthOptionPc = selectPC.options.length;
			for (let i = lengthOptionPc - 1; i >= 0; i--) {
				selectPC.options[i] = null;
			}
			document.getElementById("selectPengajuanRegisPCOld").add((new Option("Pilih Cabang", "")))
			dataRegistry.data.forEach(data => {
				document.getElementById("selectPengajuanRegisPCOld").add(new Option(data.regency_name, data.id))
			});


			e.preventDefault();
		}

		document.getElementById("selectPengajuanPD").addEventListener("change", eventChange);
		document.getElementById("selectPengajuanRegisPD").addEventListener("change", eventChangeRegis);
		document.getElementById("selectPengajuanRegisPDOld").addEventListener("change", eventChangeRegisOld);


	},

	async _getPengajuanKTAN() {;
		let status = "pending"
		let bg =""
		let idCabang =""
		let idDaerah =""
		let disabled =""
		let pd =""
		let pc=""

		if (status == 'approve') {
			 bg = 'bg-success'
			$('#btnVerifikasiAll').hide()
			$('#btnVerifikasiAllDt').hide()
			disabled = 'd-none';
		} else if (status == 'revise') {
			 bg = 'bg-danger'
			$('#btnVerifikasiAllDt').hide()
			$('#btnVerifikasiAll').hide()
		} else if (status == 'pending') {
			 bg = 'bg-warning'
			$('#btnVerifikasiAllDt').show()
			$('#btnVerifikasiAll').show()
		}

		const eventFilterd = async (idCabangValue, idDaerahValue, statusDefault , bg) => {
			await this._getPengajuanKTANFiltered(idCabangValue, idDaerahValue, statusDefault ,bg, disabled)
		}

		$(".btn-jenis-permohonan").on("click", async function () {
			$("#selectPengajuanPD").val("");
			$("#selectPengajuanPC").val("");
			status = $(this).val();
			if (status == 'approve') {
				 bg = 'bg-success'
				$('#btnVerifikasiAll').hide()
				$('#btnVerifikasiAllDt').hide()
				disabled = 'd-none';
			} else if (status == 'revise') {
				 bg = 'bg-danger'
				$('#btnVerifikasiAllDt').hide()
				$('#btnVerifikasiAll').hide()
			} else if (status == 'pending') {
				 bg = 'bg-warning'
				$('#btnVerifikasiAllDt').show()
				$('#btnVerifikasiAll').show()
			}
			await eventFilterd('', '', status , bg);

			let buttonAllElement = document.querySelectorAll('.btn-jenis-permohonan')

			buttonAllElement.forEach((element) => {
				element.classList.remove('btn-primary');
				element.classList.add('btn-outline-primary');
			});

			this.classList.remove('btn-outline-primary')
			this.classList.add('btn-primary')		
		})


		$("#selectPengajuanPC").on("change", async function (e) {
			 pc = $(this).val();
			await eventFilterd(pc, pd, status , bg,disabled);

		});

		$("#selectPengajuanPD").on("change", async function (e) {
			 pd = $(this).val();
			await eventFilterd(pc, pd, status , bg,disabled);
		})

		eventFilterd('', '', status , bg,disabled);

	},

	async _getPengajuanKTANFiltered( idCabangValue, idDaerahValue, statusDefault , bg, disabled	) {
		let idCabang = idCabangValue
		let idDaerah = idDaerahValue
		
		var savedOrderColumn = 'created_at';
		var savedOrderDirection = 'asc';

		let status = statusDefault

		
		await StorageAnggotaPP.makeStore();
		let table = await $('#tablePengajuanKTAN').DataTable({
			"pageLength": 10,
			"lengthMenu": [10, 25, 50, 100],
			"processing": true,
			"destroy": true,
			"searchDelay": 1000,
			dom: 'lBfrtip<"actions">',
			buttons: [
				{
					extend: 'copy',
					text: '<i class="fas fa-copy"></i>',
					exportOptions: {
						columns: ':visible'
					},
					className: "btn btn-light border-primary",
					titleAttr: "Copy to clipboard"
				},
				{
					extend: 'csv',
					text: '<i class="fas fa-file-csv"></i>',
					exportOptions: {
						columns: ':visible'
					},
					className: "btn btn-light border-primary",
					titleAttr: "Download file csv"
				},
				{
					extend: 'excel',
					text: '<i class="fas fa-file-excel"></i>',
					exportOptions: {
						columns: ':visible'
					},
					className: "btn btn-light border-primary",
					titleAttr: "Download file excel"
				},
				{
					extend: 'pdf',
					text: '<i class="fas fa-file-pdf"></i>',
					exportOptions: {
						columns: ':visible'
					},
					className: "btn btn-light border-primary",
					titleAttr: "Download file pdf"
				},
				{
					extend: 'print',
					text: '<i class="fas fa-print"></i>',
					exportOptions: {
						columns: ':visible'
					},
					className: "btn btn-light border-primary",
					titleAttr: "Print",
				},
				{
					text: "<i class='fas fa-check'></i> Approve All",
					exportOptions: {
						columns: ':visible'
					},
					className: "btn btn-primary " + `${disabled}`,
					titleAttr: "Approve All",
					attr: {
						id: "btnVerifikasiAllDt"
					},
					action: function () {
						$("#btnVerifikasiAllDt").html("Loading...");
						$("#btnVerifikasiAll").trigger("click");
					}
				}
			],
			'language': {
				'loadingRecords': '&nbsp;',
				'processing': '<div class="spinner"></div>'
			},
			"serverSide": true,
			"searchDelay": 1000,
			
			"ajax": {
				"url": `${API_ENDPOINT_PENGURUS_PP.PENGAJUAN_KTAN_FILTERED(idCabang, idDaerah, status)}`,
				"type": "GET",
				"beforeSend": function (xhr) {
					xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem(CONFIG.TOKEN_USER));
				},
				"data" : function (d) {
					d.orderColumn = savedOrderColumn;
					d.orderDirection = savedOrderDirection;
				}
			},
			"columns": [
				{ data: '' },
				{ data: 'created_at' },
				{ data: 'type_pengajuan', render : function (data) {
					if (data == 'new') {
						return `<div style="display:flex" class="col-sm-12 text-center">
						<span class="badge bg-primary text-light font-weight-none text-capitalize text-center" id="status">KTAN BARU</span>
					</div>`;
					}else {
						return `<div style="display:flex" class="col-sm-12 text-center">
						<span class="badge bg-success text-light font-weight-none text-capitalize text-center" id="status">PERPANJANGAN</span>
					</div>`;
					}
				} },
				{ data: 'user.name' },
				{ data: 'user_pd_aktif_id' },
				{ data: 'daerah_name' },
				{ data: 'cabang_name' },
				{ data: 'user.nik' },
				{
					data: 'dokumen.ktp_photo',
					render: function (data) {
						if (data == null || data == "") {
							return '-'
						} else {
							return '<img loading="lazy" id="show" src="' + data + '" onerror="this.onerror=null; this.src=\'./src/public/img/image-corrupt-min.png\';" data-original="' + data + '" width="40px">'
						}
					}
				},
				{
					data: 'dokumen.pas_photo',
					render: function (data) {
						if (data == null || data == "") {
							return '-'
						} else {
							return '<img loading="lazy" id="show" src="' + data + '" onerror="this.onerror=null; this.src=\'./src/public/img/image-corrupt-min.png\';" data-original="' + data + '" width="40px">'
						}
					}
				},
				{
					data: 'dokumen.bukti_bayar_ktan',
					render: function (data) {
						if (data == null || data == "") {
							return '-'
						} else {
							return '<img loading="lazy" id="show" src="' + data + '" onerror="this.onerror=null; this.src=\'./src/public/img/image-corrupt-min.png\';" data-original="' + data + '" width="40px">'
						}
					}
				},
				{
					data: 'dokumen.ijazah',
					render: function (data) {
						if (data == null || data == "") {
							return '-'
						} else {
							return '<img loading="lazy" id="show" src="' + data + '" onerror="this.onerror=null; this.src=\'./src/public/img/image-corrupt-min.png\';" data-original="' + data + '" width="40px">'
						}
					}
				},
				{data: ''},
				{
					data: 'adminPc.name',
					render: function (data) {

						if (data == null || data == '') {
							var status = 'Tidak ada data'
						} else {
							var status = `<div class="d-flex" style="display:flex">${data}</div>`
						}


						return status
					}

				},
				{
					data: 'approval_cabang_at',
					render: function (data) {

						if (data == null || data == '') {
							var status = 'Tidak ada data'
						} else {
							var status = `<div class="d-flex" style="display:flex">${data}</div>`
						}


						return status
					}

				},
				{
					data: 'adminPd.name',
					render: function (data) {
						if (data == null || data == '') {
							var status = 'Tidak ada data'
						} else {
							var status = `<div class="d-flex" style="display:flex">${data}</div>`
						}
						return status
					}

				},
				{
					data: 'approval_daerah_at',
					render: function (data) {
						if (data == null || data == '') {
							var status = 'Tidak ada data'
						} else {
							var status = `<div class="d-flex" style="display:flex">${data}</div>`
						}
						return status
					}

				},
				{
					data: 'adminPp.name',
					render: function (data) {
						if (data == null || data == '') {
							var status = 'Tidak ada data'
						} else {
							var status = `<div class="d-flex" style="display:flex">${data}</div>`
						}

						return status
					}

				},
				{
					data: 'approval_pusat_at',
					render: function (data) {
						if (data == null || data == '') {
							var status = 'Tidak ada data'
						} else {
							var status = `<div class="d-flex" style="display:flex">${data}</div>`
						}

						return status
					}

				},
			],
			"initComplete": function () {
				$('#tablePengajuanKTAN_filter input').unbind();
					$('#tablePengajuanKTAN_filter input').bind('keyup', function(e) {
						if(e.keyCode == 13) {
							table.search($('#tablePengajuanKTAN_filter input').val()).draw();
						}
					}); 
			},
			"fnDrawCallback": function (data) {
				let rowTable = data.aoData;
				const checked = document.getElementById('checkAll').checked;
				if (checked) {
					document.getElementById('checkAll').checked = false;
				}

				$('#tablePengajuanKTAN tbody tr').each(function (e) {
					if (rowTable[e] !== undefined) {
						let result = rowTable[e]._aData.id;
						let isChecked = StorageAnggotaPP.isReady(result);
						if (isChecked) {
							$(this).addClass('selected');
						}
					}
				})
			},
			select: {
				style: 'multi',
				selector: 'td:first-child'
			},
			order: [
				[1, 'asc']
			],
			"columnDefs": [{
				"targets": 0,
				"orderable": false,
				className: 'select-checkbox',
				"data": null,
				"defaultContent": ``
			},
			{
				"targets": [0],
				"visible": true,
				"searchable": false
			},
			{
				"targets": [3,4,5,6,7,8,9,10,11,12,14,16],
				"orderable": false
			},
			{
				"targets": [1],
				"visible": true,
				"searchable": true,
				"type": 'datetime'
			},
			{
				"targets": 4,
				className: 'd-none',
				"visible": false
			},
			{
				"targets": 11,
				"data": null,
				"orderable": false,
				"defaultContent": `
					<div style="display:flex" class="col-sm-12 text-center">
						<span class="badge ${bg} text-light font-weight-none text-capitalize text-center" id="status">${status}</span>
					</div>`
			},
			{
				"targets": 19,
				"data": null,
				"render": function (data, type, row) {
					return `<div style="display:flex">
					<button style="margin:1px" class='btn btn-success btn-sm ${disabled}' id='approve' title='Approve'><i class='fas fa-check'></i></button>
					<button style="margin:1px" class='btn btn-danger btn-sm' data-toggle="modal" data-target="#commentModal" id='revisi' title='Revisi'><i class='fas fa-times'></i></button>
					<button style="margin:1px; position:relative;" class='btn btn-info btn-sm' data-toggle="modal" data-target="#commentModalUserKTAN" id='comment' title='Komentar'><i class='fas fa-comment fa-fw'></i><span class="badge-chat" style="">${data.comment_unread_count}</span></button>
				</div>`},
			},
			{
				"targets": [7, 8, 9, 10],
				"visible": true,
				"searchable": true,
				"className": "text-center",
			}
			]
		});

		$('#tablePengajuanKTAN tbody').on('click', '.select-checkbox', async function (e) {
			e.stopPropagation();
			const table = $('#tablePengajuanKTAN').DataTable();
			let data = table.row($(this).parents('tr')).data();
			let idPengajuan = data.id

			if ($(this).parents('tr').hasClass("selected")) {
				$(this).parents('tr').removeClass('selected');
				await StorageAnggotaPP.deleteData(idPengajuan);
			} else {
				$(this).parents('tr').addClass('selected');
				await StorageAnggotaPP.pushData(idPengajuan);
			}
		});


		$('#tablePengajuanKTAN tbody').on('click', '.select-checkbox', async function (e) {
			e.stopPropagation();
			const table = $('#tablePengajuanKTAN').DataTable();
			let data = table.row($(this).parents('tr')).data();
			let idPengajuan = data.id

			if ($(this).parents('tr').hasClass("selected")) {
				$(this).parents('tr').removeClass('selected');
				await StorageAnggotaPP.deleteData(idPengajuan);
			} else {
				$(this).parents('tr').addClass('selected');
				await StorageAnggotaPP.pushData(idPengajuan);
			}
		});


		$('#tablePengajuanKTAN thead').off('click', 'th').on('click', 'th', function () {
			let chachedCoulumnName = savedOrderColumn;
			const columnIndex = $(this).index();
			const columnHeaders = $('#tablePengajuanKTAN thead th');
			const columnName = $(columnHeaders[columnIndex]).data('column');
			var order =  table.order()[0][1];
			
			if (order == "asc") {
				order = "desc"
			}else{
				order = "asc"
			}
			if(chachedCoulumnName != columnName){
				order = "asc"
			}
			savedOrderColumn = columnName;
			chachedCoulumnName = savedOrderColumn;
			savedOrderDirection = order;

		});

	},

	async _checkAllEvent() {
		const eventSelectAll = async () => {
			let parentElement = document.getElementById('tablePengajuanKTAN')
			let tagElement = parentElement.getElementsByTagName('tbody')[0]
			const element = tagElement.querySelectorAll(".select-checkbox");
			const checked = document.getElementById('checkAll').checked;
			for (let index = 0; index < element.length; index++) {
				const table = $('#tablePengajuanKTAN').DataTable();
				let data = table.row(index).data()
				if (checked) {
					StorageAnggotaPP.pushData(data.id)
					await element[index].parentNode.classList.add('selected');
				} else {
					element[index].parentNode.classList.remove('selected');
					await StorageAnggotaPP.deleteData(data.id)
				}
			}
		}

		document.getElementById('checkAll').addEventListener('change', eventSelectAll)

	},



	async _checkAllEventRegis() {
		const eventSelectAll = async () => {
			let parentElement = document.getElementById('tablePengajuanRegistrasi')
			let tagElement = parentElement.getElementsByTagName('tbody')[0]
			const element = tagElement.querySelectorAll(".select-checkbox");
			const checked = document.getElementById('checkAllRegis').checked;
			for (let index = 0; index < element.length; index++) {
				const table = $('#tablePengajuanRegistrasi').DataTable();
				let data = table.row(index).data()

				if (checked) {
					StorageAnggotaPPRegis.pushData(data.uuid)
					await element[index].parentNode.classList.add('selected');
				} else {
					element[index].parentNode.classList.remove('selected');
					await StorageAnggotaPPRegis.deleteData(data.uuid)
				}
			}

		}

		document.getElementById('checkAllRegis').addEventListener('change', eventSelectAll)

	},

	async _approvePengajuanKTAN() {

		let idPengajuan = 0;
		$('#tablePengajuanKTAN tbody').on('click', '#approve', async function () {
			var elmInThis = $(this)
			const table = $('#tablePengajuanKTAN').DataTable();
			let data = table.row($(this).parents('tr')).data();
			swal.fire({
				title: 'Approve Pengajuan',
				html: "Yakin menyetujui data ini ? " + data.user.name,
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Ya, Setujui'
			}).then(async (result) => {
				if (result.value) {
					elmInThis.html('<img loading="lazy" src="images/loading-gif.gif" width="18px">');
					
					idPengajuan = data.id;
		
				
					const approveAnggota = await ApiPengajuanKTANPP.aprroveKTAN({
						id: idPengajuan,
						status: "approve",
						comment: ""
					});


					if (approveAnggota.status_code != 200) {
						NotificationModal.show(`${approveAnggota.message}`, 'error');
						$('#tablePengajuanKTAN').DataTable().ajax.reload();
					} else {
						NotificationModal.show(`${approveAnggota.message}`, 'success');
						$('#tablePengajuanKTAN').DataTable().ajax.reload();
					}


					await StorageAnggotaPP.makeStore();

				}
			});
			


		});

		
		$('#tablePengajuanKTAN tbody').on('click', '#revisi', async function () {
			const table = $('#tablePengajuanKTAN').DataTable();
			let data = table.row($(this).parents('tr')).data();
			let UserID = data.user_id
			idPengajuan = data.id;

			// let getContentAdmin = (user) => {
			// let time =  new Date(user.updated_at).toLocaleDateString('en-US') + " " + new Date(user.updated_at).toLocaleTimeString('en-US') ;
			// return `
			// 		<div style="width:100%">
			// 			<div style="width:10%;" class="float-left">
			// 				<img loading="lazy" class="rounded-circle img-thumbnail img-square float-left" id="imgUser" src="${user.actor.avatar}" style="width:50px; height:50px;">
			// 			</div>
			// 			<div class="float-left text-dark text-left" style="width:88%;">
			// 				<div class="float-left" style="background:#dedede; margin:5px; padding:0 12px; padding-bottom:5px; border-radius:0px 10px" id="commentUser">
			// 					<small><b>${user.actor.name} [${user.actor.role}]</b></small><hr>
			// 					<small>${user.body}</small><br>
			// 					<div class="text-right">
			// 						<small style="margin-top:-2px; margin-left:50px"><strong>${time}</strong></small>
			// 					</div>
			// 				</div>
			// 			</div>
			// 		</div>
			// 		`
			// }

			// let getContentUser = (user) => {
			// 	let time =  new Date(user.updated_at).toLocaleDateString('en-US') + " " + new Date(user.updated_at).toLocaleTimeString('en-US') ;
			// 	return 	`
			// 			<div style="width:100%">
			// 				<div style="width:10%;" class="float-right">
			// 					<img loading="lazy" class="rounded-circle img-thumbnail img-square float-left" id="imgUser" src="${user.actor.avatar}" style="width:50px; height:50px;">
			// 				</div>
			// 				<div class="float-right text-dark text-left" style="width:88%;">
			// 					<div class="float-right" style="background:#e0e8fc; margin:5px; padding:0 12px; padding-bottom:5px; border-radius:10px 0px" id="commentUser">
			// 						<small><b>${user.actor.name} [${user.actor.role}]</b></small><hr>
			// 						<small>${user.body}</small><br>
			// 						<div class="text-right">
			// 							<small style="margin-top:-2px; margin-left:50px"><strong>${time}</strong></small>
			// 						</div>
			// 					</div>
			// 				</div>
			// 			</div>
			// 			`
			// }


			// let response = await ApiPengajuanKTANPC.getCommentar(UserID,"membership",idPengajuan)
			// let dataComment = response.data;
			// dataComment.reverse();

			// if (dataComment.length <= 0) {
			// 	$("#content-comment").html("<br><center><i>Tidak ada komentar</i></center>");
			// }else {
			// 	let getAllContent = async () => {
			// 	let elmHtml = ``
			// 		await dataComment.forEach(async element => {
			// 			elmHtml = elmHtml + (element.actor_type == "user" ?  getContentAdmin(element) :  getContentUser(element))
			// 		});
			// 		return elmHtml
			// 	}


			// 	if (response.data == undefined || response.data.length <= 0) {
			// document.getElementById("content-comment").innerHTML = `<span class="no-comment">Belum ada komentar</span>`

			// 	}else{
			// 		document.getElementById("content-comment").innerHTML = await getAllContent();

			// 		setTimeout(() => {
			// 			var elem = document.getElementById('content-comment');
			// 			elem.scrollTop = elem.scrollHeight;
			// 			}, 400);
			// 	}
			// }

		});

		$("#btnSendKomentarPengajuan").click(async function (e) {
			$(this).html(`<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>`);
			e.stopPropagation()
			let comment = $("#komentarPengajuan").val();
			if (comment === "" || null) {
				NotificationModal.show(`Komentar tidak boleh kosong !`, 'error');
				$(this).html("<i class='fas fa-comment'></i> Revisi & Berikan Komentar");
			} else {
				const revisePengajuanKTAN = await ApiPengajuanKTANPP.aprroveKTAN({
					id: idPengajuan,
					status: "revise",
					comment: comment
				})
				if (revisePengajuanKTAN.status_code != 200) {
					NotificationModal.show(`${revisePengajuanKTAN.message}`, 'error');
					$("#komentarPengajuan").val("")
					$('#commentModal').modal('hide');
					$('#tablePengajuanKTAN').DataTable().ajax.reload();
					$(this).html("<i class='fas fa-comment'></i> Revisi & Berikan Komentar");
				} else {
					NotificationModal.show(`${revisePengajuanKTAN.message}`, 'success');
					$("#komentarPengajuan").val("")
					$('#commentModal').modal('hide');
					$('#tablePengajuanKTAN').DataTable().ajax.reload();
					$(this).html("<i class='fas fa-comment'></i> Revisi & Berikan Komentar");
				}
			}
		});



		$("#btnCancel").click(function () {
			$("#komentarPengajuan").val("")
		});
	},
	
	async _initCommentKTAN() {
		let idPengajuan = '';
		let userID = '';
		$('#tablePengajuanKTAN tbody').on('click', '#comment', async function () {
			const table = $('#tablePengajuanKTAN').DataTable();
			let data = table.row($(this).parents('tr')).data();
			userID = data.user_id
			idPengajuan = data.id;

			let getContentAdmin = (user) => {
				let time = new Date(user.updated_at).toLocaleDateString('en-US') + " " + new Date(user.updated_at).toLocaleTimeString('en-US');
				return `
					<div style="width:100%">
						<div style="width:10%;" class="float-left">
							<img loading="lazy" class="rounded-circle img-thumbnail img-square float-left" id="imgUser" src="${user.actor.avatar}" style="width:50px; height:50px;">
						</div>
						<div class="float-left text-dark text-left" style="width:88%;">
							<div class="float-left" style="background:#dedede; margin:5px; padding:0 12px; padding-bottom:5px; border-radius:0px 10px" id="commentUser">
								<small><b>${user.actor.name} [${user.actor.role}]</b></small><hr>
								<small>${user.body}</small><br>
								<div class="text-right">
									<small style="margin-top:-2px; margin-left:50px"><strong>${time}</strong></small>
								</div>
							</div>
						</div>
					</div>
					`
			}

			let getContentUser = (user) => {
				let time = new Date(user.updated_at).toLocaleDateString('en-US') + " " + new Date(user.updated_at).toLocaleTimeString('en-US');
				return `
						<div style="width:100%">
							<div style="width:10%;" class="float-right">
								<img loading="lazy" class="rounded-circle img-thumbnail img-square float-left" id="imgUser" src="${user.actor.avatar}" style="width:50px; height:50px;">
							</div>
							<div class="float-right text-dark text-left" style="width:88%;">
								<div class="float-right" style="background:#e0e8fc; margin:5px; padding:0 12px; padding-bottom:5px; border-radius:10px 0px" id="commentUser">
									<small><b>${user.actor.name} [${user.actor.role}]</b></small><hr>
									<small>${user.body}</small><br>
									<div class="text-right">
										<small style="margin-top:-2px; margin-left:50px"><strong>${time}</strong></small>
									</div>
								</div>
							</div>
						</div>
						`
			}


			let response = await ApiPengajuanKTANPP.getCommentar(userID, "membership", idPengajuan)
			let dataComment = response.data;
			dataComment.reverse();

			if (dataComment.length <= 0) {
				$("#content-comment").html("<br><center><i>Tidak ada komentar</i></center>");
			} else {
				let getAllContent = async () => {
					let elmHtml = ``
					await dataComment.forEach(async element => {
						elmHtml = elmHtml + (element.actor_type == "user" ? getContentAdmin(element) : getContentUser(element))
					});
					return elmHtml
				}


				if (response.data == undefined || response.data.length <= 0) {
					document.getElementById("content-comment").innerHTML = `<span class="no-comment">Belum ada komentar</span>`
				} else {
					document.getElementById("content-comment").innerHTML = await getAllContent();

					setTimeout(() => {
						var elem = document.getElementById('content-comment');
						elem.scrollTop = elem.scrollHeight;
					}, 400);
				}
			}

		});

		const eventSendCommentar = async (e) => {
			e.preventDefault();
			document.getElementById("btnSendKomentarPengajuanKTAN").innerHTML = `<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>`
			let content = document.getElementById("komentarPengajuanKTANUser").value
			const response = await ApiPengajuanKTANPP.sendCommentar({
				userID: userID,
				pengajuanID: idPengajuan,
				commentFor: 'membership',
				contentComment: content,
			});
			if (response.status_code == 200 || 201) {


				let getContentAdmin = (user) => {
					let time = new Date(user.updated_at).toLocaleDateString('en-US') + " " + new Date(user.updated_at).toLocaleTimeString('en-US');
					return `
						<div style="width:100%">
							<div style="width:10%;" class="float-left">
								<img loading="lazy" class="rounded-circle img-thumbnail img-square float-left" id="imgUser" src="${user.actor.avatar}" style="width:50px; height:50px;">
							</div>
							<div class="float-left text-dark text-left" style="width:88%;">
								<div class="float-left" style="background:#dedede; margin:5px; padding:0 12px; padding-bottom:5px; border-radius:0px 10px" id="commentUser">
									<small><b>${user.actor.name} [${user.actor.role}]</b></small><hr>
									<small>${user.body}</small><br>
									<div class="text-right">
										<small style="margin-top:-2px; margin-left:50px"><strong>${time}</strong></small>
									</div>
								</div>
							</div>
						</div>
						`
				}

				let getContentUser = (user) => {
					let time = new Date(user.updated_at).toLocaleDateString('en-US') + " " + new Date(user.updated_at).toLocaleTimeString('en-US');
					return `
							<div style="width:100%">
								<div style="width:10%;" class="float-right">
									<img loading="lazy" class="rounded-circle img-thumbnail img-square float-left" id="imgUser" src="${user.actor.avatar}" style="width:50px; height:50px;">
								</div>
								<div class="float-right text-dark text-left" style="width:88%;">
									<div class="float-right" style="background:#e0e8fc; margin:5px; padding:0 12px; padding-bottom:5px; border-radius:10px 0px" id="commentUser">
										<small><b>${user.actor.name} [${user.actor.role}]</b></small><hr>
										<small>${user.body}</small><br>
										<div class="text-right">
											<small style="margin-top:-2px; margin-left:50px"><strong>${time}</strong></small>
										</div>
									</div>
								</div>
							</div>
							`
				}


				let response = await ApiPengajuanKTANPP.getCommentar(userID, "membership", idPengajuan)
				let dataComment = response.data;
				dataComment.reverse();

				if (dataComment.length <= 0) {
					$("#content-comment").html("<br><center><i>Tidak ada komentar</i></center>");
				} else {
					let getAllContent = async () => {
						let elmHtml = ``
						await dataComment.forEach(async element => {
							elmHtml = elmHtml + (element.actor_type == "user" ? getContentAdmin(element) : getContentUser(element))
						});
						return elmHtml
					}


					if (response.data == undefined || response.data.length <= 0) {
						document.getElementById("content-comment").innerHTML = `<span class="no-comment">Belum ada komentar</span>`
					} else {
						document.getElementById("content-comment").innerHTML = await getAllContent();

						setTimeout(() => {
							var elem = document.getElementById('content-comment');
							elem.scrollTop = elem.scrollHeight;
						}, 400);
					}
				}

				$('#komentarPengajuanKTANUser').val('');
				document.getElementById("btnSendKomentarPengajuanKTAN").innerHTML = `<i class="fas fa-comment"></i> Berikan Komentar`;
			} else {
				document.getElementById("btnSendKomentarPengajuanKTAN").innerHTML = `<i class="fas fa-comment"></i> Berikan Komentar`;
			}
			e.preventDefault();
		}
		document.getElementById("btnSendKomentarPengajuanKTAN").addEventListener("click", eventSendCommentar)
	},

	async _approvePengajuanRegistrasi() {


		let idPengajuan = 0;
		let approval = '';
		$('#tablePengajuanRegistrasi tbody').on('click', '#approve', async function () {
			var elmInThis = $(this)
			const table = $('#tablePengajuanRegistrasi').DataTable();
			let data = table.row($(this).parents('tr')).data();
			idPengajuan = data.uuid;
			approval = "approve"
			swal.fire({
				title: 'Approve Pengajuan Registrasi',
				html: "Yakin menyetujui data ini ? ",
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Ya, Setujui'
			}).then(async (result) => {
				if (result.value) {
					elmInThis.html('<img loading="lazy" src="images/loading-gif.gif" width="18px">');
							
						const approveAnggota = await ApiPengajuanKTANPP.aprroveRegistrasi({
							id: idPengajuan,
							approval: approval,
						});


						if (approveAnggota.status_code != 200) {
							NotificationModal.show(`${approveAnggota.message}`, 'error');
							$('#tablePengajuanRegistrasi').DataTable().ajax.reload();
						} else {
							NotificationModal.show(`${approveAnggota.message}`, 'success');
							$('#tablePengajuanRegistrasi').DataTable().ajax.reload();
						}

						await StorageAnggotaPPRegis.makeStore();
				}
			});
		});


		$('#tablePengajuanRegistrasi tbody').on('click', '#rejectedRegist', async function () {
			const table = $('#tablePengajuanRegistrasi').DataTable();
			let data = table.row($(this).parents('tr')).data();
			idPengajuan = data.uuid;
			approval = 'reject';

		});

		$("#btnSendKomentarRegistrasi").on("click", async function (e) {
			e.stopPropagation()
			$(this).html(`<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>`);
			let comment = $("#komentarRegistrasi").val();
			if (comment === "" || null) {
				NotificationModal.show(`Komentar tidak boleh kosong !`, 'error');
				$(this).html("<i class='fas fa-comment'></i> Kirim");
			} else {
				const approveAnggota = await ApiPengajuanKTANPP.aprroveRegistrasi({
					id: idPengajuan,
					approval: approval,
					reason: comment
				})
				if (approveAnggota.status_code != 200) {
					NotificationModal.show(`${approveAnggota.message}`, 'error');
					$(this).html("<i class='fas fa-comment'></i> Tolak & Berikan Komentar");
					$("#komentarRegistrasi").val("")
					$('#commentModalRegist').modal('hide');
				} else {
					NotificationModal.show(`${approveAnggota.message}`, 'success');
					$(this).html("<i class='fas fa-comment'></i> Tolak & Berikan Komentar");
					$("#komentarRegistrasi").val("")
					$('#commentModalRegist').modal('hide');
					$('#tablePengajuanRegistrasi').DataTable().ajax.reload();
				}
			}

		});


	},

	async _initAllApprove() {
		const eventAllApprove = async () => {
			if (await StorageAnggotaPP.size() <= 0) {
				NotificationModal.show("Silahkan Pilih Data Terlebih Dahulu", "warning")
				$("#btnVerifikasiAllDt").html("<i class='fas fa-check'></i> Approve All")
			} else {
				let data = await StorageAnggotaPP.getData()
				swal.fire({
					title: 'Setujui Semua',
					html: "Yakin menyetujui semua ? <br> <strong>Jumlah Data </strong>: " + data.length,
					icon: 'warning',
					showCancelButton: true,
					confirmButtonColor: '#3085d6',
					cancelButtonColor: '#d33',
					confirmButtonText: 'Ya, Setujui'
				}).then(async (result) => {
					if (result.value) {
						const response = await ApiPengajuanKTANPP.approveAllKTAN(data)
						if (response.status_code == 200) {
							NotificationModal.show("Data Berhasil di Approve", "success");
							$('#tablePengajuanKTAN').DataTable().ajax.reload();
							$("#btnVerifikasiAllDt").html("<i class='fas fa-check'></i> Approve All")
						} else {
							NotificationModal.show(`${response.message}`, "error");
							$('#tablePengajuanKTAN').DataTable().ajax.reload();
							$("#btnVerifikasiAllDt").html("<i class='fas fa-check'></i> Approve All")
						}
						await StorageAnggotaPP.makeStore();
					} else if (result.dismiss == 'cancel') {
						$("#btnVerifikasiAllDt").html("<i class='fas fa-check'></i> Approve All")
					};
				});
			}
		}

		document.getElementById('btnVerifikasiAll').addEventListener('click', eventAllApprove)
	},

	async _initAllApproveRegis() {
		const eventAllApprove = async () => {
			if (await StorageAnggotaPPRegis.size() <= 0) {
				NotificationModal.show("Silahkan Pilih Data Terlebih Dahulu", "warning")
				$("#btnVerifikasiRegisAllDt").html("<i class='fas fa-check'></i> Approve All")
			} else {
				let data = await StorageAnggotaPPRegis.getData()
				swal.fire({
					title: 'Setujui Semua',
					html: "Yakin menyetujui semua ? <br> <strong>Jumlah Data </strong>: " + data.length,
					icon: 'warning',
					showCancelButton: true,
					confirmButtonColor: '#3085d6',
					cancelButtonColor: '#d33',
					confirmButtonText: 'Ya, Setujui'
				}).then(async (result) => {
					if (result.value) {
						const response = await ApiPengajuanKTANPP.approveAllRegistrasi(data)
						if (response.status_code == 200) {
							NotificationModal.show("Data Berhasil di Approve", "success");
							$('#tablePengajuanRegistrasi').DataTable().ajax.reload();
							$("#btnVerifikasiRegisAllDt").html("<i class='fas fa-check'></i> Approve All")
						} else {
							NotificationModal.show(`${response.message}`, "error");
							$('#tablePengajuanRegistrasi').DataTable().ajax.reload();
							$("#btnVerifikasiRegisAllDt").html("<i class='fas fa-check'></i> Approve All")
						}
						await StorageAnggotaPPRegis.makeStore();
					} else if (result.dismiss == 'cancel') {
						$("#btnVerifikasiRegisAllDt").html("<i class='fas fa-check'></i> Approve All")
					};
				});
			}
		}

		document.getElementById('btnVerifikasiAllRegis').addEventListener('click', eventAllApprove)
	},

	async _getPengajuanRegistrasiFiltered(){
		const eventFiltered = async (e) => {
			let status = document.getElementById('selectStatusApprove').value;
			let id_cabang = document.getElementById('selectPengajuanRegisPC').value;
			let id_daerah = document.getElementById('selectPengajuanRegisPD').value;
			let status_pembayaran = document.getElementById('selectStatusPembayaran').value;
			await this._getPengajuanRegistrasi(status,id_cabang,id_daerah,status_pembayaran)
		}
		$("#pengajuan-registrasi-new-tab").one("click", async function () {
			mRefresh.refresh()
			await eventFiltered();
			document.getElementById('btnFilter').addEventListener('click', eventFiltered)
			mRefresh.resolve();
			
		});
	},

	async _getPengajuanRegistrasi(status,id_cabang, id_daerah,status_pembayaran) {
		var savedOrderColumn = 'created_at';
		var savedOrderDirection = 'desc';
		let hide = ''
		if (status == 1) {
			hide = 'd-none'
		}
		let tablePengajuan = await $('#tablePengajuanRegistrasi').DataTable({
			"pageLength": 10,
			"lengthMenu": [10, 25, 50, 100],
			"processing": true,
			"destroy": true,
			dom: 'lBfrtip<"actions">',
			buttons: [
				{
					extend: 'copy',
					text: '<i class="fas fa-copy"></i>',
					exportOptions: {
						columns: ':visible'
					},
					className: "btn btn-light border-primary",
					titleAttr: "Copy to clipboard"
				},
				{
					extend: 'csv',
					text: '<i class="fas fa-file-csv"></i>',
					exportOptions: {
						columns: ':visible'
					},
					className: "btn btn-light border-primary",
					titleAttr: "Download file csv"
				},
				{
					extend: 'excel',
					text: '<i class="fas fa-file-excel"></i>',
					exportOptions: {
						columns: ':visible'
					},
					className: "btn btn-light border-primary",
					titleAttr: "Download file excel"
				},
				{
					extend: 'pdf',
					text: '<i class="fas fa-file-pdf"></i>',
					exportOptions: {
						columns: ':visible'
					},
					className: "btn btn-light border-primary",
					titleAttr: "Download file pdf"
				},
				{
					extend: 'print',
					text: '<i class="fas fa-print"></i>',
					exportOptions: {
						columns: ':visible'
					},
					className: "btn btn-light border-primary",
					titleAttr: "Print",
				},
				{
					text: "<i class='fas fa-check'></i> Approve All",
					exportOptions: {
						columns: ':visible'
					},
					className: "btn btn-primary",
					titleAttr: "Approve All",
					attr: {
						id: "btnVerifikasiRegisAllDt"
					},
					action: function () {
						$("#btnVerifikasiRegisAllDt").html("Loading...");
						$("#btnVerifikasiAllRegis").trigger("click");
					}
				}
			],
			'language': {
				'loadingRecords': '&nbsp;',
				'processing': '<div class="spinner"></div>'
			},
			"serverSide": true,
			"searchDelay": 1000,
			"ajax": {
				"url": `${API_ENDPOINT_PENGURUS_PP.REGISTRASI_AKUN(status, id_cabang, id_daerah, status_pembayaran)}`,
				"type": "GET",
				"beforeSend": function (xhr) {
					xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem(CONFIG.TOKEN_USER));
				},
				"data" : function (d) {
					d.orderColumn = savedOrderColumn;
    				d.orderDirection = savedOrderDirection;
				}
			},
			"initComplete": function () {
				$('#tablePengajuanKTAN_filter input').unbind();
					$('#tablePengajuanKTAN_filter input').bind('keyup', function(e) {
						if(e.keyCode == 13) {
							table.search($('#ttablePengajuanKTAN_filter input').val()).draw();
						}
					}); 
			},
			"columns": [
				{ data: '' },
				{ data: 'created_at' },
				{ data: 'name' },
				{ data: 'user_pd_aktif_id' },
				{ data: 'nik' },
				{ data: 'daerah_name' },
				{ data: 'cabang_name' },
				{ data: 'email' },
				{ data: 'tempat_lahir' },
				{ data: 'tanggal_lahir' },
				{ data: 'phone_number', render : function (data) {
					let content = ``;
					if (data != null) {	
						let nomorTeleponBaru = data.replace(/^0/, "62");
						content = `${data} <a href="https://api.whatsapp.com/send?phone=${nomorTeleponBaru}" class="text-success" target="blank()"><i class="fab fa-whatsapp"></i> Chat</a>`;
					}else {
						content = '-';
					}

					return content
				}},
				{
					data: 'nominal_invoice', render: function (data) {
						data = parseFloat(data).toFixed(2);

						// Menambahkan titik sebagai pemisah ribuan
						data = data.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

						return "Rp. " + data;
					}
				},
				{
					data: 'bukti_bayar_registrasi',
					render: function (data) {
						if (data == null || data == "") {
							return '-'
						} else {
							return '<img loading="lazy" id="show" src="' + data + '" onerror="this.onerror=null; this.src=\'./src/public/img/image-corrupt-min.png\';" data-original="' + data + '" width="40px">'
						}
					}
				},
				{
					data: 'status_bayar', render: function (data) {
						if (data === 'Lunas') {
							var status_bayar = `<span class="badge bg-success text-light font-weight-none text-capitalize text-center" id="status">${data}</span>`
						} else if(data === 'Belum Bayar'){
							var status_bayar = `<span class="badge bg-danger text-light font-weight-none text-capitalize text-center" id="status">${data}</span>`
						}else {
							var status_bayar = `<span class="badge bg-warning text-light font-weight-none text-capitalize text-center" id="status">${data}</span>`
						}

						return status_bayar;
					}
				},
				{
					data: 'registration_status', render: function (data) {
						if (data === 'Approve') {
							var status_bayar = `<span class="badge bg-success text-light font-weight-none text-capitalize text-center" id="status">${data}</span>`
						} else {
							var status_bayar = `<span class="badge bg-warning text-light font-weight-none text-capitalize text-center" id="status">${data}</span>`
						}

						return status_bayar;
					}
				},
			],
			
			"fnDrawCallback": function (data) {
				let rowTable = data.aoData;
				const checked = document.getElementById('checkAll').checked;
				if (checked) {
					document.getElementById('checkAll').checked = false;
				}

				$('#tablePengajuanRegistrasi tbody tr').each(function (e) {
					if (rowTable[e] !== undefined) {
						let result = rowTable[e]._aData.uuid;
						let isChecked = StorageAnggotaPPRegis.isReady(result);
						if (isChecked) {
							$(this).addClass('selected');
						}
					}
				})
			},
			"initComplete": function () {
				$('#tablePengajuanRegistrasi_filter input').unbind();
					$('#tablePengajuanRegistrasi_filter input').bind('keyup', function(e) {
						if(e.keyCode == 13) {
							tablePengajuan.search($('#tablePengajuanRegistrasi_filter input').val()).draw();
						}
					}); 
			},
			select: {
				style: 'multi',
				selector: 'td:first-child'
			},
			order: [
				[1, 'desc']
			],
			"columnDefs": [{
				"targets": 0,
				"orderable": false,
				className: 'select-checkbox',
				"data": null,
				"defaultContent": ``
			},
			{
				"targets": [0,3,4,5,6,10,11,12,13,14,15],
				"orderable": false
			},
			{
				"targets": [0],
				"visible": true,
				"searchable": false
			},
			{
				"targets": [1],
				"visible": true,
				"type": "datetime"
			},
			{
				"targets": 3,
				className: 'd-none',
				"visible": false
			},
			{
				"targets": 15,
				"data": null,
				render: function (data) {
					let content  = ``;
					if (data.registration_status == 'Approve') {
						content = `
						<div style="display:flex">
							<button style="margin:1px" class='btn btn-danger btn-sm' data-toggle="modal" data-target="#commentModalRegist" id='rejectedRegist' title='Tolak Regitrasi'><i class='fas fa-times'></i></button>
						</div>`
					}else {
						content = `
						<div style="display:flex">
							<button style="margin:1px" class='btn btn-primary btn-sm' id='approve' title='Approve'><i class='fas fa-check'></i></button>
							<button style="margin:1px" class='btn btn-danger btn-sm' data-toggle="modal" data-target="#commentModalRegist" id='rejectedRegist' title='Tolak Regitrasi'><i class='fas fa-times'></i></button>
						</div>`
					}

					return content;
				}
			}
			]
		});

		$('#tablePengajuanRegistrasi thead').off('click', 'th').on('click', 'th', function () {
			let chachedCoulumnName = savedOrderColumn;
			const columnIndex = $(this).index();
			const columnHeaders = $('#tablePengajuanRegistrasi thead th');
			const columnName = $(columnHeaders[columnIndex]).data('column');
			var order = tablePengajuan.order()[0][1];
			
			if (order == "asc") {
				order = "desc"
			}else{
				order = "asc"
			}
			if(chachedCoulumnName != columnName){
				order = "asc"
			}

			savedOrderColumn = columnName;
			chachedCoulumnName = savedOrderColumn;
			savedOrderDirection = order;
		});

		$('#tablePengajuanRegistrasi tbody').on('click', '.select-checkbox', async function (e) {
			e.stopPropagation();
			const table = $('#tablePengajuanRegistrasi').DataTable();
			let data = table.row($(this).parents('tr')).data();
			let idPengajuan = data.uuid

			if ($(this).parents('tr').hasClass("selected")) {
				$(this).parents('tr').removeClass('selected');
				await StorageAnggotaPPRegis.deleteData(idPengajuan);
			} else {
				$(this).parents('tr').addClass('selected');
				await StorageAnggotaPPRegis.pushData(idPengajuan);
			}
		});

	},



}


const StorageAnggotaPP = {

	async makeStore() {
		this._data = [];
	},

	isReady(kode) {
		const result = this._data.map((e) => { return e.id_pengajuan; }).indexOf(kode);
		let value = false
		if (result != -1) {
			value = true
		}
		return value;
	},

	async pushData(idPengajuan) {
		if (this.isReady(idPengajuan)) return
		let gold = {
			"id_pengajuan": idPengajuan,
		}
		this._data.push(gold);
	},

	async deleteData(kode) {
		let removeIndex = this._data.map((item) => { return item.id_pengajuan; }).indexOf(`${kode}`);
		this._data.splice(removeIndex, 1)
	},

	async size() {
		return this._data.length
	},

	async getData() {
		return this._data;
	}
}

const StorageAnggotaPPRegis = {

	async makeStore() {
		this._data = [];
	},

	isReady(kode) {
		const result = this._data.map((e) => { return e.id_pengajuan; }).indexOf(kode);
		let value = false
		if (result != -1) {
			value = true
		}
		return value;
	},

	async pushData(idPengajuan) {
		if (this.isReady(idPengajuan)) return
		let gold = {
			"id_pengajuan": idPengajuan,
		}
		this._data.push(gold);
	},

	async deleteData(kode) {
		let removeIndex = this._data.map((item) => { return item.id_pengajuan; }).indexOf(`${kode}`);
		this._data.splice(removeIndex, 1)
	},

	async size() {
		return this._data.length
	},

	async getData() {
		return this._data;
	}
}

export default AnggotaPPPresenter;