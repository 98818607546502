
import API_ENDPOINT_PENGURUS_PC from "../../config/globals/menu_pengurus_pc_endpoint.js";
import NotificationModal from "../../utils/initial_notification.js";
import CONFIG from "../../config/globals/config.js";
import ApiPengajuanKTANPC from '../../api/api_pengurus_pc/pengajuan-ktan.js';
import ApiAnggota from "../../api/data-anggota.js";
import ApiKepengurusanPC from "../../api/api_pengurus_pc/kepengurusan-pc.js";

import NikParser from "../../utils/nik-parser.js";
import ApiKepengurusanPP from "../../api/api_pengurus_pp/kepengurusan-pp.js";


const AnggotaPCPresenter = {

	async init() {
		await StorageAnggotaPC.makeStore()
		await StorageRegistAnggotaPC.makeStore()
		await this._setView();
		await this._getPengajuanKTANFiltered();
		
		const theEvent = async (e) => {
			mRefresh.refresh();
			await this._getDataAnggotaFiltered();
			mRefresh.resolve();
		}
		$("#tab2").one("click", async function () {
			await theEvent();
		});

		const theEventTab3 = async (e) => {
			mRefresh.refresh();
				await this._getRegistFiltered();
			mRefresh.resolve();
		}
		$("#tab3").one("click", async function () {
			await theEventTab3();
		});
		await this._checkAllEvent();
		await this._approvePengajuanKTAN();
		await this._initAllApprove();
		await this._getDetailAnggota();
		await this._updateExpiredKTAN();
		await this._createAnggotaManual();
		await this._deleteAnggota();
	
		await this._approveRegistrasiOld();
		await this._initCommentKTAN();
		await this._updateDataAnggota();
		await this._updateNIKAnggota();
		await this._downloadAnggota();
		// await this._initPrintBiodata();
		// await this._sendIuran();
		await this._downloadKeteranganAnggota();
		await this._downloadReportByPendidikan();
	},

	async _setView() {
		document.getElementById('main-content').classList.remove('bg-gradient-primary');
		document.getElementById('main-content').style.removeProperty("height");
		document.getElementById('main-content').style.removeProperty("padding-top");
		document.getElementById('content-wrapper').style.removeProperty("overflow-x");
		document.getElementById('appbar').classList.remove('d-none');
		document.getElementById('accordionSidebar').classList.remove('d-none');
		document.getElementById("main-content").style.height = "";

		const formattedNumberInput = document.getElementById('textKTANAnggotaManual');
		const blocks = [4, 8, 1, 6]; // Jumlah digit pada setiap blok
		const totalLength = blocks.reduce((total, num) => total + num, 0);

		formattedNumberInput.addEventListener('input', (event) => {
			const inputValue = event.target.value;
			const validInput = inputValue.replace(/\D/g, ''); // Hapus karakter non-digit

			// Potong input jika panjang melebihi total panjang blok
			const formattedValue = validInput.slice(0, totalLength);

			let formattedResult = '';
			let index = 0;

			for (const block of blocks) {
				const chunk = formattedValue.slice(index, index + block);
				formattedResult += chunk;

				if (formattedValue.length > index + block) {
					formattedResult += '.';
				}

				index += block;
			}

			event.target.value = formattedResult;

			// Nonaktifkan input jika format sudah mencapai panjang yang diinginkan
			if (formattedValue.length >= totalLength) {
				event.preventDefault();
			}
		});

		// here
		// let responseLocked = await ApiAnggota.checkIsPcPdLocked();
		// if (responseLocked.status_code == 200) {
		// 	if (responseLocked.data.is_pc_locked) {
		// 		window.location.hash = '#/404?reason=iuranpd'
		// 	}
		// }

	},

	async _downloadKeteranganAnggota(){
		let uuid = '';
		$("#tableDataAnggota tbody").on("click", "#btnModalDownloadKeanggotaan", async function () {
			
			const table = $("#tableDataAnggota").DataTable();
			let data = table.row($(this).parents('tr')).data();
			let uuidAnggota = data.uuid;
			uuid = uuidAnggota;
			
			const getAnggotaById = await ApiKepengurusanPP.getAnggotaByID(uuidAnggota);
			let dataByID = getAnggotaById.data;


			if (getAnggotaById.status_code != 200) {
				NotificationModal.show(`${updateSetting.message}`, 'Gagal memuat detail anggota !');

			}else {
				
				var sipttkDropdown = document.getElementById("selectSIPTTK");
				var lengthOptionSipttkDropdown = sipttkDropdown.options.length;
				for (let i = lengthOptionSipttkDropdown-1; i >= 0; i--) {
					sipttkDropdown.options[i] = null;
				}
				dataByID.sipttk.forEach(data => {
					document.getElementById("selectSIPTTK").add(new Option(data.jenis_sipttk+" - "+data.nomor_sipttk , data.id))
				})
				
			}
			


			const eventDownload = async () => {
				
				let userId = dataByID.id
				let nomorUrutSuratKeterangan = document.getElementById("nomorUrutSuratKeterangan").value
				let sipttkId = document.getElementById("selectSIPTTK").value
				let namaAnggota = dataByID.name

				if (nomorUrutSuratKeterangan == "") {
					NotificationModal.show("Nomor urut surat tidak boleh kosong", "warning")
				}else {
					document.getElementById("btnActionDownloadKeanggotaan").innerHTML = `<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>`
					const dataDownload = await ApiKepengurusanPC.downloadKeteranganKeanggotaan({
						userId: userId,
						nomorUrutSurat: nomorUrutSuratKeterangan,
						sipttkId: sipttkId,
						namaAnggota: namaAnggota
					});
				}
				
				document.getElementById("btnActionDownloadKeanggotaan").innerHTML = `<i class='fas fa-download'></i> Download Surat Keterangan Keanggotaan`

			}

			document.getElementById("btnActionDownloadKeanggotaan").addEventListener('click', eventDownload)

		})
	},

	
	async _downloadReportByPendidikan() {

		const eventDownload = async () => {
			let filter = document.getElementById('selectFilter').value;
			let documentType = document.getElementById('selectDocument').value;
			let role = 'cabang';
			const btn = document.getElementById('btnGenerateReport');
			if (filter == '' || documentType == ''){
				NotificationModal.show('Harap lengkapi semua isian !', 'warning');
			}else {
				btn.disable = true;
				btn.innerHTML = '<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>';
				const downloadReportByPendidikan = await ApiKepengurusanPP.downloadReportByPendidikan({
					filter : filter,
					document_type : documentType,
					role : role
				});
				swal.fire({
					title: 'Success !',
					html: "Data berhasil di download ",
					icon: 'success',
					showCancelButton: false,
					confirmButtonColor: '#3085d6',
					cancelButtonColor: '#d33',
					confirmButtonText: 'OK'
				})
	
				btn.innerHTML = '<i class="fas fa-download"></i> Download Report';
				btn.disabled = false;
			}
			
			
		}

		document.getElementById('btnGenerateReport').addEventListener('click', eventDownload);


		// $('#tablePengajuanSTRTTK tbody').on('click', '#btnDownloadByPendidikan', async function () {
		// 	$(this).html('<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>');
		// 	let table = $('#tablePengajuanSTRTTK').DataTable();
		// 	let data = table.row($(this).parents('tr')).data();
		// 	let id = data.id;

		// 	if (data.status_pd != "approve") {
		// 		swal.fire({
		// 			title: 'Warning !',
		// 			html: "Status Permohonan : <strong><u>" + data.status_pd + "</u></strong><br>Anda tidak dapat mendownload surat rekomendasi sebelum di setujui oleh Admin PD ",
		// 			icon: 'warning',
		// 			showCancelButton: false,
		// 			confirmButtonColor: '#3085d6',
		// 			cancelButtonColor: '#d33',
		// 			confirmButtonText: 'OK'
		// 		})
		// 	} else {
		// 		const downloadRekomSTRTTKPD = await ApiAnggota.downloadSTRTTKPD({ id });
		// 	}
		// 	$(this).html('<i class="fas fa-download"></i> Rekomendasi STRTTK');

		// });
	},


	
	async _updateDataAnggota(){
		let uuid = '';
		$("#tableDataAnggota tbody").on("click", "#edit", async function () {
			
			var loadingElement = document.createElement("div");
			loadingElement.setAttribute("id", "loading");
			
			var spinnerElement = document.createElement("div");
			spinnerElement.setAttribute("class", "spinner");
			
			for (var i = 0; i < 3; i++) {
				var barElement = document.createElement("div");
				barElement.setAttribute("class", "bar");
				spinnerElement.appendChild(barElement);
			}
			
			loadingElement.appendChild(spinnerElement);
			document.body.appendChild(loadingElement);
			
			const table = $("#tableDataAnggota").DataTable();
			let data = table.row($(this).parents('tr')).data();
			let uuidAnggota = data.uuid;
			uuid = uuidAnggota;
			
			const getAnggotaById = await ApiKepengurusanPP.getAnggotaByID(uuidAnggota);
			

			let dataByID = getAnggotaById.data;
			if (getAnggotaById.status_code != 200) {
				NotificationModal.show(`${updateSetting.message}`, 'Gagal memuat detail anggota !');

			}else {
				var jk = '';
				$("#registerTextNamaLengkap").val(dataByID.name)
				$("#registerSelectPC").val(dataByID.user_pc_aktif_id)
				$("#registerTextNIK").val(dataByID.nik)
				$("#registerNumberNoHP").val(dataByID.phone_number)
				$("#registerEmail").val(dataByID.email)
				if (dataByID.jenis_kelamin == 'Laki-laki') {
					jk = 'L'
				}else {
					jk = 'P'
				}
				$("#registerSelectJenisKelamin").val(jk)
				$("#registerTanggalLahir").val(dataByID.tanggal_lahir)
			}
			var loadingElement = document.getElementById("loading");
			if (loadingElement) {
				loadingElement.parentNode.removeChild(loadingElement);
			}

		})

		const eventUpdateDataAnggota = async (e) => {
			e.preventDefault();
			let nikUser = document.getElementById('registerTextNIK').value;
			let isNikValid = NikParser(nikUser)
			if (isNikValid.isValid == false) {
				NotificationModal.show(`NIK Tidak valid`, 'warning');
				return
			}else{
				document.getElementById("btnUpdateAnggota").innerHTML = `<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>`
				const dataRegister = await ApiKepengurusanPP.updateDataAnggota({
					uuid: uuid,
					nik:nikUser,
					nama_lengkap: document.getElementById('registerTextNamaLengkap').value,
					no_hp: document.getElementById('registerNumberNoHP').value,
					email: document.getElementById('registerEmail').value,
					jenis_kelamin: document.getElementById('registerSelectJenisKelamin').value,
					tanggal_lahir: document.getElementById('registerTanggalLahir').value,
	
				});
	
				if (dataRegister.status_code != 200) {
					NotificationModal.show(`${dataRegister.message}`, 'error');
					document.getElementById("btnUpdateAnggota").innerHTML = `Update Data Anggota`;
				} else {
					NotificationModal.show(`${dataRegister.message}`, 'success');
					document.getElementById("btnUpdateAnggota").innerHTML = `Update Data Anggota`;

					// $('#tableDataAnggota').DataTable().ajax.reload();
					// refresh manual
					// await this._initGetAnggota();
					$('#editAnggotaModal').modal('hide');
					await this._getDataAnggotaFiltered();
					
				}
			}
			e.preventDefault();
		}

		
		document.getElementById('form-update-anggota').addEventListener('submit', eventUpdateDataAnggota);
	},

	// async printPageArea(areaID){
	// 	var newWindow = window.open('','_blank')
	// 	var printContent = document.getElementById(areaID).innerHTML;
	// 	newWindow.document.write(printContent);
	// 	newWindow.print();
	// },

	// async _initPrintBiodata (){
	// 	// let areaPrint = document.getElementById('biodataPrint')

	// 	const printArea = async (e) => {
	// 		e.preventDefault()
	// 		await this.printPageArea('biodataPrint');
			
	// 		e.preventDefault()
	// 	}

		
	// 	document.getElementById('btnPrintBiodata').addEventListener('click', printArea);

	// },

	
	async _updateNIKAnggota(){
		let uuid = '';
		$("#tableDataAnggota tbody").on("click", "#editNIK", async function () {
			
			var loadingElement = document.createElement("div");
			loadingElement.setAttribute("id", "loading");
			
			var spinnerElement = document.createElement("div");
			spinnerElement.setAttribute("class", "spinner");
			
			for (var i = 0; i < 3; i++) {
				var barElement = document.createElement("div");
				barElement.setAttribute("class", "bar");
				spinnerElement.appendChild(barElement);
			}
			
			loadingElement.appendChild(spinnerElement);
			document.body.appendChild(loadingElement);
			
			const table = $("#tableDataAnggota").DataTable();
			let data = table.row($(this).parents('tr')).data();
			let uuidAnggota = data.uuid;
			uuid = uuidAnggota;
			
			const getAnggotaById = await ApiKepengurusanPP.getAnggotaByID(uuidAnggota);

			let dataByID = getAnggotaById.data;
			if (getAnggotaById.status_code != 200) {
				NotificationModal.show(`${updateSetting.message}`, 'Gagal memuat detail anggota !');

			}else {
				$("#textNIKAnggota").val(dataByID.nik)
			}
			var loadingElement = document.getElementById("loading");
			if (loadingElement) {
				loadingElement.parentNode.removeChild(loadingElement);
			}

		})

		const eventUpdateDataAnggota = async (e) => {
			e.preventDefault();
			let nikUser = document.getElementById('textNIKAnggota').value;
			let isNikValid = NikParser(nikUser)
			if (isNikValid.isValid == false) {
				NotificationModal.show(`NIK Tidak valid`, 'warning');
				return
			}else{
				document.getElementById("btnSaveNIK").innerHTML = `<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>`
				const dataRegister = await ApiKepengurusanPP.updateDataAnggota({
					uuid: uuid,
					nik:nikUser,
	
				});
	
				if (dataRegister.status_code != 200) {
					NotificationModal.show(`${dataRegister.message}`, 'error');
					document.getElementById("btnSaveNIK").innerHTML = `<i class="fas fa-save"></i> Simpan`;
				} else {
					NotificationModal.show(`${dataRegister.message}`, 'success');
					document.getElementById("btnSaveNIK").innerHTML = `<i class="fas fa-save"></i> Simpan`;

					// $('#tableDataAnggota').DataTable().ajax.reload();
					// refresh manual
					// await this._initGetAnggota();
					$('#editNIKAnggotaModal').modal('hide');
					await this._getDataAnggota();
					
				}
			}
			e.preventDefault();
		}

		
		document.getElementById('btnSaveNIK').addEventListener('click', eventUpdateDataAnggota);
	},

	async _getPengajuanKTANFiltered(){
		let status = document.getElementById('selectStatus').value;
		let startDate = document.getElementById('dateDariTanggal').value;
		let endDate = document.getElementById('dateSampaiTanggal').value

		this._getPengajuanKTAN(status, startDate, endDate)

		const eventFilterd = async (e) => {
			let status = document.getElementById('selectStatus').value;
			let startDate = document.getElementById('dateDariTanggal').value;
			let endDate = document.getElementById('dateSampaiTanggal').value

			this._getPengajuanKTAN(status, startDate, endDate)
		}
		
		document.getElementById('selectStatus').addEventListener('change', eventFilterd)
		document.getElementById('dateDariTanggal').addEventListener('change', eventFilterd)
		document.getElementById('dateSampaiTanggal').addEventListener('change', eventFilterd)
	},

	async _getPengajuanKTAN(status,startDate,endDate) {
		var savedOrderColumn = 'created_at';
		var savedOrderDirection = 'desc';

		let bg = '';
		let contentStatus = '';
		if (status == 'pending') {
			bg = 'warning'
			contentStatus = 'Pending'
		}else if (status == 'approve') {
			bg = 'success'
			contentStatus = 'Approve'
		}else {
			bg = 'danger'
			contentStatus = 'Revise'
		}
		let table = await $('#tablePengajuanKTAN').DataTable({
			"pageLength": 10,
			"lengthMenu": [10, 25, 50, 100],
			"processing": true,
			"destroy": true,

			dom: 'lBfrtip<"actions">',
			buttons: [
				{
					extend: 'copy',
					text: '<i class="fas fa-copy"></i>',
					exportOptions: {
						columns: ':visible'
					},
					className: "btn btn-light border-primary",
					titleAttr: "Copy to clipboard"
				},
				{
					extend: 'csv',
					text: '<i class="fas fa-file-csv"></i>',
					exportOptions: {
						columns: ':visible'
					},
					className: "btn btn-light border-primary",
					titleAttr: "Download file csv"
				},
				{
					extend: 'excel',
					text: '<i class="fas fa-file-excel"></i>',
					exportOptions: {
						columns: ':visible'
					},
					className: "btn btn-light border-primary",
					titleAttr: "Download file excel"
				},
				{
					extend: 'pdf',
					text: '<i class="fas fa-file-pdf"></i>',
					exportOptions: {
						columns: ':visible'
					},
					className: "btn btn-light border-primary",
					titleAttr: "Download file pdf"
				},
				{
					extend: 'print',
					text: '<i class="fas fa-print"></i>',
					exportOptions: {
						columns: ':visible'
					},
					className: "btn btn-light border-primary",
					titleAttr: "Print",
				},
				{
					text: "<i class='fas fa-check'></i> Approve All",
					exportOptions: {
						columns: ':visible'
					},
					className: "btn btn-primary",
					titleAttr: "Approve All",
					attr: {
						id: "btnVerifikasiAllDt"
					},
					action: function () {
						$("#btnVerifikasiAllDt").html("Loading...");
						$("#btnVerifikasiAll").trigger("click");
					}
				}
			],
			'language': {
				'loadingRecords': '&nbsp;',
				'processing': '<div class="spinner"></div>'
			},
			"serverSide": true,
			"searchDelay": 1350,
			"ajax": {
				"url": `${API_ENDPOINT_PENGURUS_PC.PENGAJUAN_KTAN_FILTERED(startDate, endDate, status)}`,
				"type": "GET",
				"beforeSend": function (xhr) {
					xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem(CONFIG.TOKEN_USER));
				},
				"data" : function (d) {
					d.orderColumn = savedOrderColumn;
					d.orderDirection = savedOrderDirection;
				}
			},
			"columns": [
				{ data: '' },
				{ data: 'created_at' },
				{ data: 'type_pengajuan', render : function (data) {
					if (data == 'new') {
						return `<div style="display:flex" class="col-sm-12 text-center">
						<span class="badge bg-primary text-light font-weight-none text-capitalize text-center" id="status">KTAN BARU</span>
					</div>`;
					}else {
						return `<div style="display:flex" class="col-sm-12 text-center">
						<span class="badge bg-success text-light font-weight-none text-capitalize text-center" id="status">PERPANJANGAN</span>
					</div>`;
					}
				} },
				{ data: 'user.name' },
				{ data: 'user.nik' },
				{
					data: 'dokumen.ktp_photo',
					render: function (data) {
						if (data == null || data == "") {
							return '-'
						} else {
							return '<img loading="lazy" id="show" src="' + data + '" onerror="this.onerror=null; this.src=\'./src/public/img/image-corrupt-min.png\';" data-original="' + data + '" width="40px">'
						}
					}
				},
				{
					data: 'dokumen.pas_photo',
					render: function (data) {
						if (data == null || data == "") {
							return '-'
						} else {
							return '<img loading="lazy" id="show" src="' + data + '" onerror="this.onerror=null; this.src=\'./src/public/img/image-corrupt-min.png\';" data-original="' + data + '" width="40px">'
						}
					}
				},
				{
					data: 'dokumen.bukti_bayar_ktan',
					render: function (data) {
						if (data == null || data == "") {
							return '-'
						} else {
							return '<img loading="lazy" id="show" src="' + data + '" onerror="this.onerror=null; this.src=\'./src/public/img/image-corrupt-min.png\';" data-original="' + data + '" width="40px">'
						}
					}
				},
				{
					data: 'dokumen.ijazah',
					render: function (data) {
						if (data == null || data == "") {
							return '-'
						} else {
							return '<img loading="lazy" id="show" src="' + data + '" onerror="this.onerror=null; this.src=\'./src/public/img/image-corrupt-min.png\';" data-original="' + data + '" width="40px">'
						}
					}
				},
				{
					data: 'status',
					render: function (data) {
						var status = `<div class="d-flex" style="display:flex">${data}</div>`


						return status
					}

				},
				{
					data: 'status_pc',
					render:function(data){
						var bgStatus2 = ''
						if (data == 'approve') {
							bgStatus2 = 'success'
						}else if (data == 'revise') {
							bgStatus2 = 'danger'
						}else {
							bgStatus2  = 'warning'
						}
						return `<div style="display:flex" class="col-sm-12 text-center">
						<span class="badge bg-${bgStatus2} text-light font-weight-none text-capitalize text-center" id="status">${data}</span>
					</div>`
					}
				},
				{
					data: 'adminPc.name',
					render: function (data) {

						if (data == null || data == '') {
							var status = 'Tidak ada data'
						} else {
							var status = `<div class="d-flex" style="display:flex">${data}</div>`
						}


						return status
					}

				},
				{
					data: 'approval_cabang_at',
					render: function (data) {

						if (data == null || data == '') {
							var status = 'Tidak ada data'
						} else {
							var status = `<div class="d-flex" style="display:flex">${data}</div>`
						}


						return status
					}

				},
				{
					data: 'adminPd.name',
					render: function (data) {
						if (data == null || data == '') {
							var status = 'Tidak ada data'
						} else {
							var status = `<div class="d-flex" style="display:flex">${data}</div>`
						}
						return status
					}

				},
				{
					data: 'approval_daerah_at',
					render: function (data) {
						if (data == null || data == '') {
							var status = 'Tidak ada data'
						} else {
							var status = `<div class="d-flex" style="display:flex">${data}</div>`
						}
						return status
					}

				},
				{
					data: 'adminPp.name',
					render: function (data) {
						if (data == null || data == '') {
							var status = 'Tidak ada data'
						} else {
							var status = `<div class="d-flex" style="display:flex">${data}</div>`
						}

						return status
					}

				},
				{
					data: 'approval_pusat_at',
					render: function (data) {
						if (data == null || data == '') {
							var status = 'Tidak ada data'
						} else {
							var status = `<div class="d-flex" style="display:flex">${data}</div>`
						}

						return status
					}

				},
			],
			"fnDrawCallback": function (data) {
				let rowTable = data.aoData;
				const checked = document.getElementById('checkAll').checked;
				if (checked) {
					document.getElementById('checkAll').checked = false;
				}

				$('#tablePengajuanKTAN tbody tr').each(function (e) {
					if (rowTable[e] !== undefined) {
						let result = rowTable[e]._aData.id;
						let isChecked = StorageAnggotaPC.isReady(result);
						if (isChecked) {
							$(this).addClass('selected');
						}
					}
				})
			},
			select: {
				style: 'multi',
				selector: 'td:first-child'
			},
			order: [
				[1, 'desc']
			],
			"columnDefs": [{
				"targets": 0,
				"orderable": false,
				className: 'select-checkbox',
				"data": null,
				"defaultContent": ``
			},
			{
				"targets": [3,4,5,6,7,9,10,12,14],
				"orderable": false
			},
			{
				"targets": [0],
				"visible": true,
				"searchable": false
			},
			{
				"targets": 17,
				"data": null,
				render : function (row , s , data) {
					let content = ``;
					if (data.status == 'Disetujui PD' || data.status == 'Disetujui PP' || data.status == 'Revisi PD' || data.status == 'Revisi PP') {
						// here
						content = `
						<div style="display:flex">
							<button style="margin:1px" class='btn btn-primary btn-sm' id='detail' data-toggle="modal" data-target="#detailAnggotaModal" title='Detail Anggota'><i class='fas fa-eye'></i></button>
							<button style="margin:1px; position:relative;" class='btn btn-info btn-sm' data-toggle="modal" data-target="#commentModalUserKTAN" id='comment' title='Komentar'><i class='fas fa-comment fa-fw'></i><span class="badge-chat" style="">${data.comment_unread_count}</span></button>
						</div>`;
					}else if(data.status == "Disetujui PC"){
						content = `
						<div style="display:flex">
							<button style="margin:1px" class='btn btn-primary btn-sm' id='detail' data-toggle="modal" data-target="#detailAnggotaModal" title='Detail Anggota'><i class='fas fa-eye'></i></button>
							<button style="margin:1px" class='btn btn-danger btn-sm' data-toggle="modal" data-target="#commentModal" id='revisi' title='Revisi'><i class='fas fa-times'></i></button>
							<button style="margin:1px; position:relative;" class='btn btn-info btn-sm' data-toggle="modal" data-target="#commentModalUserKTAN" id='comment' title='Komentar'><i class='fas fa-comment fa-fw'></i><span class="badge-chat" style="">${data.comment_unread_count}</span></button>
						</div>`;
					}else {
						content = `
						<div style="display:flex">
							<button style="margin:1px" class='btn btn-primary btn-sm' id='detail' data-toggle="modal" data-target="#detailAnggotaModal" title='Detail Anggota'><i class='fas fa-eye'></i></button>
							<button style="margin:1px" class='btn btn-success btn-sm' id='approve' title='Approve'><i class='fas fa-check'></i></button>
							<button style="margin:1px" class='btn btn-danger btn-sm' data-toggle="modal" data-target="#commentModal" id='revisi' title='Revisi'><i class='fas fa-times'></i></button>
							<button style="margin:1px; position:relative;" class='btn btn-info btn-sm' data-toggle="modal" data-target="#commentModalUserKTAN" id='comment' title='Komentar'><i class='fas fa-comment fa-fw'></i><span class="badge-chat" style="">${data.comment_unread_count}</span></button>
						</div>`;
					}
					
					
					return content
				} 
			},
			{
				"targets": [4, 5, 6, 7],
				"visible": true,
				"searchable": true,
				"className": "text-center",
			}
			]
		});

		$('#tablePengajuanKTAN tbody').on('click', '.select-checkbox', async function (e) {
			e.stopPropagation();
			const table = $('#tablePengajuanKTAN').DataTable();
			let data = table.row($(this).parents('tr')).data();
			let idPengajuan = data.id

			if ($(this).parents('tr').hasClass("selected")) {
				$(this).parents('tr').removeClass('selected');
				await StorageAnggotaPC.deleteData(idPengajuan);
			} else {
				$(this).parents('tr').addClass('selected');
				await StorageAnggotaPC.pushData(idPengajuan);
			}

			// console.log( `data  ${await StorageAnggotaPC.getData()}`)
			// console.log(`size  ${await StorageAnggotaPC.size()}`)
		});

		$('#tablePengajuanKTAN thead').off('click', 'th').on('click', 'th', function () {
			let chachedCoulumnName = savedOrderColumn;
			const columnIndex = $(this).index();
			const columnHeaders = $('#tablePengajuanKTAN thead th');
			const columnName = $(columnHeaders[columnIndex]).data('column');
			var order =  table.order()[0][1];
			
			if (order == "asc") {
				order = "desc"
			}else{
				order = "asc"
			}
			if(chachedCoulumnName != columnName){
				order = "asc"
			}
			savedOrderColumn = columnName;
			chachedCoulumnName = savedOrderColumn;
			savedOrderDirection = order;

		});



	},

	async _downloadAnggota(){
		const eventDownload =  async (e) => {
			e.preventDefault()
			document.getElementById("btnDownloadAnggota").innerHTML = `<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>`
			let table = await $('#tableDataAnggota').DataTable()
			let pageLength = table.page.len()
			let page = table.page.info().page + 1;
			let start = (page-1) * pageLength;
			let statusKTAN = document.getElementById('selectJenisKeanggotaan').value;
			let statusRegist = 1
			const downloadAnggota = await ApiKepengurusanPC.downloadAnggota({
				statusRegist : statusRegist,
				statusKTAN : statusKTAN,
				start : start,
				length : pageLength,
			})
			document.getElementById('btnDownloadAnggota').innerHTML = `<i class="fas fa-download"></i> Download Data Anggota`;
			e.preventDefault()
		}
		
		document.getElementById('btnDownloadAnggota').addEventListener('click', eventDownload);
	},

	async _getRegistFiltered(){
		let status = document.getElementById('selectStatusRegist').value;
		await this._getRegistrasiAnggotaLama(status);

		const giveEventChange = async ()  => {
			let status = document.getElementById('selectStatusRegist').value;
			await this._getRegistrasiAnggotaLama(status);
		}

		document.getElementById('selectStatusRegist').addEventListener('change', giveEventChange)
	},

	async _getRegistrasiAnggotaLama(status) {
		let disableByStatus =  ``;
		if (status == 1) {
			disableByStatus = 'd-none';
		}else {
			disableByStatus = '';
		}
		var savedOrderColumn = 'created_at';
		var savedOrderDirection = 'desc';
		let table = await $('#tableRegistrasiAnggotaLama').DataTable({
			"pageLength": 10,
			"lengthMenu": [10, 25, 50, 100],
			"processing": true,
			"destroy": true,

			dom: 'lBfrtip<"actions">',
			buttons: [
				{
					extend: 'copy',
					text: '<i class="fas fa-copy"></i>',
					exportOptions: {
						columns: ':visible'
					},
					className: "btn btn-light border-primary",
					titleAttr: "Copy to clipboard"
				},
				{
					extend: 'csv',
					text: '<i class="fas fa-file-csv"></i>',
					exportOptions: {
						columns: ':visible'
					},
					className: "btn btn-light border-primary",
					titleAttr: "Download file csv"
				},
				{
					extend: 'excel',
					text: '<i class="fas fa-file-excel"></i>',
					exportOptions: {
						columns: ':visible'
					},
					className: "btn btn-light border-primary",
					titleAttr: "Download file excel"
				},
				{
					extend: 'pdf',
					text: '<i class="fas fa-file-pdf"></i>',
					exportOptions: {
						columns: ':visible'
					},
					className: "btn btn-light border-primary",
					titleAttr: "Download file pdf"
				},
				{
					extend: 'print',
					text: '<i class="fas fa-print"></i>',
					exportOptions: {
						columns: ':visible'
					},
					className: "btn btn-light border-primary",
					titleAttr: "Print",
				},
				{
					text: "<i class='fas fa-check'></i> Approve All",
					exportOptions: {
						columns: ':visible'
					},
					className: `btn btn-primary ${disableByStatus}`,
					titleAttr: "Approve All",
					attr: {
						id: "btnVerifikasiAllAnggotaLama"
					},
					action: function () {
						$("#btnVerifikasiAllDtLama").html("Loading...");
						$("#btnVerifikasiAllAnggotaLama").trigger("click");
					}
				}
			],
			'language': {
				'loadingRecords': '&nbsp;',
				'processing': '<div class="spinner"></div>'
			},
			"serverSide": true,
			"searchDelay": 1350,
			"ajax": {
				"url": `${API_ENDPOINT_PENGURUS_PC.GET_REGISTRASI_ANGGOTA_LAMA(status)}`,
				"type": "GET",
				"beforeSend": function (xhr) {
					xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem(CONFIG.TOKEN_USER));
				},
				"data" : function (d) {
					d.orderColumn = savedOrderColumn;
    				d.orderDirection = savedOrderDirection;
				}
			},
			"columns": [
				{ data: '' },
				{ data: 'created_at' },
				{ data: 'name' },
				{ data: 'phone_number', render : function (data) {
					let content = ``;
					if (data != null) {	
						let nomorTeleponBaru = data.replace(/^0/, "62");
						content = `${data} <a href="https://api.whatsapp.com/send?phone=${nomorTeleponBaru}" class="text-success" target="blank()"><i class="fab fa-whatsapp"></i> Chat</a>`;
					}else {
						content = '-';
					}

					return content
				}},
				{ data: 'ktan' },
				{ data: 'nik' },
				{ data: 'email' },
				{ data: 'ktan_expires_at' },
				{
					data: 'old_ktan',
					render: function (data) {
						if (data == null || data == "") {
							return '-'
						} else {
							return '<img loading="lazy" id="show" src="' + data + '" onerror="this.onerror=null; this.src=\'./src/public/img/image-corrupt-min.png\';" data-original="' + data + '" width="40px">'
						}
					}
				},
				{ data: 'periode_iuran.bulan_iuran_awal' },
				{ data: 'periode_iuran.bulan_iuran_akhir' },
				{
					data: 'bukti_iuran_terakhir',
					render: function (data) {
						if (data == null || data == "") {
							return '-'
						} else {
							return '<img loading="lazy" id="show" src="' + data + '" onerror="this.onerror=null; this.src=\'./src/public/img/image-corrupt-min.png\';" data-original="' + data + '" width="40px">'
						}
					}
				},
				{
					data: 'status_pc', render: function (data) {
						if (data == 'pending') {
							return `
							<div class="badge badge-warning text-capitalize">${data}</div>
						`
						} else {
							return `
							<div class="badge badge-success text-capitalize">${data}</div>
						`
						}
					}
				},

			],
			"fnDrawCallback": function (data) {
				let rowTable = data.aoData;
				const checked = document.getElementById('checkAll').checked;
				if (checked) {
					document.getElementById('checkAll').checked = false;
				}
				$('#tableRegistrasiAnggotaLama tbody tr').each(function (e) {
					if (rowTable[e] !== undefined) {
						let result = rowTable[e]._aData.uuid;
						let isChecked = StorageRegistAnggotaPC.isReady(result);
						if (isChecked) {
							$(this).addClass('selected');
						}

					}
				})
			},
			select: {
				style: 'multi',
				selector: 'td:first-child'
			},
			order: [
				[1, 'desc']
			],
			"columnDefs": [{
				"targets": 0,
				"orderable": false,
				className: 'select-checkbox',
				"data": null,
				"defaultContent": ``
			},
			{
				"targets": [4,6,7,8,9,10,12],
				"orderable": false
			},
			{
				"targets": [0],
				"visible": true,
				"searchable": false
			},
			{
				"targets": 13,
				"data": null,
				render : function (data, type, row) {
					let status = row.status_pc;
					let content = ``;

					if (status == 'pending') {
						content = `
						<div style="display:flex">
							<button style="margin:1px" class='btn btn-primary btn-sm' id='approve' title='Approve'><i class='fas fa-check'></i></button>
							<button style="margin:1px" class='btn btn-danger btn-sm' data-toggle="modal" data-target="#commentModalRegist" id='rejectedRegist' title='Tolak Regitrasi'><i class='fas fa-times'></i></button>
						</div>`
					}else {
						content = `
						<div style="display:flex">
							<button style="margin:1px" class='btn btn-danger btn-sm' data-toggle="modal" data-target="#commentModalRegist" id='rejectedRegist' title='Tolak Regitrasi'><i class='fas fa-times'></i></button>
						</div>`
					}

					return content;
				}
				
			},
			{
				"targets": [7, 9, 10],
				"visible": true,
				"searchable": true,
				"className": "text-center",
			}
			]
		});

		$('#tableRegistrasiAnggotaLama tbody').on('click', '.select-checkbox', async function (e) {
			e.stopPropagation();
			const table = $('#tableRegistrasiAnggotaLama').DataTable();
			let data = table.row($(this).parents('tr')).data();
			let idPengajuan = data.uuid
			if ($(this).parents('tr').hasClass("selected")) {
				$(this).parents('tr').removeClass('selected');
				await StorageRegistAnggotaPC.deleteData(idPengajuan);
			} else {
				$(this).parents('tr').addClass('selected');
				await StorageRegistAnggotaPC.pushData(idPengajuan);
			}
		});

		$('#tableRegistrasiAnggotaLama thead').off('click', 'th').on('click', 'th', function () {
			let chachedCoulumnName = savedOrderColumn;
			const columnIndex = $(this).index();
			const columnHeaders = $('#tableRegistrasiAnggotaLama thead th');
			const columnName = $(columnHeaders[columnIndex]).data('column');
			var order =  table.order()[0][1];
			
			if (order == "asc") {
				order = "desc"
			}else{
				order = "asc"
			}
			if(chachedCoulumnName != columnName){
				order = "asc"
			}
			savedOrderColumn = columnName;
			chachedCoulumnName = savedOrderColumn;
			savedOrderDirection = order;
		});

	},

	async _getDataAnggotaFiltered(){
		let status = document.getElementById('selectJenisKeanggotaan').value
		let jenjang = document.getElementById('selectJenjang').value
		await this._getDataAnggota(status, jenjang)

		const EventFiltered = async () => {
			let status = document.getElementById('selectJenisKeanggotaan').value
			let jenjang = document.getElementById('selectJenjang').value
			await this._getDataAnggota(status, jenjang)
		}

		document.getElementById('selectJenisKeanggotaan').addEventListener('change', EventFiltered);
		document.getElementById('selectJenjang').addEventListener('change', EventFiltered);
	},

	async _getDataAnggota(status_ktan, jenjang) {
		var savedOrderColumn = 'name';
		var savedOrderDirection = 'asc';
		let table = await $('#tableDataAnggota').DataTable({
			"pageLength": 10,
			"lengthMenu": [10, 25, 50, 100],
			"processing": true,
			dom: 'lBfrtip<"actions">',
			buttons: [
				{
					extend: 'copy',
					text: '<i class="fas fa-copy"></i>',
					exportOptions: {
						columns: ':visible'
					},
					className: "btn btn-light border-primary",
					titleAttr: "Copy to clipboard"
				},
				{
					extend: 'csv',
					text: '<i class="fas fa-file-csv"></i>',
					exportOptions: {
						columns: ':visible'
					},
					className: "btn btn-light border-primary",
					titleAttr: "Download file csv"
				},
				{
					extend: 'excel',
					text: '<i class="fas fa-file-excel"></i>',
					exportOptions: {
						columns: ':visible'
					},
					className: "btn btn-light border-primary",
					titleAttr: "Download file excel"
				},
				{
					extend: 'pdf',
					text: '<i class="fas fa-file-pdf"></i>',
					exportOptions: {
						columns: ':visible'
					},
					className: "btn btn-light border-primary",
					titleAttr: "Download file pdf"
				},
				{
					extend: 'print',
					text: '<i class="fas fa-print"></i>',
					exportOptions: {
						columns: ':visible'
					},
					className: "btn btn-light border-primary",
					titleAttr: "Print",
				},
				{
					text: '<i class="fas fa-download"></i> Download Rekap',
					exportOptions: {
						columns: ':visible'
					},
					className: "btn btn-primary",
					titleAttr: "Download Rekap Anggota",
					attr: {
						id: "btnDownloadReport",
						"data-toggle" : "modal",
						"data-target" : "#modalGenerateReport",
					},
				}
			],
			"destroy": true,
			'language': {
				'loadingRecords': '&nbsp;',
				'processing': '<div class="spinner"></div>'
			},
			"serverSide": true,
			"searchDelay": 1000,
			"ajax": {
				"url": `${API_ENDPOINT_PENGURUS_PC.GET_ALL_USER_KTAN_1(status_ktan,jenjang)}`,
				"type": "GET",
				"beforeSend": function (xhr) {
					xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem(CONFIG.TOKEN_USER));
				},
				"data" : function (d) {
					d.orderColumn = savedOrderColumn;
    				d.orderDirection = savedOrderDirection;
				}
			},
			"columns": [
				{ data: 'name' },
				{ data: 'email' },
				{ data: 'phone_number', render : function (data) {
					let content = ``;
					if (data != null) {	
						let nomorTeleponBaru = data.replace(/^0/, "62");
						content = `${data} <a href="https://api.whatsapp.com/send?phone=${nomorTeleponBaru}" class="text-success" target="blank()"><i class="fab fa-whatsapp"></i> Chat</a>`;
					}else {
						content = '-';
					}

					return content
				}},
				{ data: 'ktan' },
				{ data: 'strttk.nomor_strttk', render : function (data) {
					if (data == '' || data == null) {
						return '-'
					}else return data
				} },
				{data: 'pendidikan', render : function (data) {
					var viewHtml = ``
					if (data === undefined || data.length == 0) {
						viewHtml = '-'
					}else{
						data.forEach(pendidikanData => {
							viewHtml += `<li style="height:45px;  width:150px">${pendidikanData.jenjang}</li><br>`
						});
					}
					return viewHtml;
				}  },
				{ data: 'pendidikan', render : function (data) {
					var viewHtml = ``
					if (data === undefined || data.length == 0) {
						viewHtml = '-'
					}else{
						data.forEach(pendidikanData => {
							viewHtml += `<li style="height:45px; width:250px"> ${pendidikanData.nama_institusi_pendidikan}</li><br>`

						});
					}
					return viewHtml;
				}  },
				{ data: 'pendidikan', render : function (data) {
					var viewHtml = ``
					if (data === undefined || data.length == 0) {
						viewHtml = '-'
					}else{
						data.forEach(pendidikanData => {
							viewHtml += `<li style="height:45px; width:90px" >${pendidikanData.tahun_lulus}</li><br>`

						});
					}
					return viewHtml
				}  },
				{ data: 'pendidikan', render : function (data) {
					var viewHtml = ``
					if (data === undefined || data.length == 0) {
						viewHtml = '-'
					}else{
						data.forEach(pendidikanData => {
							viewHtml += `<li style="height:45px; width:120px">${pendidikanData.tanggal_ijazah}</li><br>`
						});
					}
					return viewHtml
				}  },
				{ data: 'jenis_kelamin' },
				{ data: 'tempat_lahir' },
				{ data: 'tanggal_lahir' },
				{
					data: 'ktan_expires_at', render: function (data) {
						if (data == null || data == "") {
							return '<span class="badge badge-danger">Belum Di Setting</span>'
						} else {
							return data;
						}
					}
				},
				{
					data: 'avatar', render: function (data) {
						if (data == null || data == "") {
							return '-'
						} else {
							return '<img loading="lazy" id="show" src="' + data + '" onerror="this.onerror=null; this.src=\'./src/public/img/image-corrupt-min.png\';" data-original="' + data + '" width="40px">'
						}
					}
				},
				{
					data: 'dokumen.old_ktan', render: function (data) {
						if (data == null || data == "") {
							return '-'
						} else {
							return '<img loading="lazy" id="show" src="' + data + '" onerror="this.onerror=null; this.src=\'./src/public/img/image-corrupt-min.png\';" data-original="' + data + '" width="40px">'
						}
					}
				},
				{
					data: 'dokumen.ktp_photo', render: function (data) {
						if (data == null || data == "") {
							return '-'
						} else {
							return '<img loading="lazy" id="show" src="' + data + '" onerror="this.onerror=null; this.src=\'./src/public/img/image-corrupt-min.png\';" data-original="' + data + '" width="40px">'
						}
					}
				},
				{
					data: 'dokumen.ijazah', render: function (data) {
						if (data == null || data == "") {
							return '-'
						} else {
							return '<img loading="lazy" id="show" src="' + data + '" onerror="this.onerror=null; this.src=\'./src/public/img/image-corrupt-min.png\';" data-original="' + data + '" width="40px">'
						}
					}
				},
			],
			"fnDrawCallback": function (data) {
				let rowTable = data.aoData;

			},
			select: {
				style: 'multi',
				selector: 'td:first-child'
			},
			order: [
				[0, 'asc']
			],
			"columnDefs": [
				{
					"targets": [0],
					"visible": true,
					"searchable": true
				},
				{
					"targets": [3,4,5,6,7,11,13,14,15,16],
					"orderable": false
				},
				{
					"targets": [13,14,15,16,17],
					"visible": true,
					"searchable": false,
					"className": "text-center"
				},
				{
					"targets": 17,
					"data": null,
					"render": function (data, type, row) {
						let disableEdit = ""
						if (row.ktan == null || row.ktan == '' || row.ktan =="" || row.ktan =="") {
							disableEdit = 'd-none'
						}
						let pd_id = localStorage.getItem('pd_id');
						let content_download = ``;
						if (pd_id == "32") {
							content_download = `<button style="margin:1px" class='btn btn-sm border-primary text-primary' id='btnModalDownloadKeanggotaan' title='Edit Data Anggota' data-toggle="modal" data-target="#modalDownloadKeanggotaan"><i class='fas fa-download'></i> Download Keterangan Keanggotaan</button>`;
						}
						
						return `
						<div style="display:flex">
							<button style="margin:1px" class='btn btn-primary btn-sm' id='detail' data-toggle="modal" data-target="#detailAnggotaModal" title='Detail Anggota'><i class='fas fa-eye'></i></button>
							<button style="margin:1px" class='btn btn-success btn-sm ${disableEdit}' data-toggle="modal" data-target="#editExpiredKTANModal" id='editExpiredKTAN' title='Ubah Masa Berlaku KTAN'><i class='fas fa-calendar-times'></i></button>
							<button style="margin:1px" class='btn btn-warning btn-sm' id='edit' title='Edit Data Anggota' data-toggle="modal" data-target="#editAnggotaModal"><i class='fas fa-edit'></i></button>
							<button style="margin:1px" class='btn btn-info btn-sm' id='editNIK' title='Edit NIK Anggota' data-toggle="modal" data-target="#editNIKAnggotaModal"><i class="fas fa-address-card"></i></button>
							<!--<button style="margin:1px"  data-toggle="modal" data-target="#commentModalDelete"  class='btn btn-danger btn-sm' id='delete' title='Hapus Anggota'><i class='fas fa-trash'></i></button>-->
						</div>
						<div>
							${content_download}
						</div>
					`
					},
				},
			]
		});

		$('#tableDataAnggota thead').off('click', 'th').on('click', 'th',function () {
			let chachedCoulumnName = savedOrderColumn;
			const columnIndex = $(this).index();
			const columnHeaders = $('#tableDataAnggota thead th');
			const columnName = $(columnHeaders[columnIndex]).data('column');
			var order =  table.order()[0][1];
			
			if (order == "asc") {
				order = "desc"
			}else{
				order = "asc"
			}
			if(chachedCoulumnName != columnName){
				order = "asc"
			}
			savedOrderColumn = columnName;
			chachedCoulumnName = savedOrderColumn;
			savedOrderDirection = order;
		});


	},


	// async _sendIuran(){
	// 	let uuid = '';
	// 	$("#tableDataAnggota tbody").on("click", "#sendIuran", async function () {
	// 		const table = $("#tableDataAnggota").DataTable();
	// 		let data = table.row($(this).parents('tr')).data();
	// 		uuid = data.uuid

	// 		$("#namaAnggota").html(data.name);
	// 		$("#emailAnggotaIuran").html(data.email);
	// 		$("#ktan").html(data.ktan);
	// 		$("#pdAnggota").html(data.daerah_name);
	// 		$("#pcAnggota").html(data.cabang_name);

	// 		const eventSendIuran = async (e) => {
	// 			e.preventDefault();

	// 			let jatuhTempo = document.getElementById('textJatuhTempo').value;
	// 			document.getElementById("btnSendIuran").innerHTML = `<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>`
	// 			const sendIuranAnggota = await ApiPengajuanKTANPC.sendIuran({
	// 				uuid:uuid,
	// 				jatuh_tempo:jatuhTempo
	// 			});
	// 			if (sendIuranAnggota.status_code != 200) {
	// 				NotificationModal.show(`${sendIuranAnggota.message}`, 'error');
	// 				document.getElementById("btnSendIuran").innerHTML = `Kirim Tagihan`;
	// 				$("#textJatuhTempo").val('');

	// 			} else {
	// 				NotificationModal.show('Tagihan iuran berhasil di kirim ke anggota !', 'success');
	// 				document.getElementById("btnSendIuran").innerHTML = `Kirim Tagihan`;
	// 				$("#textJatuhTempo").val('');
	// 			}
	// 			e.preventDefault();

	// 			$("#textNamaAnggota").val('');
	// 			$("#emailAnggota").val('');
	// 			$("#nikAnggota").val('');
	// 			$("#textKTANAnggotaManual").val('');
	// 			$("#masaAktifKTAN").val('');


	// 		}
	// 		document.getElementById('btnSendIuran').addEventListener('click', eventSendIuran);


	// 	});
	// },

	async _getDetailAnggota() {
		$("#tableDataAnggota tbody").on("click", "#detail", async function () {
			$("#detailPendidikan").html('')
			$("#detailPekerjaan").html('')
			$("#detailSerkom").html('')
			$("#detailSIP").html('')
			$("#detailSTR").html('')
			$("#detailAvatar").attr('src', 'images/logo.png')
			var loadingElement = document.createElement("div");
			loadingElement.setAttribute("id", "loading");

			var spinnerElement = document.createElement("div");
			spinnerElement.setAttribute("class", "spinner");

			for (var i = 0; i < 3; i++) {
				var barElement = document.createElement("div");
				barElement.setAttribute("class", "bar");
				spinnerElement.appendChild(barElement);
			}

			loadingElement.appendChild(spinnerElement);
			document.body.appendChild(loadingElement);

			const table = $("#tableDataAnggota").DataTable();
			let data = table.row($(this).parents('tr')).data();
			let uuidAnggota = data.uuid;
			const getAnggotaById = await ApiPengajuanKTANPC.getAnggotaByID(uuidAnggota);

			let dataByID = getAnggotaById.data;
			if (getAnggotaById.status_code != 200) {
				NotificationModal.show(`${updateSetting.message}`, 'Gagal memuat detail anggota !');
			} else {
				if (dataByID.avatar == '' || dataByID.avatar == null) {
					$("#detailAvatar").attr('src', 'images/avatar_not_found.png');
				} else {
					$("#detailAvatar").attr('src', dataByID.avatar);
				}
				$("#detailNamaAnggota").html(dataByID.name);
				$("#detailKTAN").html(dataByID.ktan);
				$("#detailNIK").html(dataByID.nik);
				$("#detailEmail").html(dataByID.email);
				$("#detailEmailHead").html(dataByID.email);
				$("#detailTempatLahir").html(dataByID.tempat_lahir);
				$("#detailTanggalLahir").html(dataByID.tanggal_lahir);
				$("#detailJenisKelamin").html(dataByID.jenis_kelamin);
				$("#detailNoHP").html(dataByID.phone_number);
				if (dataByID.alamat == null) {
					$("#detailAlamat").html("Alamat belum di input oleh anggota");
					$("#detailProvinsi").html("Alamat belum di input oleh anggota");
					$("#detailKota").html("Alamat belum di input oleh anggota");
					$("#detailKecamatan").html("Alamat belum di input oleh anggota");
					$("#detailKelurahan").html("Alamat belum di input oleh anggota");
				} else {
					$("#detailAlamat").html(dataByID.alamat.address);
					$("#detailProvinsi").html(dataByID.alamat.province_name);
					$("#detailKota").html(dataByID.alamat.regency_name);
					$("#detailKecamatan").html(dataByID.alamat.sub_regency_name);
					$("#detailKelurahan").html(dataByID.alamat.village_name);
				}
				if (dataByID.dokumen.bukti_bayar_ktan == null || dataByID.dokumen.bukti_bayar_ktan == '') {
					$("#detailBuktiBayar").html(`
						Tidak ada Bukti Bayar !
					`)
				} else {
					$("#detailBuktiBayar").html(`
						<img loading="lazy" src="${dataByID.dokumen.bukti_bayar_ktan}" width="50%">
					`)
				}


				$("#detailPendidikan").html(`
				
					<tr>
						<th class="text-center">Ijazah</th>
						<th>Jenjang</th>
						<th>Nama Perguruan Tinggi</th>
						<th>Tahun Lulus</th>
						<th>Nomor Ijazah</th>
					</tr>
				`)

				$("#detailPekerjaan").html(`
					<tr>
						<th class="text-center">Dokumen</th>
						<th>Nama Tempat Kerja</th>
						<th>Jenis</th>
						<th>Jabatan</th>
						<th>Dari Tahun</th>
						<th>Sampai Tahun</th>
						<th>No HP/ Telp</th>
						<th>Alamat</th>
					</tr>
				`)


				$("#detailSerkom").html(`
					<tr>
						<th class="text-center">Serkom</th>
						<th>Nomor</th>
						<th>Tanggal</th>
						<th>Berlaku Sampai</th>
					</tr>
				`)


				$("#detailSIP").html(`
					<tr>
						<th class="text-center">SIPTTK</th>
						<th>Jenis</th>
						<th>Nomor</th>
						<th>Tanggal</th>
						<th>Berlaku Sampai</th>
						<th>Supervisor</th>
						<th>STRA</th>
						<th>No STRTTK</th>
					</tr>
				`)


				$("#detailSTR").html(`
					<tr>
						<th class="text-center">STRTTK</th>
						<th>Nomor</th>
						<th>Tanggal</th>
						<th>Berlaku Sampai</th>
					</tr>
				`)


				var detPendidikan = ``;
				var detPekerjaan = ``;
				var detSerkom = ``;
				var detSIP = ``;
				var detSTR = ``;

				if (dataByID.pendidikan == null || dataByID.pendidikan == '') {
					$("#detailPendidikan").html(`
					<tr>
						<td>Tidak ada data Pendidikan !</td>
					</tr>
					`)
				} else {
					dataByID.pendidikan.forEach(dataPendidikan => {
						detPendidikan = `
							<tr>
								<td class="text-center"><img loading="lazy" src='${dataPendidikan.dokumen}' width="40px"></td>
								<td>${dataPendidikan.jenjang}</td>
								<td>${dataPendidikan.nama_institusi_pendidikan}</td>
								<td>${dataPendidikan.tahun_lulus}</td>
								<td>${dataPendidikan.nomor_ijazah}</td>
							</tr>
						`

						$("#detailPendidikan").append(detPendidikan)
					});

				}

				if (dataByID.pekerjaan == null || dataByID.pekerjaan == '') {
					$("#detailPekerjaan").html(`
					<tr>
						<td>Tidak ada data Pekerjaan !</td>
					</tr>
					`)
				} else {
					dataByID.pekerjaan.forEach(dataPekerjaan => {
						detPekerjaan = `
							<tr>
								<td class="text-center"><img loading="lazy" src='${dataPekerjaan.dokumen}' width="40px"></td>
								<td>${dataPekerjaan.nama_tempat_kerja}</td>
								<td>${dataPekerjaan.jenis_tempat_kerja}</td>
								<td>${dataPekerjaan.jabatan}</td>
								<td>${dataPekerjaan.dari_tahun}</td>
								<td>${dataPekerjaan.sampai_tahun}</td>
								<td>${dataPekerjaan.phone_number}</td>
								<td>${dataPekerjaan.alamat_tempat_kerja}</td>
							</tr>
						`

						$("#detailPekerjaan").append(detPekerjaan)
					});

				}

				if (dataByID.serkom == null || dataByID.serkom == '') {
					$("#detailSerkom").html(`
					<tr>
						<td>Tidak ada data SERKOM !</td>
					</tr>
					`)
				} else {
					dataByID.serkom.forEach(dataSerkom => {
						detSerkom = `
							<tr>
								<td class="text-center"><img loading="lazy" src='${dataSerkom.dokumen}' width="40px"></td>
								<td>${dataSerkom.nomor_serkom}</td>
								<td>${dataSerkom.tanggal}</td>
								<td>${dataSerkom.berlaku_sampai}</td>
							</tr>
						`

						$("#detailSerkom").append(detSerkom)
					});

				}


				if (dataByID.sipttk == null || dataByID.sipttk == '') {
					$("#detailSIP").html(`
					<tr>
						<td>Tidak ada data SIPTTK !</td>
					</tr>
					`)
				} else {
					dataByID.sipttk.forEach(dataSIP => {
						detSIP = `
							<tr>
								<td class="text-center"><img loading="lazy" src='${dataSIP.dokumen}' width="40px"></td>
								<td>${dataSIP.jenis_sipttk}</td>
								<td>${dataSIP.nomor_sipttk}</td>
								<td>${dataSIP.tanggal}</td>
								<td>${dataSIP.berlaku_sampai}</td>
								<td>${dataSIP.nama_apoteker_supervisi}</td>
								<td>${dataSIP.nomor_stra_apoteker}</td>
								<td>${dataSIP.nomor_strttk}</td>
							</tr>
						`

						$("#detailSIP").append(detSIP)
					});

				}


				if (dataByID.strttk == null || dataByID.strttk == '') {
					$("#detailSTR").html(`
					<tr>
						<td>Tidak ada data STRTTK !</td>
					</tr>
					`)
				} else {
					dataByID.strttk.forEach(dataSTR => {
						detSTR = `
							<tr>
								<td class="text-center"><img loading="lazy" src='${dataSTR.dokumen}' width="40px"></td>
								<td>${dataSTR.nomor_strttk}</td>
								<td>${dataSTR.tanggal}</td>
								<td>${dataSTR.berlaku_sampai}</td>
							</tr>
						`

						$("#detailSTR").append(detSTR)
					});

				}


			}

			var loadingElement = document.getElementById("loading");
			if (loadingElement) {
				loadingElement.parentNode.removeChild(loadingElement);
			}


		});


		$("#tablePengajuanKTAN tbody").on("click", "#detail", async function () {
			$("#detailPendidikan").html('')
			$("#detailPekerjaan").html('')
			$("#detailSerkom").html('')
			$("#detailSIP").html('')
			$("#detailSTR").html('')
			$("#detailAvatar").attr('src', 'images/logo.png')
			var loadingElement = document.createElement("div");
			loadingElement.setAttribute("id", "loading");

			var spinnerElement = document.createElement("div");
			spinnerElement.setAttribute("class", "spinner");

			for (var i = 0; i < 3; i++) {
				var barElement = document.createElement("div");
				barElement.setAttribute("class", "bar");
				spinnerElement.appendChild(barElement);
			}

			loadingElement.appendChild(spinnerElement);
			document.body.appendChild(loadingElement);

			const table = $("#tablePengajuanKTAN").DataTable();
			let data = table.row($(this).parents('tr')).data();
			console.log(data);
			let uuidAnggota = data.user.uuid;
			const getAnggotaById = await ApiPengajuanKTANPC.getAnggotaByID(uuidAnggota);

			let dataByID = getAnggotaById.data;
			if (getAnggotaById.status_code != 200) {
				NotificationModal.show(`${updateSetting.message}`, 'Gagal memuat detail anggota !');
			} else {
				if (dataByID.avatar == '' || dataByID.avatar == null) {
					$("#detailAvatar").attr('src', 'images/avatar_not_found.png');
				} else {
					$("#detailAvatar").attr('src', dataByID.avatar);
				}
				$("#detailNamaAnggota").html(dataByID.name);
				$("#detailKTAN").html(dataByID.ktan);
				$("#detailNIK").html(dataByID.nik);
				$("#detailEmail").html(dataByID.email);
				$("#detailEmailHead").html(dataByID.email);
				$("#detailTempatLahir").html(dataByID.tempat_lahir);
				$("#detailTanggalLahir").html(dataByID.tanggal_lahir);
				$("#detailJenisKelamin").html(dataByID.jenis_kelamin);
				$("#detailNoHP").html(dataByID.phone_number);
				if (dataByID.alamat == null) {
					$("#detailAlamat").html("Alamat belum di input oleh anggota");
					$("#detailProvinsi").html("Alamat belum di input oleh anggota");
					$("#detailKota").html("Alamat belum di input oleh anggota");
					$("#detailKecamatan").html("Alamat belum di input oleh anggota");
					$("#detailKelurahan").html("Alamat belum di input oleh anggota");
				} else {
					$("#detailAlamat").html(dataByID.alamat.address);
					$("#detailProvinsi").html(dataByID.alamat.province_name);
					$("#detailKota").html(dataByID.alamat.regency_name);
					$("#detailKecamatan").html(dataByID.alamat.sub_regency_name);
					$("#detailKelurahan").html(dataByID.alamat.village_name);
				}
				if (dataByID.dokumen.bukti_bayar_ktan == null || dataByID.dokumen.bukti_bayar_ktan == '') {
					$("#detailBuktiBayar").html(`
						Tidak ada Bukti Bayar !
					`)
				} else {
					$("#detailBuktiBayar").html(`
						<img loading="lazy" src="${dataByID.dokumen.bukti_bayar_ktan}" width="50%">
					`)
				}


				$("#detailPendidikan").html(`
				
					<tr>
						<th class="text-center">Ijazah</th>
						<th>Jenjang</th>
						<th>Nama Perguruan Tinggi</th>
						<th>Tahun Lulus</th>
						<th>Nomor Ijazah</th>
					</tr>
				`)

				$("#detailPekerjaan").html(`
					<tr>
						<th class="text-center">Dokumen</th>
						<th>Nama Tempat Kerja</th>
						<th>Jenis</th>
						<th>Jabatan</th>
						<th>Dari Tahun</th>
						<th>Sampai Tahun</th>
						<th>No HP/ Telp</th>
						<th>Alamat</th>
					</tr>
				`)


				$("#detailSerkom").html(`
					<tr>
						<th class="text-center">Serkom</th>
						<th>Nomor</th>
						<th>Tanggal</th>
						<th>Berlaku Sampai</th>
					</tr>
				`)


				$("#detailSIP").html(`
					<tr>
						<th class="text-center">SIPTTK</th>
						<th>Jenis</th>
						<th>Nomor</th>
						<th>Tanggal</th>
						<th>Berlaku Sampai</th>
						<th>Supervisor</th>
						<th>STRA</th>
						<th>No STRTTK</th>
					</tr>
				`)


				$("#detailSTR").html(`
					<tr>
						<th class="text-center">STRTTK</th>
						<th>Nomor</th>
						<th>Tanggal</th>
						<th>Berlaku Sampai</th>
					</tr>
				`)


				var detPendidikan = ``;
				var detPekerjaan = ``;
				var detSerkom = ``;
				var detSIP = ``;
				var detSTR = ``;

				if (dataByID.pendidikan == null || dataByID.pendidikan == '') {
					$("#detailPendidikan").html(`
					<tr>
						<td>Tidak ada data Pendidikan !</td>
					</tr>
					`)
				} else {
					dataByID.pendidikan.forEach(dataPendidikan => {
						detPendidikan = `
							<tr>
								<td class="text-center"><img loading="lazy" src='${dataPendidikan.dokumen}' width="40px"></td>
								<td>${dataPendidikan.jenjang}</td>
								<td>${dataPendidikan.nama_institusi_pendidikan}</td>
								<td>${dataPendidikan.tahun_lulus}</td>
								<td>${dataPendidikan.nomor_ijazah}</td>
							</tr>
						`

						$("#detailPendidikan").append(detPendidikan)
					});

				}

				if (dataByID.pekerjaan == null || dataByID.pekerjaan == '') {
					$("#detailPekerjaan").html(`
					<tr>
						<td>Tidak ada data Pekerjaan !</td>
					</tr>
					`)
				} else {
					dataByID.pekerjaan.forEach(dataPekerjaan => {
						detPekerjaan = `
							<tr>
								<td class="text-center"><img loading="lazy" src='${dataPekerjaan.dokumen}' width="40px"></td>
								<td>${dataPekerjaan.nama_tempat_kerja}</td>
								<td>${dataPekerjaan.jenis_tempat_kerja}</td>
								<td>${dataPekerjaan.jabatan}</td>
								<td>${dataPekerjaan.dari_tahun}</td>
								<td>${dataPekerjaan.sampai_tahun}</td>
								<td>${dataPekerjaan.phone_number}</td>
								<td>${dataPekerjaan.alamat_tempat_kerja}</td>
							</tr>
						`

						$("#detailPekerjaan").append(detPekerjaan)
					});

				}

				if (dataByID.serkom == null || dataByID.serkom == '') {
					$("#detailSerkom").html(`
					<tr>
						<td>Tidak ada data SERKOM !</td>
					</tr>
					`)
				} else {
					dataByID.serkom.forEach(dataSerkom => {
						detSerkom = `
							<tr>
								<td class="text-center"><img loading="lazy" src='${dataSerkom.dokumen}' width="40px"></td>
								<td>${dataSerkom.nomor_serkom}</td>
								<td>${dataSerkom.tanggal}</td>
								<td>${dataSerkom.berlaku_sampai}</td>
							</tr>
						`

						$("#detailSerkom").append(detSerkom)
					});

				}


				if (dataByID.sipttk == null || dataByID.sipttk == '') {
					$("#detailSIP").html(`
					<tr>
						<td>Tidak ada data SIPTTK !</td>
					</tr>
					`)
				} else {
					dataByID.sipttk.forEach(dataSIP => {
						detSIP = `
							<tr>
								<td class="text-center"><img loading="lazy" src='${dataSIP.dokumen}' width="40px"></td>
								<td>${dataSIP.jenis_sipttk}</td>
								<td>${dataSIP.nomor_sipttk}</td>
								<td>${dataSIP.tanggal}</td>
								<td>${dataSIP.berlaku_sampai}</td>
								<td>${dataSIP.nama_apoteker_supervisi}</td>
								<td>${dataSIP.nomor_stra_apoteker}</td>
								<td>${dataSIP.nomor_strttk}</td>
							</tr>
						`

						$("#detailSIP").append(detSIP)
					});

				}


				if (dataByID.strttk == null || dataByID.strttk == '') {
					$("#detailSTR").html(`
					<tr>
						<td>Tidak ada data STRTTK !</td>
					</tr>
					`)
				} else {
					dataByID.strttk.forEach(dataSTR => {
						detSTR = `
							<tr>
								<td class="text-center"><img loading="lazy" src='${dataSTR.dokumen}' width="40px"></td>
								<td>${dataSTR.nomor_strttk}</td>
								<td>${dataSTR.tanggal}</td>
								<td>${dataSTR.berlaku_sampai}</td>
							</tr>
						`

						$("#detailSTR").append(detSTR)
					});

				}


			}

			var loadingElement = document.getElementById("loading");
			if (loadingElement) {
				loadingElement.parentNode.removeChild(loadingElement);
			}


		});
	},


	async _updateExpiredKTAN() {
		let uuid = '';
		$("#tableDataAnggota tbody").on("click", "#editExpiredKTAN", async function () {
			$("#textNamaAnggota").val('')
			$("#textEmail").val('')
			$("#textKTAN").val('')
			$("#textMasaBerlakuKTAN").val('')
			var loadingElement = document.createElement("div");
			loadingElement.setAttribute("id", "loading");

			var spinnerElement = document.createElement("div");
			spinnerElement.setAttribute("class", "spinner");

			for (var i = 0; i < 3; i++) {
				var barElement = document.createElement("div");
				barElement.setAttribute("class", "bar");
				spinnerElement.appendChild(barElement);
			}

			loadingElement.appendChild(spinnerElement);
			document.body.appendChild(loadingElement);

			const table = $("#tableDataAnggota").DataTable();
			let data = table.row($(this).parents('tr')).data();
			uuid = data.uuid;
			$("#textNamaAnggota").val(data.name);
			$("#textEmail").val(data.email);
			$("#textKTAN").val(data.ktan);
			$("#textMasaBerlakuKTAN").val(data.ktan_expires_at);

			var loadingElement = document.getElementById("loading");
			if (loadingElement) {
				loadingElement.parentNode.removeChild(loadingElement);
			}
		});

		const eventUpdateExpiredKTAN = async (e) => {
			e.preventDefault();
			$("#btnUpdateExpiredKTAN").html(`<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>`);
			let masaBerlakuKTAN = $("#textMasaBerlakuKTAN").val()

			const updateExpiredKTAN = await ApiKepengurusanPC.updateExpiredKTAN({
				uuid: uuid,
				masaBerlakuKTAN: masaBerlakuKTAN
			});

			if (updateExpiredKTAN.status_code != 200) {
				NotificationModal.show(updateExpiredKTAN.message, 'error');
				$("#editExpiredKTANModal").modal('hide');
				$("#btnUpdateExpiredKTAN").html(`<i class='fas fa-save'></i> Update Data`);
				$("#tableDataAnggota").DataTable().ajax.reload()
			} else {
				NotificationModal.show(updateExpiredKTAN.message, 'success');
				$("#textNamaAnggota").val('')
				$("#textEmail").val('')
				$("#textKTAN").val('')
				$("#textMasaBerlakuKTAN").val('')
				$("#editExpiredKTANModal").modal('hide');
				$("#btnUpdateExpiredKTAN").html(`<i class='fas fa-save'></i> Update Data`);
				$("#tableDataAnggota").DataTable().ajax.reload()
			}

			e.preventDefault();
		}


		document.getElementById('btnUpdateExpiredKTAN').addEventListener('click', eventUpdateExpiredKTAN)


	},

	async _deleteAnggota() {
		let uuid = ''
		$('#tableDataAnggota tbody').on('click', '#delete', async function () {
			const table = $('#tableDataAnggota').DataTable();
			let data = table.row($(this).parents('tr')).data();
			uuid = data.uuid;
			$("#textHideUUID").val(uuid);
			// swal.fire({
			// 	title: 'Hapus',
			// 	html: "Anda ingin menghapus data ini? <br> <strong>Nama Anggota </strong>: " + data.name,
			// 	icon: 'warning',
			// 	showCancelButton: true,
			// 	confirmButtonColor: '#3085d6',
			// 	cancelButtonColor: '#d33',
			// 	confirmButtonText: 'Ya, Hapus!'
			// }).then(async (result) => {
			// 	if (result.value) {

			// 		var loadingElement = document.createElement("div");
			// 		loadingElement.setAttribute("id", "loading");

			// 		var spinnerElement = document.createElement("div");
			// 		spinnerElement.setAttribute("class", "spinner");

			// 		for (var i = 0; i < 3; i++) {
			// 			var barElement = document.createElement("div");
			// 			barElement.setAttribute("class", "bar");
			// 			spinnerElement.appendChild(barElement);
			// 		}

			// 		loadingElement.appendChild(spinnerElement);
			// 		document.body.appendChild(loadingElement);

			// 		let idAnggota = data.uuid;

			// 		const deleteAnggota = await ApiPengajuanKTANPC.deleteAnggota(idAnggota);

			// 		var loadingElement = document.getElementById("loading");
			// 		if (loadingElement) {
			// 			loadingElement.parentNode.removeChild(loadingElement);
			// 		}
			// 		if (deleteAnggota.status_code == '200') {
			// 			swal.fire({
			// 				title: 'Hapus',
			// 				text: deleteAnggota.message,
			// 				icon: 'success',
			// 				showConfirmButton: false,
			// 				timer: 1000
			// 			});
			// 			//refres manual 
			// 			$('#tableDataAnggota').DataTable().ajax.reload();
			// 		}else {
			// 			swal.fire({
			// 				title: 'Error',
			// 				text: deleteAnggota.message,
			// 				icon: 'error',
			// 				showConfirmButton: false,
			// 				timer: 1000
			// 			});
			// 		}
			// 	};
			// });


		});
		// $("#btnSendCommentDelete").on("click", async function (e) {

		// 	e.stopPropagation()
		// 	let uuid = $("#textHideUUID").val();
		// 	$(this).html(`<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>`);
		// 	let comment = $("#komentarPenghapusan").val();
		// 	if (comment === "" || null) {
		// 		NotificationModal.show(`Komentar tidak boleh kosong !`, 'error');
		// 		$(this).html("<i class='fas fa-comment'></i> Kirim");
		// 	}else {
		// 		const approveAnggota = await ApiPengajuanKTANPC.deleteAnggota({
		// 			uuid : uuid,
		// 			reason: comment
		// 		})
		// 		if (approveAnggota.status_code != 200) {
		// 			NotificationModal.show(`${approveAnggota.message}`, 'error');
		// 			$(this).html("<i class='fas fa-comment'></i> Hapus & Berikan Komentar");
		// 			$("#komentarPenghapusan").val("")
		// 			$('#commentModalDelete').modal('hide');
		// 		} else {
		// 			NotificationModal.show(`${approveAnggota.message}`, 'success');
		// 			$(this).html("<i class='fas fa-comment'></i> Hapus & Berikan Komentar");
		// 			$("#komentarPenghapusan").val("")
		// 			$('#commentModalDelete').modal('hide');
		// 			$('#tableDataAnggota').DataTable().ajax.reload();
		// 		}
		// 	}

		// });
	},

	async _checkAllEvent() {
		const eventSelectAll = async () => {
			let parentElement = document.getElementById('tablePengajuanKTAN')
			let tagElement = parentElement.getElementsByTagName('tbody')[0]
			const element = tagElement.querySelectorAll(".select-checkbox");
			const checked = document.getElementById('checkAll').checked;
			for (let index = 0; index < element.length; index++) {
				const table = $('#tablePengajuanKTAN').DataTable();
				let data = table.row(index).data()
				if (checked) {
					StorageAnggotaPC.pushData(data.id)
					await element[index].parentNode.classList.add('selected');
				} else {
					element[index].parentNode.classList.remove('selected');
					await StorageAnggotaPC.deleteData(data.id)
				}
			}

		}

		const eventSelectAllRegist = async () => {
			let parentElement = document.getElementById('tableRegistrasiAnggotaLama')
			let tagElement = parentElement.getElementsByTagName('tbody')[0]
			const element = tagElement.querySelectorAll(".select-checkbox");
			const checked = document.getElementById('checkAllRegis').checked;
			for (let index = 0; index < element.length; index++) {
				const table = $('#tableRegistrasiAnggotaLama').DataTable();
				let data = table.row(index).data()
				if (checked) {
					await StorageRegistAnggotaPC.pushData(data.uuid)
					await element[index].parentNode.classList.add('selected');
				} else {
					element[index].parentNode.classList.remove('selected');
					await StorageRegistAnggotaPC.deleteData(data.uuid)
				}
			}


		}

		document.getElementById('checkAll').addEventListener('change', eventSelectAll)
		document.getElementById('checkAllRegis').addEventListener('change', eventSelectAllRegist)

	},

	async _approvePengajuanKTAN() {

		let idPengajuan = 0;
		$('#tablePengajuanKTAN tbody').on('click', '#approve', async function () {

		


			let elmInThis = $(this)
			
			const table = $('#tablePengajuanKTAN').DataTable();
			let data = table.row($(this).parents('tr')).data();
			swal.fire({
				title: 'Approve Pengajuan',
				html: "Yakin menyetujui data ini ? " + data.user.name,
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Ya, Setujui'
			}).then(async (result) => {
				if (result.value) {
					elmInThis.html('<img loading="lazy" src="images/loading-gif.gif" width="18px">');
					
					idPengajuan = data.id;
		
					const approveAnggota = await ApiPengajuanKTANPC.aprroveKTAN({
						id: idPengajuan,
						status: "approve",
						comment: ""
					});
		
					if (approveAnggota.status_code != 200) {
						NotificationModal.show(`${approveAnggota.message}`, 'error');
						elmInThis.html("<i class='fas fa-check'></i>");
						$('#tablePengajuanKTAN').DataTable().ajax.reload();
					} else {
						NotificationModal.show(`${approveAnggota.message}`, 'success');
						elmInThis.html("<i class='fas fa-check'></i>");
						$('#tablePengajuanKTAN').DataTable().ajax.reload();
					}
					await StorageAnggotaPC.makeStore();
				}
			});
		});

		$('#tablePengajuanKTAN tbody').on('click', '#revisi', async function () {
			const table = $('#tablePengajuanKTAN').DataTable();
			let data = table.row($(this).parents('tr')).data();
			let UserID = data.user_id
			idPengajuan = data.id;

			// let getContentAdmin = (user) => {
			// let time =  new Date(user.updated_at).toLocaleDateString('en-US') + " " + new Date(user.updated_at).toLocaleTimeString('en-US') ;
			// return `
			// 		<div style="width:100%">
			// 			<div style="width:10%;" class="float-left">
			// 				<img loading="lazy" class="rounded-circle img-thumbnail img-square float-left" id="imgUser" src="${user.actor.avatar}" style="width:50px; height:50px;">
			// 			</div>
			// 			<div class="float-left text-dark text-left" style="width:88%;">
			// 				<div class="float-left" style="background:#dedede; margin:5px; padding:0 12px; padding-bottom:5px; border-radius:0px 10px" id="commentUser">
			// 					<small><b>${user.actor.name} [${user.actor.role}]</b></small><hr>
			// 					<small>${user.body}</small><br>
			// 					<div class="text-right">
			// 						<small style="margin-top:-2px; margin-left:50px"><strong>${time}</strong></small>
			// 					</div>
			// 				</div>
			// 			</div>
			// 		</div>
			// 		`
			// }

			// let getContentUser = (user) => {
			// 	let time =  new Date(user.updated_at).toLocaleDateString('en-US') + " " + new Date(user.updated_at).toLocaleTimeString('en-US') ;
			// 	return 	`
			// 			<div style="width:100%">
			// 				<div style="width:10%;" class="float-right">
			// 					<img loading="lazy" class="rounded-circle img-thumbnail img-square float-left" id="imgUser" src="${user.actor.avatar}" style="width:50px; height:50px;">
			// 				</div>
			// 				<div class="float-right text-dark text-left" style="width:88%;">
			// 					<div class="float-right" style="background:#e0e8fc; margin:5px; padding:0 12px; padding-bottom:5px; border-radius:10px 0px" id="commentUser">
			// 						<small><b>${user.actor.name} [${user.actor.role}]</b></small><hr>
			// 						<small>${user.body}</small><br>
			// 						<div class="text-right">
			// 							<small style="margin-top:-2px; margin-left:50px"><strong>${time}</strong></small>
			// 						</div>
			// 					</div>
			// 				</div>
			// 			</div>
			// 			`
			// }


			// let response = await ApiPengajuanKTANPC.getCommentar(UserID,"membership",idPengajuan)
			// let dataComment = response.data;
			// dataComment.reverse();

			// if (dataComment.length <= 0) {
			// 	$("#content-comment").html("<br><center><i>Tidak ada komentar</i></center>");
			// }else {
			// 	let getAllContent = async () => {
			// 	let elmHtml = ``
			// 		await dataComment.forEach(async element => {
			// 			elmHtml = elmHtml + (element.actor_type == "user" ?  getContentAdmin(element) :  getContentUser(element))
			// 		});
			// 		return elmHtml
			// 	}


			// 	if (response.data == undefined || response.data.length <= 0) {
			// document.getElementById("content-comment").innerHTML = `<span class="no-comment">Belum ada komentar</span>`

			// 	}else{
			// 		document.getElementById("content-comment").innerHTML = await getAllContent();

			// 		setTimeout(() => {
			// 			var elem = document.getElementById('content-comment');
			// 			elem.scrollTop = elem.scrollHeight;
			// 			}, 400);
			// 	}
			// }

		});



		$("#btnSendKomentarPengajuan").on("click", async function (e) {
			e.stopPropagation()
			$(this).html(`<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>`);
			let comment = $("#komentarPengajuan").val();
			if (comment === "" || null) {
				NotificationModal.show(`Komentar tidak boleh kosong !`, 'error');
				$(this).html("<i class='fas fa-comment'></i> Kirim");
			} else {
				const revisePengajuanKTAN = await ApiPengajuanKTANPC.aprroveKTAN({
					id: idPengajuan,
					status: "revise",
					comment: comment
				})
				if (revisePengajuanKTAN.status_code != 200) {
					NotificationModal.show(`${revisePengajuanKTAN.message}`, 'error');
					$(this).html("<i class='fas fa-comment'></i> Revisi & Berikan Komentar");
					$("#komentarPengajuan").val("")
					$('#commentModal').modal('hide');
				} else {
					NotificationModal.show(`${revisePengajuanKTAN.message}`, 'success');
					$(this).html("<i class='fas fa-comment'></i> Revisi & Berikan Komentar");
					$("#komentarPengajuan").val("")
					$('#commentModal').modal('hide');
					$('#tablePengajuanKTAN').DataTable().ajax.reload();
				}
			}

		});

		$('#commentModal').on('hidden.bs.modal', async function (e) {
			$("#komentarPengajuan").val("")
		});

	},


	async _initCommentKTAN() {
		let idPengajuan = '';
		let userID = '';
		$('#tablePengajuanKTAN tbody').on('click', '#comment', async function () {
			const table = $('#tablePengajuanKTAN').DataTable();
			let data = table.row($(this).parents('tr')).data();
			userID = data.user_id
			idPengajuan = data.id;

			let getContentAdmin = (user) => {
				let time = new Date(user.updated_at).toLocaleDateString('en-US') + " " + new Date(user.updated_at).toLocaleTimeString('en-US');
				return `
					<div style="width:100%">
						<div style="width:10%;" class="float-left">
							<img loading="lazy" class="rounded-circle img-thumbnail img-square float-left" id="imgUser" src="${user.actor.avatar}" style="width:50px; height:50px;">
						</div>
						<div class="float-left text-dark text-left" style="width:88%;">
							<div class="float-left" style="background:#dedede; margin:5px; padding:0 12px; padding-bottom:5px; border-radius:0px 10px" id="commentUser">
								<small><b>${user.actor.name} [${user.actor.role}]</b></small><hr>
								<small>${user.body}</small><br>
								<div class="text-right">
									<small style="margin-top:-2px; margin-left:50px"><strong>${time}</strong></small>
								</div>
							</div>
						</div>
					</div>
					`
			}

			let getContentUser = (user) => {
				let time = new Date(user.updated_at).toLocaleDateString('en-US') + " " + new Date(user.updated_at).toLocaleTimeString('en-US');
				return `
						<div style="width:100%">
							<div style="width:10%;" class="float-right">
								<img loading="lazy" class="rounded-circle img-thumbnail img-square float-left" id="imgUser" src="${user.actor.avatar}" style="width:50px; height:50px;">
							</div>
							<div class="float-right text-dark text-left" style="width:88%;">
								<div class="float-right" style="background:#e0e8fc; margin:5px; padding:0 12px; padding-bottom:5px; border-radius:10px 0px" id="commentUser">
									<small><b>${user.actor.name} [${user.actor.role}]</b></small><hr>
									<small>${user.body}</small><br>
									<div class="text-right">
										<small style="margin-top:-2px; margin-left:50px"><strong>${time}</strong></small>
									</div>
								</div>
							</div>
						</div>
						`
			}


			let response = await ApiPengajuanKTANPC.getCommentar(userID, "membership", idPengajuan)
			let dataComment = response.data;
			dataComment.reverse();

			if (dataComment.length <= 0) {
				$("#content-comment").html("<br><center><i>Tidak ada komentar</i></center>");
			} else {
				let getAllContent = async () => {
					let elmHtml = ``
					await dataComment.forEach(async element => {
						elmHtml = elmHtml + (element.actor_type == "user" ? getContentAdmin(element) : getContentUser(element))
					});
					return elmHtml
				}


				if (response.data == undefined || response.data.length <= 0) {
					document.getElementById("content-comment").innerHTML = `<span class="no-comment">Belum ada komentar</span>`
				} else {
					document.getElementById("content-comment").innerHTML = await getAllContent();

					setTimeout(() => {
						var elem = document.getElementById('content-comment');
						elem.scrollTop = elem.scrollHeight;
					}, 400);
				}
			}

		});

		const eventSendCommentar = async (e) => {
			e.preventDefault();
			document.getElementById("btnSendKomentarPengajuanKTAN").innerHTML = `<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>`
			let content = document.getElementById("komentarPengajuanKTANUser").value
			const response = await ApiPengajuanKTANPC.sendCommentar({
				userID: userID,
				pengajuanID: idPengajuan,
				commentFor: 'membership',
				contentComment: content,
			});
			if (response.status_code == 200 || 201) {


				let getContentAdmin = (user) => {
					let time = new Date(user.updated_at).toLocaleDateString('en-US') + " " + new Date(user.updated_at).toLocaleTimeString('en-US');
					return `
						<div style="width:100%">
							<div style="width:10%;" class="float-left">
								<img loading="lazy" class="rounded-circle img-thumbnail img-square float-left" id="imgUser" src="${user.actor.avatar}" style="width:50px; height:50px;">
							</div>
							<div class="float-left text-dark text-left" style="width:88%;">
								<div class="float-left" style="background:#dedede; margin:5px; padding:0 12px; padding-bottom:5px; border-radius:0px 10px" id="commentUser">
									<small><b>${user.actor.name} [${user.actor.role}]</b></small><hr>
									<small>${user.body}</small><br>
									<div class="text-right">
										<small style="margin-top:-2px; margin-left:50px"><strong>${time}</strong></small>
									</div>
								</div>
							</div>
						</div>
						`
				}

				let getContentUser = (user) => {
					let time = new Date(user.updated_at).toLocaleDateString('en-US') + " " + new Date(user.updated_at).toLocaleTimeString('en-US');
					return `
							<div style="width:100%">
								<div style="width:10%;" class="float-right">
									<img loading="lazy" class="rounded-circle img-thumbnail img-square float-left" id="imgUser" src="${user.actor.avatar}" style="width:50px; height:50px;">
								</div>
								<div class="float-right text-dark text-left" style="width:88%;">
									<div class="float-right" style="background:#e0e8fc; margin:5px; padding:0 12px; padding-bottom:5px; border-radius:10px 0px" id="commentUser">
										<small><b>${user.actor.name} [${user.actor.role}]</b></small><hr>
										<small>${user.body}</small><br>
										<div class="text-right">
											<small style="margin-top:-2px; margin-left:50px"><strong>${time}</strong></small>
										</div>
									</div>
								</div>
							</div>
							`
				}


				let response = await ApiPengajuanKTANPC.getCommentar(userID, "membership", idPengajuan)
				let dataComment = response.data;
				dataComment.reverse();

				if (dataComment.length <= 0) {
					$("#content-comment").html("<br><center><i>Tidak ada komentar</i></center>");
				} else {
					let getAllContent = async () => {
						let elmHtml = ``
						await dataComment.forEach(async element => {
							elmHtml = elmHtml + (element.actor_type == "user" ? getContentAdmin(element) : getContentUser(element))
						});
						return elmHtml
					}


					if (response.data == undefined || response.data.length <= 0) {
						document.getElementById("content-comment").innerHTML = `<span class="no-comment">Belum ada komentar</span>`
					} else {
						document.getElementById("content-comment").innerHTML = await getAllContent();

						setTimeout(() => {
							var elem = document.getElementById('content-comment');
							elem.scrollTop = elem.scrollHeight;
						}, 400);
					}
				}

				$('#komentarPengajuanKTANUser').val('');
				document.getElementById("btnSendKomentarPengajuanKTAN").innerHTML = `<i class="fas fa-comment"></i> Berikan Komentar`;
			} else {
				document.getElementById("btnSendKomentarPengajuanKTAN").innerHTML = `<i class="fas fa-comment"></i> Berikan Komentar`;
			}
			e.preventDefault();
		}
		document.getElementById("btnSendKomentarPengajuanKTAN").addEventListener("click", eventSendCommentar)
	},

	async _approveRegistrasiOld() {

		let idPengajuan = 0;
		let approval = '';
		$('#tableRegistrasiAnggotaLama tbody').on('click', '#approve', async function () {
			$(this).html('<img loading="lazy" src="images/loading-gif.gif" width="18px">');
			const table = $('#tableRegistrasiAnggotaLama').DataTable();
			let data = table.row($(this).parents('tr')).data();
			idPengajuan = data.uuid;
			approval = 'approve';
			swal.fire({
				title: 'Setujui Registrasi Anggota',
				html: "Yakin menyetujui anggota lama ini? <br> <strong>Nama Anggota </strong>: " + data.name,
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Ya, Setujui'
			}).then(async (result) => {
				if (result.value) {

					const approveAnggota = await ApiPengajuanKTANPC.approveRegisOld({
						id: idPengajuan,
						approval: approval
					});
					if (approveAnggota.status_code == 200) {
						swal.fire({
							title: 'Success',
							text: 'Data Berhasil di Approve',
							icon: 'success',
							showConfirmButton: false,
							timer: 1000
						});

						$(this).html("<i class='fas fa-check'></i>");
						$('#tableRegistrasiAnggotaLama').DataTable().ajax.reload();

					} else {

						swal.fire({
							title: 'Error',
							text: `${approveAnggota.message}`,
							icon: 'error',
							showConfirmButton: false,
							timer: 1000
						});
						$(this).html("<i class='fas fa-check'></i>");
						$('#tableRegistrasiAnggotaLama').DataTable().ajax.reload();
					}
				} else if (result.dismiss == 'cancel') {
					$(this).html("<i class='fas fa-check'></i>");
				};
			});

			// if (approveAnggota.status_code != 200) {
			// 	NotificationModal.show(`${approveAnggota.message}`, 'error');
			// } else {
			// 	NotificationModal.show(`${approveAnggota.message}`, 'success');
			// }

			await StorageRegistAnggotaPC.makeStore();
		});

		// $('#tableRegistrasiAnggotaLama tbody').on('click', '#rejectedRegist', async function () {
		// 	$(this).html('<img loading="lazy" src="images/loading-gif.gif" width="18px">');
		// 	const table = $('#tableRegistrasiAnggotaLama').DataTable();
		// 	let data = table.row($(this).parents('tr')).data();
		// 	idPengajuan = data.uuid;
		// 	approval = 'reject';
		// 	swal.fire({
		// 		title: 'Tolak Registrasi Anggota',
		// 		html: "Yakin menolak anggota lama ini? <br> <strong>Nama Anggota </strong>: " + data.name,
		// 		icon: 'warning',
		// 		showCancelButton: true,
		// 		confirmButtonColor: '#3085d6',
		// 		cancelButtonColor: '#d33',
		// 		confirmButtonText: 'Ya, Tolak'
		// 	}).then(async (result) => {
		// 		if (result.value) {

		// 			const approveAnggota = await ApiPengajuanKTANPC.approveRegisOld({
		// 				id: idPengajuan,
		// 				approval: approval
		// 			});
		// 			if (approveAnggota.status_code == 200) {
		// 				swal.fire({
		// 					title: 'Success',
		// 					text: 'Data Berhasil di Reject',
		// 					icon: 'success',
		// 					showConfirmButton: false,
		// 					timer: 1000
		// 				});

		// 				$(this).html("<i class='fas fa-times'></i>");
		// 				$('#tableRegistrasiAnggotaLama').DataTable().ajax.reload();

		// 			}else{

		// 				swal.fire({
		// 					title: 'Error',
		// 					text: `${approveAnggota.message}`,
		// 					icon: 'error',
		// 					showConfirmButton: false,
		// 					timer: 1000
		// 				});
		// 				$(this).html("<i class='fas fa-times'></i>");
		// 				$('#tableRegistrasiAnggotaLama').DataTable().ajax.reload();
		// 			}
		// 		}else if(result.dismiss == 'cancel'){
		// 				$(this).html("<i class='fas fa-times'></i>");
		// 		 };
		// 	});

		// 	// if (approveAnggota.status_code != 200) {
		// 	// 	NotificationModal.show(`${approveAnggota.message}`, 'error');
		// 	// } else {
		// 	// 	NotificationModal.show(`${approveAnggota.message}`, 'success');
		// 	// }

		// 	await StorageRegistAnggotaPC.makeStore();
		// });
		$('#tableRegistrasiAnggotaLama tbody').on('click', '#rejectedRegist', async function () {
			const table = $('#tableRegistrasiAnggotaLama').DataTable();
			let data = table.row($(this).parents('tr')).data();
			idPengajuan = data.uuid;
			approval = 'reject';
		});

		$("#btnSendKomentarRegistrasi").on("click", async function (e) {
			e.stopPropagation()
			$(this).html(`<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>`);
			let comment = $("#komentarRegistrasi").val();
			if (comment === "" || null) {
				NotificationModal.show(`Komentar tidak boleh kosong !`, 'error');
				$(this).html("<i class='fas fa-comment'></i> Kirim");
			} else {
				const approveAnggota = await ApiPengajuanKTANPC.approveRegisOld({
					id: idPengajuan,
					approval: approval,
					reason: comment
				})
				if (approveAnggota.status_code != 200) {
					NotificationModal.show(`${approveAnggota.message}`, 'error');
					$(this).html("<i class='fas fa-comment'></i> Revisi & Berikan Komentar");
					$("#komentarRegistrasi").val("")
					$('#commentModalRegist').modal('hide');
				} else {
					NotificationModal.show(`${approveAnggota.message}`, 'success');
					$(this).html("<i class='fas fa-comment'></i> Revisi & Berikan Komentar");
					$("#komentarRegistrasi").val("")
					$('#commentModalRegist').modal('hide');
					$('#tableRegistrasiAnggotaLama').DataTable().ajax.reload();
				}
			}

		});



	},

	async _initAllApprove() {
		const eventAllApprove = async () => {

			if (await StorageAnggotaPC.size() <= 0) {
				NotificationModal.show("Silahkan Pilih Data Terlebih Dahulu", "warning")
				$("#btnVerifikasiAllDt").html("<i class='fas fa-check'></i> Approve All")
			} else {

				let data = await StorageAnggotaPC.getData()
				swal.fire({
					title: 'Setujui Semua',
					html: "Yakin menyetujui semua pengajuan KTAN? <br> <strong>Jumlah Data </strong>: " + data.length,
					icon: 'warning',
					showCancelButton: true,
					confirmButtonColor: '#3085d6',
					cancelButtonColor: '#d33',
					confirmButtonText: 'Ya, Setujui'
				}).then(async (result) => {
					if (result.value) {

						const response = await ApiPengajuanKTANPC.approveAllKTAN(data)
						await StorageAnggotaPC.makeStore();
						if (response.status_code == 200) {
							swal.fire({
								title: 'Success',
								text: 'Data Berhasil di Approve',
								icon: 'success',
								showConfirmButton: false,
								timer: 1000
							});
							$("#btnVerifikasiAllDt").html("<i class='fas fa-check'></i> Approve All")
							$('#tablePengajuanKTAN').DataTable().ajax.reload();

						} else {

							swal.fire({
								title: 'Error',
								text: `${response.message}`,
								icon: 'error',
								showConfirmButton: false,
								timer: 1000
							});
							$("#btnVerifikasiAllDt").html("<i class='fas fa-check'></i> Approve All")
							$('#tablePengajuanKTAN').DataTable().ajax.reload();
						}
					} else if (result.dismiss == 'cancel') {
						$("#btnVerifikasiAllDt").html("<i class='fas fa-check'></i> Approve All")
					};
				});
			}
		}

		document.getElementById('btnVerifikasiAll').addEventListener('click', eventAllApprove)


		const eventAllApproveAnggotaLama = async () => {

			if (await StorageRegistAnggotaPC.size() <= 0) {
				NotificationModal.show("Silahkan Pilih Data Terlebih Dahulu", "warning")
				$("#btnVerifikasiAllDtLama").html("<i class='fas fa-check'></i> Approve All")
			} else {

				let data = await StorageRegistAnggotaPC.getData()
				swal.fire({
					title: 'Setujui Semua',
					html: "Yakin menyetujui semua pengajuan Registrasi Anggota Lama? <br> <strong>Jumlah Data </strong>: " + data.length,
					icon: 'warning',
					showCancelButton: true,
					confirmButtonColor: '#3085d6',
					cancelButtonColor: '#d33',
					confirmButtonText: 'Ya, Setujui'
				}).then(async (result) => {
					if (result.value) {

						const response = await ApiPengajuanKTANPC.approveAllRegis(data)
						await StorageRegistAnggotaPC.makeStore();
						if (response.status_code == 200) {
							swal.fire({
								title: 'Success',
								text: 'Data Berhasil di Approve',
								icon: 'success',
								showConfirmButton: false,
								timer: 1000
							});
							$("#btnVerifikasiAllDtLama").html("<i class='fas fa-check'></i> Approve All")
							$('#tableRegistrasiAnggotaLama').DataTable().ajax.reload();

						} else {

							swal.fire({
								title: 'Error',
								text: `${response.message}`,
								icon: 'error',
								showConfirmButton: false,
								timer: 1000
							});
							$("#btnVerifikasiAllDtLama").html("<i class='fas fa-check'></i> Approve All")
							$('#tableRegistrasiAnggotaLama').DataTable().ajax.reload();
						}
					} else if (result.dismiss == 'cancel') {
						$("#btnVerifikasiAllDtLama").html("<i class='fas fa-check'></i> Approve All")
					};
				});
			}
		}


		document.getElementById('btnVerifikasiAllAnggotaLama').addEventListener('click', eventAllApproveAnggotaLama)
	},

	async _createAnggotaManual() {

		$("#textNamaAnggotaManual").val('');
		$("#emailAnggota").val('');
		$("#nikAnggota").val('');
		$("#textKTANAnggotaManual").val('');
		$("#masaAktifKTAN").val('');
		$("#textIuranTerakhirDariBulan").val('');
		$("#textIuranTerakhirSampaiBulan").val('');
		const eventCreateAnggotaManual = async (e) => {
			e.preventDefault();
			let valueKTAN = document.getElementById('textKTANAnggotaManual').value
			if (valueKTAN.length < 22) {
				NotificationModal.show(`NO KTAN ini : ${valueKTAN} Tidak valid , silahkan isi kembali.`, 'warning');
				return
			}

			document.getElementById("btnSaveAnggotaManual").innerHTML = `<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>`
			const createAnggotaManual = await ApiPengajuanKTANPC.createAnggotaManual({
				name: document.getElementById('textNamaAnggotaManual').value,
				email: document.getElementById('emailAnggota').value,
				nik: document.getElementById('nikAnggota').value,
				ktan: document.getElementById('textKTANAnggotaManual').value,
				masa_berlaku_ktan: document.getElementById('masaAktifKTAN').value,
				bulan_iuran_awal: document.getElementById('textIuranTerakhirSampaiBulan').value,
				bulan_iuran_akhir: document.getElementById('textIuranTerakhirSampaiBulan').value,
			});
			if (createAnggotaManual.status_code != 200) {
				NotificationModal.show(`${createAnggotaManual.message}`, 'error');
				document.getElementById("btnSaveAnggotaManual").innerHTML = `Save Changes`;

			} else {
				NotificationModal.show('Anggota baru berhasil ditambahkan !', 'success');
				document.getElementById('form-tambah-anggota-manual').reset()
				document.getElementById("btnSaveAnggotaManual").innerHTML = `Save Changes`;
				$('#tableDataAnggota').DataTable().ajax.reload();
			}
			e.preventDefault();

			$("#textNamaAnggota").val('');
			$("#emailAnggota").val('');
			$("#nikAnggota").val('');
			$("#textKTANAnggotaManual").val('');
			$("#masaAktifKTAN").val('');


		}
		document.getElementById('btnSaveAnggotaManual').addEventListener('click', eventCreateAnggotaManual);

		$('#modalTambahAnggotaManual').on('hidden.bs.modal', async function (e) {
			$('#btnSaveAnggotaManual').off('click');
		});
	},

}



const StorageAnggotaPC = {

	async makeStore() {
		this._data = [];
	},

	isReady(kode) {
		const result = this._data.map((e) => { return e.id_pengajuan; }).indexOf(kode);
		let value = false
		if (result != -1) {
			value = true
		}
		return value;
	},

	async pushData(idPengajuan) {
		if (this.isReady(idPengajuan)) return
		let gold = {
			"id_pengajuan": idPengajuan,
		}
		this._data.push(gold);
	},

	async deleteData(kode) {
		let removeIndex = this._data.map((item) => { return item.id_pengajuan; }).indexOf(`${kode}`);
		this._data.splice(removeIndex, 1)
	},

	async size() {
		return this._data.length
	},

	async getData() {
		return this._data;
	}
}

const StorageRegistAnggotaPC = {

	async makeStore() {
		this._data = [];
	},

	isReady(kode) {
		const result = this._data.map((e) => { return e.id_pengajuan; }).indexOf(kode);
		let value = false
		if (result != -1) {
			value = true
		}
		return value;
	},

	async pushData(idPengajuan) {
		if (this.isReady(idPengajuan)) return
		let gold = {
			"user_uuid": idPengajuan,
		}
		this._data.push(gold);
	},

	async deleteData(kode) {
		let removeIndex = this._data.map((item) => { return item.id_pengajuan; }).indexOf(`${kode}`);
		this._data.splice(removeIndex, 1)
	},

	async size() {
		return this._data.length
	},

	async getData() {
		return this._data;
	}
}

export default AnggotaPCPresenter;