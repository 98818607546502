
import API_ENDPOINT_PENGURUS_PD from "../../config/globals/menu_pengurus_pd_endpoint.js";
import NotificationModal from "../../utils/initial_notification.js";
import CONFIG from "../../config/globals/config.js";
import ApiPengajuanKTANPD from '../../api/api_pengurus_pd/pengajuan-ktan.js';
import ApiAnggota from "../../api/data-anggota.js";
import ApiRegister from "../../api/data-register.js";
import ApiPengajuanKTANPC from '../../api/api_pengurus_pc/pengajuan-ktan.js';
import ApiKepengurusanPP from "../../api/api_pengurus_pp/kepengurusan-pp.js";

const AnggotaPDPresenter = {

	async init() {
		await StorageAnggotaPD.makeStore()
		await this._setView();
		await this._getDataPrivate();
		await this._getPengajuanKTAN();
		await this._checkAllEvent();
		await this._approvePengajuanKTAN();
		await this._initAllApprove()
		await this._getDataAnggota();
		await this._initCommentKTAN();
		await this._getDetailAnggota();
		await this._downloadKTAN()
		await this._downloadReportByPendidikan()
	},

	async _setView() {
		document.getElementById('main-content').classList.remove('bg-gradient-primary');
		document.getElementById('main-content').style.removeProperty("height");
		document.getElementById('main-content').style.removeProperty("padding-top");
		document.getElementById('content-wrapper').style.removeProperty("overflow-x");
		document.getElementById('appbar').classList.remove('d-none');
		document.getElementById('accordionSidebar').classList.remove('d-none');
		document.getElementById("main-content").style.height = "";
		// let responseLocked = await ApiAnggota.checkIsPcPdLocked();
		// if (responseLocked.status_code == 200) {
		// 	if (responseLocked.data.is_pd_locked) {
		// 		window.location.hash = '#/404?reason=iuranpd'
		// 	}
		// }
	},

	async _getDataPrivate() {

		const dataPrivate = await ApiAnggota.getPrivate();
		const dataAddressPrivate = dataPrivate.data;
		const member_pd = dataAddressPrivate.user_pd_aktif_id;

		const dataRegistry = await ApiRegister.getRegistry(member_pd)

		var selectPC = document.getElementById("selectPengajuanPC");
		var selectPCAnggota = document.getElementById("selectAnggotaPC");
		var lengthOptionPc = selectPC.options.length;
		var lengthOptionPcAnggota = selectPCAnggota.options.length;
		for (let i = lengthOptionPc - 1; i >= 0; i--) {
			selectPC.options[i] = null;
		}

		for (let i = lengthOptionPcAnggota - 1; i >= 0; i--) {
			selectPCAnggota.options[i] = null;
		}

		document.getElementById("selectPengajuanPC").add((new Option("Pilih Cabang", "")))
		dataRegistry.data.forEach(data => {
			document.getElementById("selectPengajuanPC").add(new Option(data.regency_name, data.id))
		});

		document.getElementById("selectAnggotaPC").add((new Option("Pilih Cabang", "")))
		dataRegistry.data.forEach(data => {
			document.getElementById("selectAnggotaPC").add(new Option(data.regency_name, data.id))
		});


	},


	async _getDetailAnggota() {
		$("#tableDataAnggota tbody").on("click", "#detail", async function () {
			$("#detailPendidikan").html('')
			$("#detailPekerjaan").html('')
			$("#detailSerkom").html('')
			$("#detailSIP").html('')
			$("#detailSTR").html('')
			$("#detailAvatar").attr('src', 'images/logo.png')
			var loadingElement = document.createElement("div");
			loadingElement.setAttribute("id", "loading");

			var spinnerElement = document.createElement("div");
			spinnerElement.setAttribute("class", "spinner");

			for (var i = 0; i < 3; i++) {
				var barElement = document.createElement("div");
				barElement.setAttribute("class", "bar");
				spinnerElement.appendChild(barElement);
			}

			loadingElement.appendChild(spinnerElement);
			document.body.appendChild(loadingElement);

			const table = $("#tableDataAnggota").DataTable();
			let data = table.row($(this).parents('tr')).data();
			let uuidAnggota = data.uuid;
			const getAnggotaById = await ApiPengajuanKTANPC.getAnggotaByID(uuidAnggota);

			let dataByID = getAnggotaById.data;
			if (getAnggotaById.status_code != 200) {
				NotificationModal.show(`${updateSetting.message}`, 'Gagal memuat detail anggota !');
			} else {
				if (dataByID.avatar == '' || dataByID.avatar == null) {
					$("#detailAvatar").attr('src', 'images/avatar_not_found.png');
				} else {
					$("#detailAvatar").attr('src', dataByID.avatar);
				}
				$("#detailNamaAnggota").html(dataByID.name);
				$("#detailKTAN").html(dataByID.ktan);
				$("#detailNIK").html(dataByID.nik);
				$("#detailEmail").html(dataByID.email);
				$("#detailEmailHead").html(dataByID.email);
				$("#detailTempatLahir").html(dataByID.tempat_lahir);
				$("#detailTanggalLahir").html(dataByID.tanggal_lahir);
				$("#detailJenisKelamin").html(dataByID.jenis_kelamin);
				$("#detailNoHP").html(dataByID.phone_number);
				if (dataByID.alamat == null) {
					$("#detailAlamat").html("Alamat belum di input oleh anggota");
					$("#detailProvinsi").html("Alamat belum di input oleh anggota");
					$("#detailKota").html("Alamat belum di input oleh anggota");
					$("#detailKecamatan").html("Alamat belum di input oleh anggota");
					$("#detailKelurahan").html("Alamat belum di input oleh anggota");
				} else {
					$("#detailAlamat").html(dataByID.alamat.address);
					$("#detailProvinsi").html(dataByID.alamat.province_name);
					$("#detailKota").html(dataByID.alamat.regency_name);
					$("#detailKecamatan").html(dataByID.alamat.sub_regency_name);
					$("#detailKelurahan").html(dataByID.alamat.village_name);
				}
				if (dataByID.dokumen.bukti_bayar_ktan == null || dataByID.dokumen.bukti_bayar_ktan == '') {
					$("#detailBuktiBayar").html(`
						Tidak ada Bukti Bayar !
					`)
				} else {
					$("#detailBuktiBayar").html(`
						<img loading="lazy" src="${dataByID.dokumen.bukti_bayar_ktan}" width="50%">
					`)
				}


				$("#detailPendidikan").html(`
				
					<tr>
						<th class="text-center">Ijazah</th>
						<th>Jenjang</th>
						<th>Nama Perguruan Tinggi</th>
						<th>Tahun Lulus</th>
						<th>Nomor Ijazah</th>
					</tr>
				`)

				$("#detailPekerjaan").html(`
					<tr>
						<th class="text-center">Dokumen</th>
						<th>Nama Tempat Kerja</th>
						<th>Jenis</th>
						<th>Jabatan</th>
						<th>Dari Tahun</th>
						<th>Sampai Tahun</th>
						<th>No HP/ Telp</th>
						<th>Alamat</th>
					</tr>
				`)


				$("#detailSerkom").html(`
					<tr>
						<th class="text-center">Serkom</th>
						<th>Nomor</th>
						<th>Tanggal</th>
						<th>Berlaku Sampai</th>
					</tr>
				`)


				$("#detailSIP").html(`
					<tr>
						<th class="text-center">SIPTTK</th>
						<th>Jenis</th>
						<th>Nomor</th>
						<th>Tanggal</th>
						<th>Berlaku Sampai</th>
						<th>Supervisor</th>
						<th>STRA</th>
						<th>No STRTTK</th>
					</tr>
				`)


				$("#detailSTR").html(`
					<tr>
						<th class="text-center">STRTTK</th>
						<th>Nomor</th>
						<th>Tanggal</th>
						<th>Berlaku Sampai</th>
					</tr>
				`)


				var detPendidikan = ``;
				var detPekerjaan = ``;
				var detSerkom = ``;
				var detSIP = ``;
				var detSTR = ``;

				if (dataByID.pendidikan == null || dataByID.pendidikan == '') {
					$("#detailPendidikan").html(`
					<tr>
						<td>Tidak ada data Pendidikan !</td>
					</tr>
					`)
				} else {
					dataByID.pendidikan.forEach(dataPendidikan => {
						detPendidikan = `
							<tr>
								<td class="text-center"><img loading="lazy" src='${dataPendidikan.dokumen}' width="40px"></td>
								<td>${dataPendidikan.jenjang}</td>
								<td>${dataPendidikan.nama_institusi_pendidikan}</td>
								<td>${dataPendidikan.tahun_lulus}</td>
								<td>${dataPendidikan.nomor_ijazah}</td>
							</tr>
						`

						$("#detailPendidikan").append(detPendidikan)
					});

				}

				if (dataByID.pekerjaan == null || dataByID.pekerjaan == '') {
					$("#detailPekerjaan").html(`
					<tr>
						<td>Tidak ada data Pekerjaan !</td>
					</tr>
					`)
				} else {
					dataByID.pekerjaan.forEach(dataPekerjaan => {
						detPekerjaan = `
							<tr>
								<td class="text-center"><img loading="lazy" src='${dataPekerjaan.dokumen}' width="40px"></td>
								<td>${dataPekerjaan.nama_tempat_kerja}</td>
								<td>${dataPekerjaan.jenis_tempat_kerja}</td>
								<td>${dataPekerjaan.jabatan}</td>
								<td>${dataPekerjaan.dari_tahun}</td>
								<td>${dataPekerjaan.sampai_tahun}</td>
								<td>${dataPekerjaan.phone_number}</td>
								<td>${dataPekerjaan.alamat_tempat_kerja}</td>
							</tr>
						`

						$("#detailPekerjaan").append(detPekerjaan)
					});

				}

				if (dataByID.serkom == null || dataByID.serkom == '') {
					$("#detailSerkom").html(`
					<tr>
						<td>Tidak ada data SERKOM !</td>
					</tr>
					`)
				} else {
					dataByID.serkom.forEach(dataSerkom => {
						detSerkom = `
							<tr>
								<td class="text-center"><img loading="lazy" src='${dataSerkom.dokumen}' width="40px"></td>
								<td>${dataSerkom.nomor_serkom}</td>
								<td>${dataSerkom.tanggal}</td>
								<td>${dataSerkom.berlaku_sampai}</td>
							</tr>
						`

						$("#detailSerkom").append(detSerkom)
					});

				}


				if (dataByID.sipttk == null || dataByID.sipttk == '') {
					$("#detailSIP").html(`
					<tr>
						<td>Tidak ada data SIPTTK !</td>
					</tr>
					`)
				} else {
					dataByID.sipttk.forEach(dataSIP => {
						detSIP = `
							<tr>
								<td class="text-center"><img loading="lazy" src='${dataSIP.dokumen}' width="40px"></td>
								<td>${dataSIP.jenis_sipttk}</td>
								<td>${dataSIP.nomor_sipttk}</td>
								<td>${dataSIP.tanggal}</td>
								<td>${dataSIP.berlaku_sampai}</td>
								<td>${dataSIP.nama_apoteker_supervisi}</td>
								<td>${dataSIP.nomor_stra_apoteker}</td>
								<td>${dataSIP.nomor_strttk}</td>
							</tr>
						`

						$("#detailSIP").append(detSIP)
					});

				}


				if (dataByID.strttk == null || dataByID.strttk == '') {
					$("#detailSTR").html(`
					<tr>
						<td>Tidak ada data STRTTK !</td>
					</tr>
					`)
				} else {
					dataByID.strttk.forEach(dataSTR => {
						detSTR = `
							<tr>
								<td class="text-center"><img loading="lazy" src='${dataSTR.dokumen}' width="40px"></td>
								<td>${dataSTR.nomor_strttk}</td>
								<td>${dataSTR.tanggal}</td>
								<td>${dataSTR.berlaku_sampai}</td>
							</tr>
						`

						$("#detailSTR").append(detSTR)
					});

				}


			}

			var loadingElement = document.getElementById("loading");
			if (loadingElement) {
				loadingElement.parentNode.removeChild(loadingElement);
			}


		});


		$("#tablePengajuanKTAN tbody").on("click", "#detail", async function () {
			$("#detailPendidikan").html('')
			$("#detailPekerjaan").html('')
			$("#detailSerkom").html('')
			$("#detailSIP").html('')
			$("#detailSTR").html('')
			$("#detailAvatar").attr('src', 'images/logo.png')
			var loadingElement = document.createElement("div");
			loadingElement.setAttribute("id", "loading");

			var spinnerElement = document.createElement("div");
			spinnerElement.setAttribute("class", "spinner");

			for (var i = 0; i < 3; i++) {
				var barElement = document.createElement("div");
				barElement.setAttribute("class", "bar");
				spinnerElement.appendChild(barElement);
			}

			loadingElement.appendChild(spinnerElement);
			document.body.appendChild(loadingElement);

			const table = $("#tablePengajuanKTAN").DataTable();
			let data = table.row($(this).parents('tr')).data();
			console.log(data);
			let uuidAnggota = data.user.uuid;
			const getAnggotaById = await ApiPengajuanKTANPC.getAnggotaByID(uuidAnggota);

			let dataByID = getAnggotaById.data;
			if (getAnggotaById.status_code != 200) {
				NotificationModal.show(`${updateSetting.message}`, 'Gagal memuat detail anggota !');
			} else {
				if (dataByID.avatar == '' || dataByID.avatar == null) {
					$("#detailAvatar").attr('src', 'images/avatar_not_found.png');
				} else {
					$("#detailAvatar").attr('src', dataByID.avatar);
				}
				$("#detailNamaAnggota").html(dataByID.name);
				$("#detailKTAN").html(dataByID.ktan);
				$("#detailNIK").html(dataByID.nik);
				$("#detailEmail").html(dataByID.email);
				$("#detailEmailHead").html(dataByID.email);
				$("#detailTempatLahir").html(dataByID.tempat_lahir);
				$("#detailTanggalLahir").html(dataByID.tanggal_lahir);
				$("#detailJenisKelamin").html(dataByID.jenis_kelamin);
				$("#detailNoHP").html(dataByID.phone_number);
				if (dataByID.alamat == null) {
					$("#detailAlamat").html("Alamat belum di input oleh anggota");
					$("#detailProvinsi").html("Alamat belum di input oleh anggota");
					$("#detailKota").html("Alamat belum di input oleh anggota");
					$("#detailKecamatan").html("Alamat belum di input oleh anggota");
					$("#detailKelurahan").html("Alamat belum di input oleh anggota");
				} else {
					$("#detailAlamat").html(dataByID.alamat.address);
					$("#detailProvinsi").html(dataByID.alamat.province_name);
					$("#detailKota").html(dataByID.alamat.regency_name);
					$("#detailKecamatan").html(dataByID.alamat.sub_regency_name);
					$("#detailKelurahan").html(dataByID.alamat.village_name);
				}
				if (dataByID.dokumen.bukti_bayar_ktan == null || dataByID.dokumen.bukti_bayar_ktan == '') {
					$("#detailBuktiBayar").html(`
						Tidak ada Bukti Bayar !
					`)
				} else {
					$("#detailBuktiBayar").html(`
						<img loading="lazy" src="${dataByID.dokumen.bukti_bayar_ktan}" width="50%">
					`)
				}


				$("#detailPendidikan").html(`
				
					<tr>
						<th class="text-center">Ijazah</th>
						<th>Jenjang</th>
						<th>Nama Perguruan Tinggi</th>
						<th>Tahun Lulus</th>
						<th>Nomor Ijazah</th>
					</tr>
				`)

				$("#detailPekerjaan").html(`
					<tr>
						<th class="text-center">Dokumen</th>
						<th>Nama Tempat Kerja</th>
						<th>Jenis</th>
						<th>Jabatan</th>
						<th>Dari Tahun</th>
						<th>Sampai Tahun</th>
						<th>No HP/ Telp</th>
						<th>Alamat</th>
					</tr>
				`)


				$("#detailSerkom").html(`
					<tr>
						<th class="text-center">Serkom</th>
						<th>Nomor</th>
						<th>Tanggal</th>
						<th>Berlaku Sampai</th>
					</tr>
				`)


				$("#detailSIP").html(`
					<tr>
						<th class="text-center">SIPTTK</th>
						<th>Jenis</th>
						<th>Nomor</th>
						<th>Tanggal</th>
						<th>Berlaku Sampai</th>
						<th>Supervisor</th>
						<th>STRA</th>
						<th>No STRTTK</th>
					</tr>
				`)


				$("#detailSTR").html(`
					<tr>
						<th class="text-center">STRTTK</th>
						<th>Nomor</th>
						<th>Tanggal</th>
						<th>Berlaku Sampai</th>
					</tr>
				`)


				var detPendidikan = ``;
				var detPekerjaan = ``;
				var detSerkom = ``;
				var detSIP = ``;
				var detSTR = ``;

				if (dataByID.pendidikan == null || dataByID.pendidikan == '') {
					$("#detailPendidikan").html(`
					<tr>
						<td>Tidak ada data Pendidikan !</td>
					</tr>
					`)
				} else {
					dataByID.pendidikan.forEach(dataPendidikan => {
						detPendidikan = `
							<tr>
								<td class="text-center"><img loading="lazy" src='${dataPendidikan.dokumen}' width="40px"></td>
								<td>${dataPendidikan.jenjang}</td>
								<td>${dataPendidikan.nama_institusi_pendidikan}</td>
								<td>${dataPendidikan.tahun_lulus}</td>
								<td>${dataPendidikan.nomor_ijazah}</td>
							</tr>
						`

						$("#detailPendidikan").append(detPendidikan)
					});

				}

				if (dataByID.pekerjaan == null || dataByID.pekerjaan == '') {
					$("#detailPekerjaan").html(`
					<tr>
						<td>Tidak ada data Pekerjaan !</td>
					</tr>
					`)
				} else {
					dataByID.pekerjaan.forEach(dataPekerjaan => {
						detPekerjaan = `
							<tr>
								<td class="text-center"><img loading="lazy" src='${dataPekerjaan.dokumen}' width="40px"></td>
								<td>${dataPekerjaan.nama_tempat_kerja}</td>
								<td>${dataPekerjaan.jenis_tempat_kerja}</td>
								<td>${dataPekerjaan.jabatan}</td>
								<td>${dataPekerjaan.dari_tahun}</td>
								<td>${dataPekerjaan.sampai_tahun}</td>
								<td>${dataPekerjaan.phone_number}</td>
								<td>${dataPekerjaan.alamat_tempat_kerja}</td>
							</tr>
						`

						$("#detailPekerjaan").append(detPekerjaan)
					});

				}

				if (dataByID.serkom == null || dataByID.serkom == '') {
					$("#detailSerkom").html(`
					<tr>
						<td>Tidak ada data SERKOM !</td>
					</tr>
					`)
				} else {
					dataByID.serkom.forEach(dataSerkom => {
						detSerkom = `
							<tr>
								<td class="text-center"><img loading="lazy" src='${dataSerkom.dokumen}' width="40px"></td>
								<td>${dataSerkom.nomor_serkom}</td>
								<td>${dataSerkom.tanggal}</td>
								<td>${dataSerkom.berlaku_sampai}</td>
							</tr>
						`

						$("#detailSerkom").append(detSerkom)
					});

				}


				if (dataByID.sipttk == null || dataByID.sipttk == '') {
					$("#detailSIP").html(`
					<tr>
						<td>Tidak ada data SIPTTK !</td>
					</tr>
					`)
				} else {
					dataByID.sipttk.forEach(dataSIP => {
						detSIP = `
							<tr>
								<td class="text-center"><img loading="lazy" src='${dataSIP.dokumen}' width="40px"></td>
								<td>${dataSIP.jenis_sipttk}</td>
								<td>${dataSIP.nomor_sipttk}</td>
								<td>${dataSIP.tanggal}</td>
								<td>${dataSIP.berlaku_sampai}</td>
								<td>${dataSIP.nama_apoteker_supervisi}</td>
								<td>${dataSIP.nomor_stra_apoteker}</td>
								<td>${dataSIP.nomor_strttk}</td>
							</tr>
						`

						$("#detailSIP").append(detSIP)
					});

				}


				if (dataByID.strttk == null || dataByID.strttk == '') {
					$("#detailSTR").html(`
					<tr>
						<td>Tidak ada data STRTTK !</td>
					</tr>
					`)
				} else {
					dataByID.strttk.forEach(dataSTR => {
						detSTR = `
							<tr>
								<td class="text-center"><img loading="lazy" src='${dataSTR.dokumen}' width="40px"></td>
								<td>${dataSTR.nomor_strttk}</td>
								<td>${dataSTR.tanggal}</td>
								<td>${dataSTR.berlaku_sampai}</td>
							</tr>
						`

						$("#detailSTR").append(detSTR)
					});

				}


			}

			var loadingElement = document.getElementById("loading");
			if (loadingElement) {
				loadingElement.parentNode.removeChild(loadingElement);
			}


		});
	},
	async _getPengajuanKTAN(){
		let status = document.getElementById('selectStatusApprove').value
		let id_cabang = document.getElementById('selectPengajuanPC').value
		await this._getPengajuanKTANFiltered(id_cabang,status);
		const eventFilterd = async()=>{
			let status = document.getElementById('selectStatusApprove').value
			let id_cabang = document.getElementById('selectPengajuanPC').value
			await this._getPengajuanKTANFiltered(id_cabang,status);
		}

		document.getElementById('selectStatusApprove').addEventListener('change', eventFilterd)
		document.getElementById('selectPengajuanPC').addEventListener('change', eventFilterd)
	},
	async _getPengajuanKTANFiltered(id_cabang,status) {
		var savedOrderColumn = 'created_at';
		var savedOrderDirection = 'desc';

		let disableByStatus = '';
		let bgStatus = '';

		if (status == 'approve') {
			disableByStatus = 'd-none'
			bgStatus = 'success'
			$("#btnVerifikasiAllDt").addClass('d-none')
		}else if (status == 'revise') {
			disableByStatus = ''
			bgStatus = 'danger'
			$("#btnVerifikasiAllDt").removeClass('d-none')
		}else {
			disableByStatus = '';
			bgStatus  = 'warning'
			$("#btnVerifikasiAllDt").removeClass('d-none')
		}

		let table = await $('#tablePengajuanKTAN').DataTable({
			"pageLength": 10,
			"lengthMenu": [10, 25, 50, 100],
			"processing": true,
			"destroy": true,
			dom: 'lBfrtip<"actions">',
			buttons: [
				{
					extend: 'copy',
					text: '<i class="fas fa-copy"></i>',
					exportOptions: {
						columns: ':visible'
					},
					className: "btn btn-light border-primary",
					titleAttr: "Copy to clipboard"
				},
				{
					extend: 'csv',
					text: '<i class="fas fa-file-csv"></i>',
					exportOptions: {
						columns: ':visible'
					},
					className: "btn btn-light border-primary",
					titleAttr: "Download file csv"
				},
				{
					extend: 'excel',
					text: '<i class="fas fa-file-excel"></i>',
					exportOptions: {
						columns: ':visible'
					},
					className: "btn btn-light border-primary",
					titleAttr: "Download file excel"
				},
				{
					extend: 'pdf',
					text: '<i class="fas fa-file-pdf"></i>',
					exportOptions: {
						columns: ':visible'
					},
					className: "btn btn-light border-primary",
					titleAttr: "Download file pdf"
				},
				{
					extend: 'print',
					text: '<i class="fas fa-print"></i>',
					exportOptions: {
						columns: ':visible'
					},
					className: "btn btn-light border-primary",
					titleAttr: "Print",
				},
				{
					text: "<i class='fas fa-check'></i> Approve All",
					exportOptions: {
						columns: ':visible'
					},
					className: "btn btn-primary",
					titleAttr: "Approve All",
					attr: {
						id: "btnVerifikasiAllDt"
					},
					action: function () {
						$("#btnVerifikasiAllDt").html("Loading...");
						$("#btnVerifikasiAll").trigger("click");
					}
				}
			],
			'language': {
				'loadingRecords': '&nbsp;',
				'processing': '<div class="spinner"></div>'
			},
			"serverSide": true,
			"searchDelay": 1000,
			"ajax": {
				"url": `${API_ENDPOINT_PENGURUS_PD.PENGAJUAN_KTAN_FILTERED(id_cabang,status)}`,
				"type": "GET",
				"beforeSend": function (xhr) {
					xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem(CONFIG.TOKEN_USER));
				},
				"data" : function (d) {
					d.orderColumn = savedOrderColumn;
					d.orderDirection = savedOrderDirection;
				}
			},
			"columns": [
				{ data: '' },
				{ data: 'created_at' },
				{ data: 'type_pengajuan', render : function (data) {
					if (data == 'new') {
						return `<div style="display:flex" class="col-sm-12 text-center">
						<span class="badge bg-primary text-light font-weight-none text-capitalize text-center" id="status">KTAN BARU</span>
					</div>`;
					}else {
						return `<div style="display:flex" class="col-sm-12 text-center">
						<span class="badge bg-success text-light font-weight-none text-capitalize text-center" id="status">PERPANJANGAN</span>
					</div>`;
					}
				} },
				{ data: 'user.name' },
				{ data: 'cabang_name' },
				{ data: 'user.nik' },
				{
					data: 'dokumen.ktp_photo',
					render: function (data) {
						if (data == null || data == "") {
							return '-'
						} else {
							return '<img loading="lazy" id="show" src="' + data + '" onerror="this.onerror=null; this.src=\'./src/public/img/image-corrupt-min.png\';" data-original="' + data + '" width="40px">'
						}
					}
				},
				{
					data: 'dokumen.pas_photo',
					render: function (data) {
						if (data == null || data == "") {
							return '-'
						} else {
							return '<img loading="lazy" id="show" src="' + data + '" onerror="this.onerror=null; this.src=\'./src/public/img/image-corrupt-min.png\';" data-original="' + data + '" width="40px">'
						}
					}
				},
				{
					data: 'dokumen.bukti_bayar_ktan',
					render: function (data) {
						if (data == null || data == "") {
							return '-'
						} else {
							return '<img loading="lazy" id="show" src="' + data + '" onerror="this.onerror=null; this.src=\'./src/public/img/image-corrupt-min.png\';" data-original="' + data + '" width="40px">'
						}
					}
				},
				{
					data: 'dokumen.ijazah',
					render: function (data) {
						if (data == null || data == "") {
							return '-'
						} else {
							return '<img loading="lazy" id="show" src="' + data + '" onerror="this.onerror=null; this.src=\'./src/public/img/image-corrupt-min.png\';" data-original="' + data + '" width="40px">'
						}
					}
				},
				{
					data: 'status',
					render: function (data) {
						var status = `<div class="d-flex" style="display:flex">${data}</div>`


						return status
					}

				},
				{
					data: 'status_pd',
					render:function(data){
						var bgStatus2 = ''
						if (data == 'approve') {
							bgStatus2 = 'success'
						}else if (data == 'revise') {
							bgStatus2 = 'danger'
						}else {
							bgStatus2  = 'warning'
						}
						return `<div style="display:flex" class="col-sm-12 text-center">
						<span class="badge bg-${bgStatus2} text-light font-weight-none text-capitalize text-center" id="status">${data}</span>
					</div>`
					}
				},{
					data: 'adminPc.name',
					render: function (data) {

						if (data == null || data == '') {
							var status = 'Tidak ada data'
						} else {
							var status = `<div class="d-flex" style="display:flex">${data}</div>`
						}


						return status
					}

				},
				{
					data: 'approval_cabang_at',
					render: function (data) {

						if (data == null || data == '') {
							var status = 'Tidak ada data'
						} else {
							var status = `<div class="d-flex" style="display:flex">${data}</div>`
						}


						return status
					}

				},
				{
					data: 'adminPd.name',
					render: function (data) {
						if (data == null || data == '') {
							var status = 'Tidak ada data'
						} else {
							var status = `<div class="d-flex" style="display:flex">${data}</div>`
						}
						return status
					}

				},
				{
					data: 'approval_daerah_at',
					render: function (data) {
						if (data == null || data == '') {
							var status = 'Tidak ada data'
						} else {
							var status = `<div class="d-flex" style="display:flex">${data}</div>`
						}
						return status
					}

				},
				{
					data: 'adminPp.name',
					render: function (data) {
						if (data == null || data == '') {
							var status = 'Tidak ada data'
						} else {
							var status = `<div class="d-flex" style="display:flex">${data}</div>`
						}

						return status
					}

				},
				{
					data: 'approval_pusat_at',
					render: function (data) {
						if (data == null || data == '') {
							var status = 'Tidak ada data'
						} else {
							var status = `<div class="d-flex" style="display:flex">${data}</div>`
						}

						return status
					}

				},
			],
			"fnDrawCallback": function (data) {
				let rowTable = data.aoData;
				const checked = document.getElementById('checkAll').checked;
				if (checked) {
					document.getElementById('checkAll').checked = false;
				}

				$('#tablePengajuanKTAN tbody tr').each(function (e) {
					if (rowTable[e] !== undefined) {
						let result = rowTable[e]._aData.id;
						let isChecked = StorageAnggotaPD.isReady(result);
						if (isChecked) {
							$(this).addClass('selected');
						}
					}
				})
			},
			"initComplete": function () {
				$('#tablePengajuanKTAN_filter input').unbind();
					$('#tablePengajuanKTAN_filter input').bind('keyup', function(e) {
						if(e.keyCode == 13) {
							table.search($('#tablePengajuanKTAN_filter input').val()).draw();
						}
					}); 
			},
			select: {
				style: 'multi',
				selector: 'td:first-child'
			},
			order: [
				[1, 'desc']
			],
			"columnDefs": [{
				"targets": 0,
				"orderable": false,
				className: 'select-checkbox',
				"data": null,
				"defaultContent": ``
			},
			{
				"targets": [3,4,5,6,7,8,9,10,11,13,15],
				"orderable": false
			},
			{
				"targets": [0],
				"visible": true,
				"searchable": false
			},
			{
				"targets": 18,
				"data": null,
				"render": function (data, type, row) {
					return `<div style="display:flex">
					<button style="margin:1px" class='btn btn-success btn-sm ${disableByStatus}' id='approve' title='Approve'><i class='fas fa-check'></i></button>
					<button style="margin:1px" class='btn btn-danger btn-sm' data-toggle="modal" data-target="#commentModal" id='revisi' title='Revisi'><i class='fas fa-times'></i></button>
					<button style="margin:1px; position:relative;" class='btn btn-info btn-sm' data-toggle="modal" data-target="#commentModalUserKTAN" id='comment' title='Komentar'><i class='fas fa-comment fa-fw'></i><span class="badge-chat" style="">${data.comment_unread_count}</span></button>
				</div>`
				},
			},
			{
				"targets": [5, 6, 7, 8],
				"visible": true,
				"searchable": true,
				"className": "text-center",
			}
			]
		});

		$('#tablePengajuanKTAN tbody').on('click', '.select-checkbox', async function (e) {
			e.stopPropagation();
			const table = $('#tablePengajuanKTAN').DataTable();
			let data = table.row($(this).parents('tr')).data();
			let idPengajuan = data.id

			if ($(this).parents('tr').hasClass("selected")) {
				$(this).parents('tr').removeClass('selected');
				await StorageAnggotaPD.deleteData(idPengajuan);
			} else {
				$(this).parents('tr').addClass('selected');
				await StorageAnggotaPD.pushData(idPengajuan);
			}
		});


		$('#tablePengajuanKTAN thead').off('click', 'th').on('click', 'th', function () {
			let chachedCoulumnName = savedOrderColumn;
			const columnIndex = $(this).index();
			const columnHeaders = $('#tablePengajuanKTAN thead th');
			const columnName = $(columnHeaders[columnIndex]).data('column');
			var order =  table.order()[0][1];
			
			if (order == "asc") {
				order = "desc"
			}else{
				order = "asc"
			}
			if(chachedCoulumnName != columnName){
				order = "asc"
			}
			savedOrderColumn = columnName;
			chachedCoulumnName = savedOrderColumn;
			savedOrderDirection = order;

		});

	},


	async _initCommentKTAN() {
		let idPengajuan = '';
		let userID = '';
		$('#tablePengajuanKTAN tbody').on('click', '#comment', async function () {
			const table = $('#tablePengajuanKTAN').DataTable();
			let data = table.row($(this).parents('tr')).data();
			userID = data.user_id
			idPengajuan = data.id;

			let getContentAdmin = (user) => {
				let time = new Date(user.updated_at).toLocaleDateString('en-US') + " " + new Date(user.updated_at).toLocaleTimeString('en-US');
				return `
					<div style="width:100%">
						<div style="width:10%;" class="float-left">
							<img loading="lazy" class="rounded-circle img-thumbnail img-square float-left" id="imgUser" src="${user.actor.avatar}" style="width:50px; height:50px;">
						</div>
						<div class="float-left text-dark text-left" style="width:88%;">
							<div class="float-left" style="background:#dedede; margin:5px; padding:0 12px; padding-bottom:5px; border-radius:0px 10px" id="commentUser">
								<small><b>${user.actor.name} [${user.actor.role}]</b></small><hr>
								<small>${user.body}</small><br>
								<div class="text-right">
									<small style="margin-top:-2px; margin-left:50px"><strong>${time}</strong></small>
								</div>
							</div>
						</div>
					</div>
					`
			}

			let getContentUser = (user) => {
				let time = new Date(user.updated_at).toLocaleDateString('en-US') + " " + new Date(user.updated_at).toLocaleTimeString('en-US');
				return `
						<div style="width:100%">
							<div style="width:10%;" class="float-right">
								<img loading="lazy" class="rounded-circle img-thumbnail img-square float-left" id="imgUser" src="${user.actor.avatar}" style="width:50px; height:50px;">
							</div>
							<div class="float-right text-dark text-left" style="width:88%;">
								<div class="float-right" style="background:#e0e8fc; margin:5px; padding:0 12px; padding-bottom:5px; border-radius:10px 0px" id="commentUser">
									<small><b>${user.actor.name} [${user.actor.role}]</b></small><hr>
									<small>${user.body}</small><br>
									<div class="text-right">
										<small style="margin-top:-2px; margin-left:50px"><strong>${time}</strong></small>
									</div>
								</div>
							</div>
						</div>
						`
			}


			let response = await ApiPengajuanKTANPC.getCommentar(userID, "membership", idPengajuan)
			let dataComment = response.data;
			dataComment.reverse();

			if (dataComment.length <= 0) {
				$("#content-comment").html("<br><center><i>Tidak ada komentar</i></center>");
			} else {
				let getAllContent = async () => {
					let elmHtml = ``
					await dataComment.forEach(async element => {
						elmHtml = elmHtml + (element.actor_type == "user" ? getContentAdmin(element) : getContentUser(element))
					});
					return elmHtml
				}


				if (response.data == undefined || response.data.length <= 0) {
					document.getElementById("content-comment").innerHTML = `<span class="no-comment">Belum ada komentar</span>`
				} else {
					document.getElementById("content-comment").innerHTML = await getAllContent();

					setTimeout(() => {
						var elem = document.getElementById('content-comment');
						elem.scrollTop = elem.scrollHeight;
					}, 400);
				}
			}

		});

		const eventSendCommentar = async (e) => {
			e.preventDefault();
			document.getElementById("btnSendKomentarPengajuanKTAN").innerHTML = `<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>`
			let content = document.getElementById("komentarPengajuanKTANUser").value
			const response = await ApiPengajuanKTANPD.sendCommentar({
				userID: userID,
				pengajuanID: idPengajuan,
				commentFor: 'membership',
				contentComment: content,
			});
			if (response.status_code == 200 || 201) {


				let getContentAdmin = (user) => {
					let time = new Date(user.updated_at).toLocaleDateString('en-US') + " " + new Date(user.updated_at).toLocaleTimeString('en-US');
					return `
						<div style="width:100%">
							<div style="width:10%;" class="float-left">
								<img loading="lazy" class="rounded-circle img-thumbnail img-square float-left" id="imgUser" src="${user.actor.avatar}" style="width:50px; height:50px;">
							</div>
							<div class="float-left text-dark text-left" style="width:88%;">
								<div class="float-left" style="background:#dedede; margin:5px; padding:0 12px; padding-bottom:5px; border-radius:0px 10px" id="commentUser">
									<small><b>${user.actor.name} [${user.actor.role}]</b></small><hr>
									<small>${user.body}</small><br>
									<div class="text-right">
										<small style="margin-top:-2px; margin-left:50px"><strong>${time}</strong></small>
									</div>
								</div>
							</div>
						</div>
						`
				}

				let getContentUser = (user) => {
					let time = new Date(user.updated_at).toLocaleDateString('en-US') + " " + new Date(user.updated_at).toLocaleTimeString('en-US');
					return `
							<div style="width:100%">
								<div style="width:10%;" class="float-right">
									<img loading="lazy" class="rounded-circle img-thumbnail img-square float-left" id="imgUser" src="${user.actor.avatar}" style="width:50px; height:50px;">
								</div>
								<div class="float-right text-dark text-left" style="width:88%;">
									<div class="float-right" style="background:#e0e8fc; margin:5px; padding:0 12px; padding-bottom:5px; border-radius:10px 0px" id="commentUser">
										<small><b>${user.actor.name} [${user.actor.role}]</b></small><hr>
										<small>${user.body}</small><br>
										<div class="text-right">
											<small style="margin-top:-2px; margin-left:50px"><strong>${time}</strong></small>
										</div>
									</div>
								</div>
							</div>
							`
				}


				let response = await ApiPengajuanKTANPC.getCommentar(userID, "membership", idPengajuan)
				let dataComment = response.data;
				dataComment.reverse();

				if (dataComment.length <= 0) {
					$("#content-comment").html("<br><center><i>Tidak ada komentar</i></center>");
				} else {
					let getAllContent = async () => {
						let elmHtml = ``
						await dataComment.forEach(async element => {
							elmHtml = elmHtml + (element.actor_type == "user" ? getContentAdmin(element) : getContentUser(element))
						});
						return elmHtml
					}


					if (response.data == undefined || response.data.length <= 0) {
						document.getElementById("content-comment").innerHTML = `<span class="no-comment">Belum ada komentar</span>`
					} else {
						document.getElementById("content-comment").innerHTML = await getAllContent();

						setTimeout(() => {
							var elem = document.getElementById('content-comment');
							elem.scrollTop = elem.scrollHeight;
						}, 400);
					}
				}

				$('#komentarPengajuanKTANUser').val('');
				document.getElementById("btnSendKomentarPengajuanKTAN").innerHTML = `<i class="fas fa-comment"></i> Berikan Komentar`;
			} else {
				document.getElementById("btnSendKomentarPengajuanKTAN").innerHTML = `<i class="fas fa-comment"></i> Berikan Komentar`;
			}
			e.preventDefault();
		}
		document.getElementById("btnSendKomentarPengajuanKTAN").addEventListener("click", eventSendCommentar)
	},

	async _checkAllEvent() {
		const eventSelectAll = async () => {
			let parentElement = document.getElementById('tablePengajuanKTAN')
			let tagElement = parentElement.getElementsByTagName('tbody')[0]
			const element = tagElement.querySelectorAll(".select-checkbox");
			const checked = document.getElementById('checkAll').checked;
			for (let index = 0; index < element.length; index++) {
				const table = $('#tablePengajuanKTAN').DataTable();
				let data = table.row(index).data()
				if (checked) {
					await StorageAnggotaPD.pushData(data.id)
					await element[index].parentNode.classList.add('selected');
				} else {
					element[index].parentNode.classList.remove('selected');
					await StorageAnggotaPD.deleteData(data.id)
				}
			}
		}

		document.getElementById('checkAll').addEventListener('change', eventSelectAll)

	},

	async _approvePengajuanKTAN() {

		let idPengajuan = 0;
		$('#tablePengajuanKTAN tbody').on('click', '#approve', async function () {
			let elmInThis = $(this)
			const table = $('#tablePengajuanKTAN').DataTable();
			let data = table.row($(this).parents('tr')).data();
			swal.fire({
				title: 'Approve Pengajuan',
				html: "Yakin menyetujui data ini ? " + data.user.name,
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Ya, Setujui'
			}).then(async (result) => {
				if (result.value) {
					elmInThis.html('<img loading="lazy" src="images/loading-gif.gif" width="18px">');
					
					idPengajuan = data.id;
		
					const approveAnggota = await ApiPengajuanKTANPD.aprroveKTAN({
						id: idPengajuan,
						status: "approve",
						comment: ""
					});
		
		
					if (approveAnggota.status_code != 200) {
						NotificationModal.show(`${approveAnggota.message}`, 'error');
						elmInThis.html("<i class='fas fa-check'></i>");
						$('#tablePengajuanKTAN').DataTable().ajax.reload();
						await StorageAnggotaPD.makeStore();
					} else {
						NotificationModal.show(`${approveAnggota.message}`, 'success');
						elmInThis.html("<i class='fas fa-check'></i>");
						$('#tablePengajuanKTAN').DataTable().ajax.reload();
						await StorageAnggotaPD.makeStore();
					}
				}
			});
		});

		$('#tablePengajuanKTAN tbody').on('click', '#revisi', async function () {
			const table = $('#tablePengajuanKTAN').DataTable();
			let data = table.row($(this).parents('tr')).data();
			let UserID = data.user_id
			idPengajuan = data.id;

			let getContentAdmin = (user) => {
				let time = new Date(user.updated_at).toLocaleDateString('en-US') + " " + new Date(user.updated_at).toLocaleTimeString('en-US');
				return `
						<div style="width:100%">
							<div style="width:10%;" class="float-left">
								<img loading="lazy" class="rounded-circle img-thumbnail img-square float-left" id="imgUser" src="${user.actor.avatar}" style="width:50px; height:50px;">
							</div>
							<div class="float-left text-dark text-left" style="width:88%;">
								<div class="float-left" style="background:#dedede; margin:5px; padding:0 12px; padding-bottom:5px; border-radius:0px 10px" id="commentUser">
									<small><b>${user.actor.name} [${user.actor.role}]</b></small><hr>
									<small>${user.body}</small><br>
									<div class="text-right">
										<small style="margin-top:-2px; margin-left:50px"><strong>${time}</strong></small>
									</div>
								</div>
							</div>
						</div>
						`
			}

			let getContentUser = (user) => {
				let time = new Date(user.updated_at).toLocaleDateString('en-US') + " " + new Date(user.updated_at).toLocaleTimeString('en-US');
				return `
							<div style="width:100%">
								<div style="width:10%;" class="float-right">
									<img loading="lazy" class="rounded-circle img-thumbnail img-square float-left" id="imgUser" src="${user.actor.avatar}" style="width:50px; height:50px;">
								</div>
								<div class="float-right text-dark text-left" style="width:88%;">
									<div class="float-right" style="background:#e0e8fc; margin:5px; padding:0 12px; padding-bottom:5px; border-radius:10px 0px" id="commentUser">
										<small><b>${user.actor.name} [${user.actor.role}]</b></small><hr>
										<small>${user.body}</small><br>
										<div class="text-right">
											<small style="margin-top:-2px; margin-left:50px"><strong>${time}</strong></small>
										</div>
									</div>
								</div>
							</div>
							`
			}


			let response = await ApiPengajuanKTANPD.getCommentar(UserID, "membership", idPengajuan)
			let dataComment = response.data;
			dataComment.reverse();

			if (dataComment.length <= 0) {
				$("#content-comment").html("<br><center><i>Tidak ada komentar</i></center>");
			} else {
				let getAllContent = async () => {
					let elmHtml = ``
					await dataComment.forEach(async element => {
						elmHtml = elmHtml + (element.actor_type == "user" ? getContentAdmin(element) : getContentUser(element))
					});
					return elmHtml
				}


				if (response.data == undefined || response.data.length <= 0) {
					document.getElementById("content-comment").innerHTML = `<span class="no-comment">Belum ada komentar</span>`
				} else {
					document.getElementById("content-comment").innerHTML = await getAllContent();

					setTimeout(() => {
						var elem = document.getElementById('content-comment');
						elem.scrollTop = elem.scrollHeight;
					}, 400);
				}
			}


		});

		$("#btnSendKomentarPengajuan").click(async function (e) {
			$(this).html("Loading...");
			e.stopPropagation()
			let comment = $("#komentarPengajuan").val();
			if (comment === "" || null) {
				NotificationModal.show(`Komentar tidak boleh kosong !`, 'error');
				$(this).html("<i class='fas fa-comment'></i> Revisi & Berikan Komentar");
			} else {
				const revisePengajuanKTAN = await ApiPengajuanKTANPD.aprroveKTAN({
					id: idPengajuan,
					status: "revise",
					comment: comment
				})

				if (revisePengajuanKTAN.status_code != 200) {
					NotificationModal.show(`${revisePengajuanKTAN.message}`, 'error');
					$(this).html("<i class='fas fa-comment'></i> Revisi & Berikan Komentar");
					$("#komentarPengajuan").val("")
					$('#commentModal').modal('hide');
				} else {
					NotificationModal.show(`${revisePengajuanKTAN.message}`, 'success');
					$(this).html("<i class='fas fa-comment'></i> Revisi & Berikan Komentar");
					$("#komentarPengajuan").val("")
					$('#commentModal').modal('hide');
					$('#tablePengajuanKTAN').DataTable().ajax.reload();
				}
			}
		});



		$("#btnCancel").click(function () {
			$("#komentarPengajuan").val("")
		});
	},

	_navigateToLogin() {
		window.location.hash = '#/login';
	},

	async _initAllApprove() {
		const eventAllApprove = async () => {
			if (await StorageAnggotaPD.size() <= 0) {
				NotificationModal.show("Silahkan Pilih Data Terlebih Dahulu", "warning")
				$("#btnVerifikasiAllDt").html("<i class='fas fa-check'></i> Approve All")
			} else {
				let data = await StorageAnggotaPD.getData()
				swal.fire({
					title: 'Setujui Semua',
					html: "Yakin menyetujui semua ? <br> <strong>Jumlah Data </strong>: " + data.length,
					icon: 'warning',
					showCancelButton: true,
					confirmButtonColor: '#3085d6',
					cancelButtonColor: '#d33',
					confirmButtonText: 'Ya, Setujui'
				}).then(async (result) => {
					if (result.value) {
						const response = await ApiPengajuanKTANPD.approveAllKTAN(data)
						if (response.status_code == 200) {
							NotificationModal.show("Data Berhasil di Approve", "success");
							$("#btnVerifikasiAllDt").html("<i class='fas fa-check'></i> Approve All")
							$('#tablePengajuanKTAN').DataTable().ajax.reload();
							await StorageAnggotaPD.makeStore();
						} else {
							NotificationModal.show(`${response.message}`, "error");
							$('#tablePengajuanKTAN').DataTable().ajax.reload();
							$("#btnVerifikasiAllDt").html("<i class='fas fa-check'></i> Approve All")
							await StorageAnggotaPD.makeStore();
						}
					} else if (result.dismiss == 'cancel') {
						$("#btnVerifikasiAllDt").html("<i class='fas fa-check'></i> Approve All")
					};
				});
			}
		}
		document.getElementById('btnVerifikasiAll').addEventListener('click', eventAllApprove)
	},

	async _getDataAnggota(){

		const theEvent = async ()=>{
			let status_ktan = document.getElementById("selectJenisKeanggotaan").value;
			let id_cabang = document.getElementById("selectAnggotaPC").value;
			let jenjang = document.getElementById("selectJenjang").value;
			await this._getDataAnggotaFilterd(status_ktan,id_cabang, jenjang)

		}

		$("#tab2").one("click", async function () {
		mRefresh.refresh()
		await theEvent();
		const eventChangeFilter = async ()=>{
			await theEvent();
		}

		document.getElementById('selectJenisKeanggotaan').addEventListener('change', eventChangeFilter)
		document.getElementById('selectAnggotaPC').addEventListener('change', eventChangeFilter)
		document.getElementById('selectJenjang').addEventListener('change', eventChangeFilter)
		mRefresh.resolve()
	});
	},

	async _downloadKTAN(){
		let id = '';
		$("#tableDataAnggota tbody").on("click", "#downloadKTAN", async function () {
			const table = $("#tableDataAnggota").DataTable();
			let data = table.row($(this).parents('tr')).data();
			let nama = data.name
			id = data.uuid
			$(this).html(`<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>`)
			const response = await ApiPengajuanKTANPD.downloadKTAN({id,nama});

			if (response.status_code != 200) {
				$(this).html(`<i class="fas fa-download"></i> KTAN`);
			} else {
				$(this).html(`<i class="fas fa-download"></i> KTAN`);
			}
		})
	},

	async _getDataAnggotaFilterd(status_ktan,id_cabang,jenjang) {
		var savedOrderColumn = 'name';
		var savedOrderDirection = 'asc';
		let table = await $('#tableDataAnggota').DataTable({
			"pageLength": 10,
			"lengthMenu": [10, 25, 50, 100],
			"processing": true,
			dom: 'lBfrtip<"actions">',
			buttons: [
				{
					extend: 'copy',
					text: '<i class="fas fa-copy"></i>',
					exportOptions: {
						columns: ':visible'
					},
					className: "btn btn-light border-primary",
					titleAttr: "Copy to clipboard"
				},
				{
					extend: 'csv',
					text: '<i class="fas fa-file-csv"></i>',
					exportOptions: {
						columns: ':visible'
					},
					className: "btn btn-light border-primary",
					titleAttr: "Download file csv"
				},
				{
					extend: 'excel',
					text: '<i class="fas fa-file-excel"></i>',
					exportOptions: {
						columns: ':visible'
					},
					className: "btn btn-light border-primary",
					titleAttr: "Download file excel"
				},
				{
					extend: 'pdf',
					text: '<i class="fas fa-file-pdf"></i>',
					exportOptions: {
						columns: ':visible'
					},
					className: "btn btn-light border-primary",
					titleAttr: "Download file pdf"
				},
				{
					extend: 'print',
					text: '<i class="fas fa-print"></i>',
					exportOptions: {
						columns: ':visible'
					},
					className: "btn btn-light border-primary",
					titleAttr: "Print",
				},
				{
					text: '<i class="fas fa-download"></i> Download Rekap',
					exportOptions: {
						columns: ':visible'
					},
					className: "btn btn-primary",
					titleAttr: "Download Rekap Anggota",
					attr: {
						id: "btnDownloadReport",
						"data-toggle" : "modal",
						"data-target" : "#modalGenerateReport",
					},
				}
			],
			"destroy": true,
			'language': {
				'loadingRecords': '&nbsp;',
				'processing': '<div class="spinner"></div>'
			},
			"serverSide": true,
			"searchDelay": 1000,
			"ajax": {
				"url": `${API_ENDPOINT_PENGURUS_PD.GET_ALL_USER_KTAN_1(status_ktan,id_cabang,jenjang)}`,
				"type": "GET",
				"beforeSend": function (xhr) {
					xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem(CONFIG.TOKEN_USER));
				},
				"data" : function (d) {
					d.orderColumn = savedOrderColumn;
    				d.orderDirection = savedOrderDirection;
				}
			},
			"columns": [
				{ data: 'name' },
				{ data: 'email' },
				{ data: 'cabang_name' },
				{ data: 'ktan' },
				{ data: 'strttk.nomor_strttk', render : function (data) {
					if (data == '' || data == null) {
						return '-'
					}else return data
				} },
				{data: 'pendidikan', render : function (data) {
					var viewHtml = ``
					if (data === undefined || data.length == 0) {
						viewHtml = '-'
					}else{
						data.forEach(pendidikanData => {
							viewHtml += `<li style="height:45px;  width:150px">${pendidikanData.jenjang}</li><br>`
						});
					}
					return viewHtml;
				}  },
				{ data: 'pendidikan', render : function (data) {
					var viewHtml = ``
					if (data === undefined || data.length == 0) {
						viewHtml = '-'
					}else{
						data.forEach(pendidikanData => {
							viewHtml += `<li style="height:45px; width:250px"> ${pendidikanData.nama_institusi_pendidikan}</li><br>`

						});
					}
					return viewHtml;
				}  },
				{ data: 'pendidikan', render : function (data) {
					var viewHtml = ``
					if (data === undefined || data.length == 0) {
						viewHtml = '-'
					}else{
						data.forEach(pendidikanData => {
							viewHtml += `<li style="height:45px; width:90px" >${pendidikanData.tahun_lulus}</li><br>`

						});
					}
					return viewHtml
				}  },
				{ data: 'pendidikan', render : function (data) {
					var viewHtml = ``
					if (data === undefined || data.length == 0) {
						viewHtml = '-'
					}else{
						data.forEach(pendidikanData => {
							viewHtml += `<li style="height:45px; width:120px">${pendidikanData.tanggal_ijazah}</li><br>`
						});
					}
					return viewHtml
				}  },
				{ data: 'jenis_kelamin' },
				{ data: 'tempat_lahir' },
				{ data: 'tanggal_lahir' },
				{ data: 'phone_number' },
				{
					data: 'ktan_expires_at', render: function (data) {
						if (data == null || data == "") {
							return '<span class="badge badge-danger">Belum Di Setting</span>'
						} else {
							return data;
						}
					}
				},
				{
					data: 'avatar', render: function (data) {
						if (data == null || data == "") {
							return '-'
						} else {
							return '<img loading="lazy" id="show" src="' + data + '" onerror="this.onerror=null; this.src=\'./src/public/img/image-corrupt-min.png\';" data-original="' + data + '" width="40px">'
						}
					}
				},
				{
					data: 'dokumen.old_ktan', render: function (data) {
						if (data == null || data == "") {
							return '-'
						} else {
							return '<img loading="lazy" id="show" src="' + data + '" onerror="this.onerror=null; this.src=\'./src/public/img/image-corrupt-min.png\';" data-original="' + data + '" width="40px">'
						}
					}
				},
				{
					data: 'dokumen.ktp_photo', render: function (data) {
						if (data == null || data == "") {
							return '-'
						} else {
							return '<img loading="lazy" id="show" src="' + data + '" onerror="this.onerror=null; this.src=\'./src/public/img/image-corrupt-min.png\';" data-original="' + data + '" width="40px">'
						}
					}
				},
				{
					data: 'dokumen.ijazah', render: function (data) {
						if (data == null || data == "") {
							return '-'
						} else {
							return '<img loading="lazy" id="show" src="' + data + '" onerror="this.onerror=null; this.src=\'./src/public/img/image-corrupt-min.png\';" data-original="' + data + '" width="40px">'
						}
					}
				},
				{data: 'ktan', render: function (data) {
					let content = ``
					if (data != null) {
						content = `
						<div class="d-flex">
						<button style="margin:1px" class='btn btn-primary btn-sm' id='detail' data-toggle="modal" data-target="#detailAnggotaModal" title='Detail'><i class='fas fa-eye'></i></button>
						<button style="margin:1px" class='btn btn-success btn-sm' id='downloadKTAN' title='Download KTAN'><i class='fas fa-download'></i> KTAN</button>
						</div>
						`
					}else {
						content = `
						<div class="d-flex">
						<button style="margin:1px" class='btn btn-primary btn-sm' id='detail' data-toggle="modal" data-target="#detailAnggotaModal" title='Detail'><i class='fas fa-eye'></i></button>
						</div>
						`
					}

					return content
						
				}
				}
			],
			"initComplete": function () {
				$('#tableDataAnggota_filter input').unbind();
					$('#tableDataAnggota_filter input').bind('keyup', function(e) {
						if(e.keyCode == 13) {
							table.search($('#tableDataAnggota_filter input').val()).draw();
						}
					}); 
			},
			"fnDrawCallback": function (data) {
				let rowTable = data.aoData;

			},
			select: {
				style: 'multi',
				selector: 'td:first-child'
			},
			"columnDefs": [
				{
					"targets": [0],
					"visible": true,
					"searchable": true
				},
				{
					"targets": [2,4,5,6,7,8,12,14,15,16,17,18],
					"orderable": false
				},
				{
					"targets": [14,15,16,17,18],
					"visible": true,
					"searchable": false,
					"className": "text-center"
				},
			]
		});


		$('#tableDataAnggota thead').off('click', 'th').on('click', 'th', function () {
			let chachedCoulumnName = savedOrderColumn;
			const columnIndex = $(this).index();
			const columnHeaders = $('#tableDataAnggota thead th');
			const columnName = $(columnHeaders[columnIndex]).data('column');
			var order =  table.order()[0][1];
			
			if (order == "asc") {
				order = "desc"
			}else{
				order = "asc"
			}
			if(chachedCoulumnName != columnName){
				order = "asc"
			}
			savedOrderColumn = columnName;
			chachedCoulumnName = savedOrderColumn;
			savedOrderDirection = order;
		});



	},

	
	async _downloadReportByPendidikan() {

		const eventDownload = async () => {
			let filter = document.getElementById('selectFilter').value;
			let documentType = document.getElementById('selectDocument').value;
			let role = 'daerah';
			const btn = document.getElementById('btnGenerateReport');
			if (filter == '' || documentType == ''){
				NotificationModal.show('Harap lengkapi semua isian !', 'warning');
			}else {
				btn.disable = true;
				btn.innerHTML = '<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>';
				const downloadReportByPendidikan = await ApiKepengurusanPP.downloadReportByPendidikan({
					filter : filter,
					document_type : documentType,
					role : role
				});
				swal.fire({
					title: 'Success !',
					html: "Data berhasil di download ",
					icon: 'success',
					showCancelButton: false,
					confirmButtonColor: '#3085d6',
					cancelButtonColor: '#d33',
					confirmButtonText: 'OK'
				})
	
				btn.innerHTML = '<i class="fas fa-download"></i> Download Report';
				btn.disabled = false;
			}
			
			
		}

		document.getElementById('btnGenerateReport').addEventListener('click', eventDownload);


		// $('#tablePengajuanSTRTTK tbody').on('click', '#btnDownloadByPendidikan', async function () {
		// 	$(this).html('<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>');
		// 	let table = $('#tablePengajuanSTRTTK').DataTable();
		// 	let data = table.row($(this).parents('tr')).data();
		// 	let id = data.id;

		// 	if (data.status_pd != "approve") {
		// 		swal.fire({
		// 			title: 'Warning !',
		// 			html: "Status Permohonan : <strong><u>" + data.status_pd + "</u></strong><br>Anda tidak dapat mendownload surat rekomendasi sebelum di setujui oleh Admin PD ",
		// 			icon: 'warning',
		// 			showCancelButton: false,
		// 			confirmButtonColor: '#3085d6',
		// 			cancelButtonColor: '#d33',
		// 			confirmButtonText: 'OK'
		// 		})
		// 	} else {
		// 		const downloadRekomSTRTTKPD = await ApiAnggota.downloadSTRTTKPD({ id });
		// 	}
		// 	$(this).html('<i class="fas fa-download"></i> Rekomendasi STRTTK');

		// });
	},


}

const StorageAnggotaPD = {

	async makeStore() {
		this._data = [];
	},

	isReady(kode) {
		const result = this._data.map((e) => { return e.id_pengajuan; }).indexOf(kode);
		let value = false
		if (result != -1) {
			value = true
		}
		return value;
	},

	async pushData(idPengajuan) {
		if (this.isReady(idPengajuan)) return
		let gold = {
			"id_pengajuan": idPengajuan,
		}
		this._data.push(gold);
	},

	async deleteData(kode) {
		let removeIndex = this._data.map((item) => { return item.id_pengajuan; }).indexOf(`${kode}`);
		this._data.splice(removeIndex, 1)
	},

	async size() {
		return this._data.length
	},

	async getData() {
		return this._data;
	}
}

export default AnggotaPDPresenter;