import AnggotaPCPresenter from "../../../presenter/menu_pengurus_pc/anggota-pc-presenter.js";
import ImagePopUPInitiator from '../../../presenter/pop-up-presenter.js';




const AnggotaPC = {
	async render() {
		const view = `
		<!-- DataTales Example -->
		<div class="card shadow mb-4">
			<div class="card-body">
				<ul class="nav nav-tabs" id="myTab" role="tablist">
                    <li class="nav-item" role="presentation">
                        <button href="#tabVerifikasiKTAN" class="nav-link hapus-border active" id="data-verifikasi-anggota" data-bs-toggle="tab" data-bs-target="#tabVerifikasiKTAN" data-toggle="tab" type="button" role="tab" aria-controls="tabVerifikasiKTAN" aria-selected="true">Pengajuan KTAN</button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button href="#tabDataAnggota" class="nav-link hapus-border" id="tab2" data-bs-toggle="tab" data-bs-target="#tabDataAnggota" data-toggle="tab" type="button" role="tab" aria-controls="tabDataAnggota" aria-selected="true">Data Anggota</button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button href="#tabDataRegistrasiAnggotaLama" class="nav-link hapus-border" id="tab3" data-bs-toggle="tab" data-bs-target="#tabDataRegistrasiAnggotaLama" data-toggle="tab" type="button" role="tab" aria-controls="tabDataRegistrasiAnggotaLama" aria-selected="true">Registrasi Anggota Lama</button>
                    </li>
				</ul>
				<div class="tab-content" id="myTabContent">
					<div class="tab-pane fade show active" id="tabVerifikasiKTAN" role="tabpanel" aria-labelledby="tab-verifikasi-ktan">
						<div class="row mt-3">
                            <div class="col-sm-12">
                                <div class="card mb-3">
                                    <div class="card-header font-weight-bold text-primary">
                                        Filter Data
                                    </div>
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-sm-4">
                                                <label class="control-label font-weight-bold">Status Approvement</label>
                                                <select id="selectStatus" class="form-control">
                                                    <option value="pending">Pending</option>
                                                    <option value="approve">Disetujui</option>
                                                    <option value="revise">Revisi</option>
                                                    <option value="all">Semua</option>
                                                </select>
                                            </div>
                                            <div class="col-sm-4">
                                                <label class="control-label font-weight-bold">Dari Tanggal</label>
                                                <input type="date"  pattern="\d{2}-\d{2}-\d{4}" class="form-control" id="dateDariTanggal"> 
                                            </div>
                                            <div class="col-sm-4">
                                                <label class="control-label font-weight-bold">Sampai Tanggal</label>
                                                <input type="date"  pattern="\d{2}-\d{2}-\d{4}" class="form-control" id="dateSampaiTanggal"> 
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header font-weight-bold text-primary">
                                        Pengajuan KTAN
                                    </div>
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-sm-5 col-xl-5 col-lg-5 text-center">
                                                <div class="row">
                                                    <div class="col-sm-8 mb-3">
                                                    
                                                    </div>
                                                    <div class="col-sm-4 d-none">
                                                        <button type="button" class="btn btn-primary" title="Aprrove" id="btnVerifikasiAll">
                                                            <i class="fas fa-check"></i>
                                                        </button>
                                                        <button type="button" class="btn btn-light border" title="Print" id="btnPrint">
                                                            <i class="fas fa-print"></i>
                                                        </button>
                                                        <button type="button" class="btn btn-light border" title="Downloads" id="btnDownload">
                                                            <i class="fas fa-download"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-12">
                                                <div class="table-responsive">
                                                    <table class="table table-bordered table-hover" id="tablePengajuanKTAN" width="100%" cellspacing="0">
                                                        <thead>
                                                            <tr>
                                                                <th class="text-center"><input type="checkbox" id="checkAll"></th>
                                                                <th data-column="created_at">Tanggal Pengajuan</th>
                                                                <th>Jenis</th>
                                                                <th data-column="name" >Nama Anggota</th>
                                                                <th>NIK</th>
                                                                <th>KTP</th>
                                                                <th>PAS Foto</th>
                                                                <th>Bukti Bayar</th>
                                                                <th>Ijazah</th>
                                                                <th data-column="status" class="text-center">Tracking</th>
                                                                <th class="text-center">Status PC</th>
                                                                <th>Admin PC</th>
                                                                <th data-column="approval_cabang_at">Tanggal Approval PC</th>
                                                                <th>Admin PD</th>
                                                                <th data-column="approval_daerah_at" >Tanggal Approval PD</th>
                                                                <th>Admin PP</th>
                                                                <th data-column="approval_pusat_at" >Tanggal Approval PP</th>
                                                                <th>Aksi</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td><div class="shimmer title-line end"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer circle"></div></td>
                                                                <td><div class="shimmer circle"></div></td>
                                                                <td><div class="shimmer circle"></div></td>
                                                                <td><div class="shimmer circle"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line end"></div></td>
                                                            </tr>
                                                            <tr>
                                                                <td><div class="shimmer title-line end"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer circle"></div></td>
                                                                <td><div class="shimmer circle"></div></td>
                                                                <td><div class="shimmer circle"></div></td>
                                                                <td><div class="shimmer circle"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line end"></div></td>
                                                            </tr>
                                                            <tr>
                                                                <td><div class="shimmer title-line end"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer circle"></div></td>
                                                                <td><div class="shimmer circle"></div></td>
                                                                <td><div class="shimmer circle"></div></td>
                                                                <td><div class="shimmer circle"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line end"></div></td>
                                                            </tr>
                                                        </tbody>
                                                        <tfoot>
                                                            
                                                        </tfoot>
                                                        <tbody>
                                                        
                                                        
                                                        
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
						</div>
                    </div>

                    <div class="tab-pane fade show" id="tabDataAnggota" role="tabpanel" aria-labelledby="tabDataAnggota">
						<div class="row">
                            <div class="col-sm-12">
                                <div class="card mt-3">
                                    <div class="card-header text-primary font-weight-bold">
                                        Filter Data
                                    </div>
                                    <div class="card-body">
                                        <div class="col-sm-12 col-xl-12 col-lg-12 float-right">
                                            <div class="row">
                                                <div class="col-sm-4 mb-2">
                                                    <label class="control-label font-weight-bold">Jenis Keanggotaan</label>
                                                    <select id="selectJenisKeanggotaan" class="form-control">
                                                        <option value="all">Semua</option>
                                                        <option value="1">Sudah Memiliki KTAN</option>
                                                        <option value="0">Belum Memiliki KTAN</option>
                                                    </select>
                                                </div>
                                                <div class="col-sm-4 mb-2">
                                                    <label class="control-label font-weight-bold">Jenjang Pendidikan</label>
                                                    <select id="selectJenjang" class="form-control">
                                                        <option value="">Semua</option>
                                                        <option value="SMK-F / SMF / SAA">SMK-F / SMF / SAA</option>
                                                        <option value="D3 FARMASI">D3 FARMASI</option>
                                                        <option value="D3 ANAFARMA">D3 ANAFARMA</option>
                                                        <option value="D3 NON-FARMASI">D3 NON-FARMASI</option>
                                                        <option value="S1 FARMASI">S1 FARMASI</option>
                                                        <option value="S1 NON-FARMASI">S1 NON-FARMASI</option>
                                                        <option value="S2 FARMASI">S2 FARMASI</option>
                                                        <option value="S2 NON-FARMASI">S2 NON-FARMASI</option>
                                                        <option value="S3 FARMASI">S3 FARMASI</option>
                                                        <option value="S3 NON-FARMASI">S3 NON-FARMASI</option>
                                                    </select>
                                                </div>
                                                <div class="col-sm-4 mb-2">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card mt-3">
                                    <div class="card-header text-primary font-weight-bold">
                                       Kelola Data Anggota
                                    </div>
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-sm-12">
                                            
                                                <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#modalTambahAnggotaManual"><i class="fas fa-plus"></i> Tambah Anggota Manual</button>
                                                <button type="button" class="btn btn-secondary" id="btnDownloadAnggota"><i class="fas fa-download"></i> Download Data Anggota</button>
                                                <div class="table-responsive">
                                                    <table class="table table-bordered table-hover" id="tableDataAnggota" width="100%" cellspacing="0">
                                                        <thead>
                                                            <tr>
                                                                <th data-column="name"  rowspan="2">Nama Anggota</th>
                                                                <th data-column="email" rowspan="2">Email</th>
                                                                <th rowspan="2">No Hp</th>
                                                                <th data-column="ktan" rowspan="2">No KTAN</th>
                                                                <th rowspan="2">No STRTTK</th>
                                                                <th colspan="4" class="text-center">Pendidikan</th>
                                                                <th data-column="jenis_kelamin" rowspan="2">Jenis Kelamin</th>
                                                                <th data-column="tempat_lahir"  rowspan="2">Tempat Lahir</th>
                                                                <th data-column="tanggal_lahir" rowspan="2">Tanggal Lahir</th>
                                                                <th data-column="ktan_expires_at" rowspan="2">Masa Berlaku KTAN</th>
                                                                <th rowspan="2">Foto</th>
                                                                <th rowspan="2">KTAN</th>
                                                                <th rowspan="2">KTP</th>
                                                                <th rowspan="2">Ijazah</th>
                                                                <th rowspan="2">Aksi</th>
                                                            </tr>
                                                            <tr>
                                                                <th>Jenjang Pendidikan</th>
                                                                <th>Nama Institusi</th>
                                                                <th>Tahun Lulus</th>
                                                                <th>Tanggal Ijazah</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer circle"></div></td>
                                                                <td><div class="shimmer circle"></div></td>
                                                                <td><div class="shimmer circle"></div></td>
                                                                <td><div class="shimmer circle"></div></td>
                                                                <td><div class="shimmer title-line end"></div></td>
                                                            </tr>
                                                            <tr>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer circle"></div></td>
                                                                <td><div class="shimmer circle"></div></td>
                                                                <td><div class="shimmer circle"></div></td>
                                                                <td><div class="shimmer circle"></div></td>
                                                                <td><div class="shimmer title-line end"></div></td>
                                                            </tr>
                                                            <tr>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer circle"></div></td>
                                                                <td><div class="shimmer circle"></div></td>
                                                                <td><div class="shimmer circle"></div></td>
                                                                <td><div class="shimmer circle"></div></td>
                                                                <td><div class="shimmer title-line end"></div></td>
                                                            </tr>
                                                        </tbody>
                                                        <tfoot>
                                                            
                                                        </tfoot>
                                                        <tbody>
                                                        
                                                        
                                                        
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
						</div>
                    </div>

                    <div class="tab-pane fade show" id="tabDataRegistrasiAnggotaLama" role="tabpanel" aria-labelledby="tab-verifikasi-ktan">
						<div class="row mt-3">
                            <div class="col-sm-12">
                                <div class="card mb-3">
                                    <div class="card-header font-weight-bold text-primary">
                                        Filter Data
                                    </div>
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-sm-4">
                                                <label class="control-label font-weight-bold">Status Approvement</label>
                                                <select id="selectStatusRegist" class="form-control">
                                                    <option value="0">Pending</option>
                                                    <option value="1">Disetujui</option>
                                                    <option value="">Semua</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header font-weight-bold text-primary">
                                        Data Registrasi Anggota Lama
                                    </div>
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-sm-5 col-xl-5 col-lg-5 text-center">
                                                <div class="row">
                                                    <div class="col-sm-8 mb-3">
                                                    
                                                    </div>
                                                    <div class="col-sm-4 d-none">
                                                        <button type="button" class="btn btn-primary" title="Aprrove" id="btnVerifikasiAllAnggotaLama">
                                                            <i class="fas fa-check"></i>
                                                        </button>
                                                        <button type="button" class="btn btn-light border" title="Print" id="btnPrint">
                                                            <i class="fas fa-print"></i>
                                                        </button>
                                                        <button type="button" class="btn btn-light border" title="Downloads" id="btnDownload">
                                                            <i class="fas fa-download"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row mt-3">
                                            <div class="col-sm-12">
                                                <div class="table-responsive">
                                                    <table class="table table-bordered table-hover" id="tableRegistrasiAnggotaLama" width="100%" cellspacing="0">
                                                        <thead>
                                                            <tr>
                                                                <th class="text-center"><input type="checkbox" id="checkAllRegis"></th>
                                                                <th data-column="created_at">Tanggal Registrasi</th>
                                                                <th data-column="name">Nama Anggota</th>
                                                                <th data-column="name">No Hp</th>
                                                                <th data-column="ktan">No KTAN</th>
                                                                <th>NIK</th>
                                                                <th data-column="email">Email</th>
                                                                <th>Masa Berlaku KTAN</th>
                                                                <th>KTAN Lama</th>
                                                                <th>Periode Iuran Terakhir (Bulan Awal)</th>
                                                                <th>Periode Iuran Terakhir (Bulan Akhir)</th>
                                                                <th>Bukti Iuran Terakhir</th>
                                                                <th data-column="status_pc">Status</th>
                                                                <th>Aksi</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td><div class="shimmer title-line end"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer circle"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer circle"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line end"></div></td>
                                                            </tr>
                                                            <tr>
                                                                <td><div class="shimmer title-line end"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer circle"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer circle"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line end"></div></td>
                                                            </tr>
                                                            <tr>
                                                                <td><div class="shimmer title-line end"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer circle"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer circle"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line end"></div></td>
                                                            </tr>
                                                        </tbody>
                                                        <tfoot>
                                                            
                                                        </tfoot>
                                                        <tbody>
                                                        
                                                        
                                                        
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
						</div>
                    </div>


				</div>
			</div>
		</div>

        <div class="modal fade" id="commentModalUserKTAN" tabindex="-1" role="dialog" aria-labelledby="commentModal"
        aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header text-primary font-weight-bold">
                        Komentar Pengajuan KTAN
                        <button class="close" type="button" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="chat-body" id="content-comment"  style="height:400px; overflow-y:auto">
                                    <div style="width:100%">
                                        <div style="width:10%;" class="float-left">
                                            <div class="shimmer circle"></div>
                                        </div>
                                        <div class="float-left text-dark text-left bubble-shimmer-left" style="width:88%;">
                                            <div class="shimmer content-line"></div>
                                            <div class="shimmer content-line"></div>
                                            <div class="shimmer content-line end"></div>
                                        </div>
                                    </div>
                                    <div style="width:100%">
                                        <div style="width:10%;" class="float-right">
                                            <div class="shimmer circle"></div>
                                        </div>
                                        <div class="float-right text-dark text-left bubble-shimmer-right" style="width:88%;">
                                            <div class="shimmer content-line"></div>
                                            <div class="shimmer content-line"></div>
                                            <div class="shimmer content-line end"></div>
                                        </div>
                                    </div>
                                    <div style="width:100%">
                                        <div style="width:10%;" class="float-left">
                                            <div class="shimmer circle"></div>
                                        </div>
                                        <div class="float-left text-dark text-left bubble-shimmer-left" style="width:88%;">
                                            <div class="shimmer content-line"></div>
                                            <div class="shimmer content-line"></div>
                                            <div class="shimmer content-line end"></div>
                                        </div>
                                    </div>
                                    <div style="width:100%">
                                        <div style="width:10%;" class="float-right">
                                            <div class="shimmer circle"></div>
                                        </div>
                                        <div class="float-right text-dark text-left bubble-shimmer-right" style="width:88%;">
                                            <div class="shimmer content-line"></div>
                                            <div class="shimmer content-line"></div>
                                            <div class="shimmer content-line end"></div>
                                        </div>
                                    </div>
                                </div>
                                <form id="form-comment-user-ktan">
                                    <label class="control-label font-weight-bold">Komentar</label>
                                    <textarea class="form-control" id="komentarPengajuanKTANUser" name="komentar" placeholder="Komentar..." required></textarea>
                                    <br>
                                    <button type="button" class="form-control btn btn-sm btn-primary" id="btnSendKomentarPengajuanKTAN"><i class='fas fa-comment'></i> Berikan Komentar</button>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-secondary" id="btnCancel" type="button" data-dismiss="modal">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="modal fade" id="commentModal" tabindex="-1" role="dialog" aria-labelledby="commentModal"
        aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header text-primary font-weight-bold">
                        Revisi & Berikan Komentar
                        <button class="close" type="button" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-sm-12">
                                <form id="form-comment">
                                    <label class="control-label font-weight-bold">Komentar</label>
                                    <textarea class="form-control" id="komentarPengajuan" name="komentar" placeholder="Komentar..." required></textarea>
                                    <br>
                                    <button type="button" class="form-control btn btn-sm btn-primary" id="btnSendKomentarPengajuan"><i class='fas fa-comment'></i> Revisi & Berikan Komentar</button>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-secondary" id="btnCancel" type="button" data-dismiss="modal">Cancel</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade" id="commentModalRegist" tabindex="-1" role="dialog" aria-labelledby="commentModalRegist"
        aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header font-weight-bold text-primary">
                        Tolak Registrasi & Berikan Alasan Penolakan
                        <button class="close" type="button" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-sm-12">
                                <form id="form-comment-regist">
                                    <label class="control-label font-weight-bold">Alasan Penolakan Registrasi</label>
                                    <textarea class="form-control" id="komentarRegistrasi" name="komentar" placeholder="Alasan Penolakan..." required></textarea>
                                    <br>
                                    <button type="button" class="form-control btn btn-sm btn-primary" id="btnSendKomentarRegistrasi"><i class='fas fa-comment'></i> Tolak & Berikan Alasan</button>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-secondary" id="btnCancel" type="button" data-dismiss="modal">Cancel</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade" id="editExpiredKTANModal" tabindex="-1" role="dialog" aria-labelledby="editExpiredKTANModal"
        aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header font-weight-bold text-primary">
                        Ubah Masa Berlaku KTAN
                        <button class="close" type="button" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-sm-12">
                                <form id="form-ktan-expired">
                                    <label class="control-label font-weight-bold">Nama Anggota</label>
                                    <input type="text" class="form-control" id="textNamaAnggota" readonly>
                                    <label class="control-label font-weight-bold">Email</label>
                                    <input type="email" class="form-control" id="textEmail" readonly>
                                    <label class="control-label font-weight-bold">KTAN</label>
                                    <input type="text" class="form-control" id="textKTAN" readonly>
                                    <label class="control-label font-weight-bold">Masa Berlaku KTAN</label>
                                    <input type="date"  pattern="\d{2}-\d{2}-\d{4}" class="form-control" id="textMasaBerlakuKTAN">
                                    <br>
                                    <button type="button" class="form-control btn btn-sm btn-primary" id="btnUpdateExpiredKTAN"><i class='fas fa-save'></i> Update Data</button>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-secondary" id="btnCancel" type="button" data-dismiss="modal">Cancel</button>
                    </div>
                </div>
            </div>
        </div>

        
        <div class="modal fade" id="editNIKAnggotaModal" tabindex="-1" role="dialog" aria-labelledby="editNIKAnggotaModal"
        aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title text-primary font-weight-bold">Edit NIK Anggota</h5>
                        <button class="close" type="button" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="false" class="text-default">×</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="form-group">
                            <label class="control-label font-weight-bold">NIK Anggota</label>
                            <input type="text" name="NIK" class="form-control" id="textNIKAnggota" aria-describedby="nik" placeholder="NIK Anggota">
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-secondary" id="btnCancel" type="button" data-dismiss="modal">Cancel</button>
                        <a class="btn btn-primary" id="btnSaveNIK"><i class="fas fa-save"></i> Simpan</a>
                    </div>
                </div>
            </div>
        </div>

        
        <div class="modal fade bd-example-modal-lg" id="editAnggotaModal" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title text-primary font-weight-bold">Edit Data Anggota</h5>
                        <button class="close" type="button" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="false" class="text-default">×</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="alert alert-info">
                            <strong>Informasi :</strong><br>
                            Periksa data anggota dengan teliti sebelum anda melakukan perubahan data anggota, terimakasih.
                        </div>
                        <form class="user" class="form-horizontal" id='form-update-anggota'>
                            <div class="form-group">
                                <label class="control-label font-weight-bold">NIK</label>
                                <input type="text" name="NIK" class="form-control" id="registerTextNIK" aria-describedby="ktan" placeholder="NIK" readonly>
                            </div>
                            <div class="form-group">
                                <label class="control-label font-weight-bold">Nama Lengkap</label>
                                <input type="text" name="register_nama_lengkap" class="form-control" id="registerTextNamaLengkap" aria-describedby="register_nama_lengkap" placeholder="Nama Lengkap..." required>
                            </div>
                            <div class="form-group">
                                <label class="control-label font-weight-bold">No HP</label>
                                <input type="number" name="register_no_hp" class="form-control" id="registerNumberNoHP" aria-describedby="register_no_hp" placeholder="No Hp..." required oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                type = "number"
                                maxlength = "14">
                            </div>
                            <div class="form-group">
                                <label class="control-label font-weight-bold">Email</label>
                                <input type="email" name="register_email" class="form-control" id="registerEmail" aria-describedby="register_email" placeholder="Email..." required>
                            </div>
                            <div class="form-group">
                                <label class="control-label font-weight-bold">Jenis Kelamin</label>
                                <select class="form-control" id="registerSelectJenisKelamin" name="register_jenis_kelamin" placeholder="Jenis Kelamin" required>
                                <option value="">Jenis Kelamin</option>
                                <option value="L">Laki Laki</option>
                                <option value="P">Perempuan</option>
                                </select>
                            </div>
                            <div class="form-group">
                                <label class="control-label font-weight-bold">Tanggal Lahir</label>
                                <input placeholder="Tanggal Lahir..." class="textbox-n form-control" type="date" id="registerTanggalLahir" required min="1900-01-01" max="2006-12-31"/>
                            </div>
                            
                            <button type="submit" id="btnUpdateAnggota" style="margin-top:15px;" class="btn btn-primary btn-user btn-block">Update Data Anggota</button>
                        </form>
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-secondary" id="btnCancel" type="button" data-dismiss="modal">Cancel</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade" id="modalTambahAnggotaManual" tabindex="-1" role="dialog" aria-labelledby="modalTambahAnggotaManual"
        aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Tambah Anggota Manual</h5>
                        <button class="close" type="button" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <form id="form-tambah-anggota-manual">
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="form-group row">
                                        <label class="control-label col-sm-12 font-weight-bold">Nama Anggota</label>
                                        <div class="col-sm-12">
                                            <input type="text" class="form-control" id="textNamaAnggotaManual" required placeholder="Nama Anggota...">
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="control-label col-sm-12 font-weight-bold">KTAN</label>
                                        <div class="col-sm-12">
                                            <input type="text" class="form-control" id="textKTANAnggotaManual" required placeholder="KTAN...">
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="control-label col-sm-12 font-weight-bold">Email Anggota</label>
                                        <div class="col-sm-12">
                                            <input type="email" class="form-control" id="emailAnggota" required placeholder="Email Anggota...">
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="control-label col-sm-12 font-weight-bold">NIK Anggota</label>
                                        <div class="col-sm-12">
                                            <input type="number" class="form-control" id="nikAnggota" required placeholder="NIK Anggota...">
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="control-label col-sm-12 font-weight-bold">Masa Aktif KTAN</label>
                                        <div class="col-sm-12">
                                            <input type="date"  pattern="\d{2}-\d{2}-\d{4}" class="form-control" id="masaAktifKTAN" required placeholder="Masa Aktif KTAN...">
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="control-label col-sm-12 font-weight-bold">Iuran Terakhir (Dari Bulan)</label>
                                        <div class="col-sm-12">
                                            <input type="month" class="form-control" id="textIuranTerakhirDariBulan" required>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="control-label col-sm-12 font-weight-bold">Iuran Terakhir (Sampai Bulan)</label>
                                        <div class="col-sm-12">
                                            <input type="month" class="form-control" id="textIuranTerakhirSampaiBulan" required>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-secondary" id="btnCancel" type="button" data-dismiss="modal">Cancel</button>
                        <a class="btn btn-primary" id="btnSaveAnggotaManual">Save Changes</a>
                    </div>
                </div>
            </div>
        </div>

        
        <div class="modal fade" id="commentModalDelete" tabindex="-1" role="dialog" aria-labelledby="commentModalDelete"
        aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Hapus Anggota & Berikan Alasan</h5>
                        <button class="close" type="button" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-sm-12">
                                <form id="form-comment-regist">
                                    <input type="hidden" id="textHideUUID">
                                    <label class="control-label font-weight-bold">Alasan Penghapusan Anggota</label>
                                    <textarea class="form-control" id="komentarPenghapusan" name="komentar" placeholder="Alasan Penghapusan..." required></textarea>
                                    <br>
                                    <button type="button" class="form-control btn btn-sm btn-primary" id="btnSendCommentDelete"><i class='fas fa-comment'></i> Hapus & Berikan Alasan</button>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-secondary" id="btnCancel" type="button" data-dismiss="modal">Cancel</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade" id="modalDownloadKeanggotaan" tabindex="-1" role="dialog" aria-labelledby="modalDownloadKeanggotaan"
        aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title font-weight-bold text-primary">Silakan Pilih Jenis SIPTTK Anggota</h5>
                        <button class="close" type="button" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-sm-12">
                                <form id="form-download-keanggotaan">
                                    <input type="hidden" id="textHideSIPTTKId">
                                    <label class="control-label font-weight-bold">SIPTTK Ke</label>
                                    <select class="form-control" id="selectSIPTTK" name="selectSIPTTK" placeholder="Pilih SIPTTK" required>
                                        <option id="" value="">Pilih SIPTTK</option>
                                    </select>
                                    <br>
                                    <label class="control-label font-weight-bold">Nomor Urut Surat</label>
                                    <input type="number" class="form-control" id="nomorUrutSuratKeterangan" placeholder = "Contoh : 0001" required>
                                    <br>
                                    <button type="button" class="form-control btn btn-sm btn-primary" id="btnActionDownloadKeanggotaan"><i class='fas fa-download'></i> Download Surat Keterangan Keanggotaan</button>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-secondary" id="btnCancel" type="button" data-dismiss="modal">Cancel</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade bd-example-modal-lg" id="detailAnggotaModal" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-xl">
                <div class="modal-content">
                    <div class="modal-header font-weight-bold text-primary">
                        Detail Anggota
                        <button class="btn btn-sm btn-primary" id="btnPrintBiodata"><i class="fas fa-print"></i> Cetak Biodata Angota</button>
                    </div>
                    <div class="modal-body" id="biodataPrint">
                        <div class="row">
                            <div class="col-sm-12 pr-4">
                                <div class="row mt-3 ">
                                    <div class="col-sm-5 col-xl-5 col-lg-5 mx-auto pt-3 mb-3 text-center">
                                        <img loading="lazy" id="detailAvatar" src="images/logo.png" width="30%">
                                    </div>
                                    <div class="col-sm-5 col-xl-5 col-lg-5 mx-auto pt-3">
                                        <h3 class="font-weight-bold" id="detailNamaAnggota">Nama Anggota</h3>
                                        <div class="row text-primary">
                                            <div class="col-sm-1 font-weight-bold">
                                                <i class="fas fa-id-card"></i>
                                            </div>
                                            <div class="col-sm-11" id="detailKTAN">
                                                
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-1 font-weight-bold">
                                                <i class="fas fa-phone-alt"></i>
                                            </div>
                                            <div class="col-sm-11" id="detailNoHP">
                                                
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-1 font-weight-bold">
                                                <i class="fas fa-at"></i>
                                            </div>
                                            <div class="col-sm-11" id="detailEmailHead">
                                                
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-5 mx-auto pt-3">
                                        <h5 class="text-primary border-bottom border-primary pb-2"><i class="fas fa-user"></i> Data Umum</h5>
                                        <div class="row mb-3">
                                            <div class="col-sm-4 font-weight-bold">
                                                NIK
                                            </div>
                                            <div class="col-sm-8" id="detailNIK">
                                                
                                            </div>
                                        </div>
                                        <div class="row mb-3">
                                            <div class="col-sm-4 font-weight-bold">
                                                Email
                                            </div>
                                            <div class="col-sm-8" id="detailEmail">
                                                
                                            </div>
                                        </div>
                                        <div class="row mb-3">
                                            <div class="col-sm-4 font-weight-bold">
                                                Tempat Lahir
                                            </div>
                                            <div class="col-sm-8" id="detailTempatLahir">
                                                
                                            </div>
                                        </div>
                                        <div class="row mb-3">
                                            <div class="col-sm-4 font-weight-bold">
                                                Tanggal Lahir
                                            </div>
                                            <div class="col-sm-8" id="detailTanggalLahir">
                                                
                                            </div>
                                        </div>
                                        <div class="row mb-3">
                                            <div class="col-sm-4 font-weight-bold">
                                                Jenis Kelamin
                                            </div>
                                            <div class="col-sm-8" id="detailJenisKelamin">
                                                
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div class="col-sm-5 pt-3 mx-auto">
                                        <h5 class="text-primary border-bottom border-primary pb-2"><i class="fas fa-map-marker-alt"></i> Alamat</h5>
                                        <div class="row mb-3">
                                            <div class="col-sm-5 font-weight-bold">
                                                Alamat
                                            </div>
                                            <div class="col-sm-7" id="detailAlamat">
                                                
                                            </div>
                                        </div>
                                        <div class="row mb-3">
                                            <div class="col-sm-5 font-weight-bold">
                                                Provinsi
                                            </div>
                                            <div class="col-sm-7" id="detailProvinsi">
                                                
                                            </div>
                                        </div>
                                        <div class="row mb-3">
                                            <div class="col-sm-5 font-weight-bold">
                                                Kota / Kabupaten
                                            </div>
                                            <div class="col-sm-7" id="detailKota">
                                                
                                            </div>
                                        </div>
                                        <div class="row mb-3">
                                            <div class="col-sm-5 font-weight-bold">
                                                Kecamatan
                                            </div>
                                            <div class="col-sm-7" id="detailKecamatan">
                                                
                                            </div>
                                        </div>
                                        <div class="row mb-3">
                                            <div class="col-sm-5 font-weight-bold">
                                                Kelurahan / Desa
                                            </div>
                                            <div class="col-sm-7" id="detailKelurahan">
                                                
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-sm-5 font-wight-bold pt-3 mx-auto">
                                        <h5 class="text-primary border-bottom border-primary pb-2"><i class="fas fa-money-check"></i> Bukti Bayar</h5>
                                        <div class="row mb-3">
                                            <div class="col-sm-12 text-center">
                                                <table width="100%">
                                                    <tr>
                                                        <td id="detailBuktiBayar">Tidak ada bukti bayar !</td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-sm-5 overflow-auto font-wight-bold pt-3 mx-auto">
                                        <h5 class="text-primary border-bottom border-primary pb-2"><i class="fas fa-graduation-cap"></i> Pendidikan</h5>
                                        <div class="row mb-3">
                                            <div class="col-sm-12 text-center">
                                                <table class="table table-bordered" width="100%" id="detailPendidikan">

                                                </table>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-sm-11 overflow-auto font-wight-bold pt-3 mx-auto">
                                        <h5 class="text-primary border-bottom border-primary pb-2"><i class="fas fa-briefcase"></i> Pekerjaan</h5>
                                        <div class="row mb-3">
                                            <div class="col-sm-12 text-center">
                                                <table class="table table-bordered" width="100%" id="detailPekerjaan">
                                                
                                                </table>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-sm-5 overflow-auto font-wight-bold pt-3 mx-auto">
                                        <h5 class="text-primary border-bottom border-primary pb-2"><i class="fas fa-certificate"></i> Serkom</h5>
                                        <div class="row mb-3">
                                            <div class="col-sm-12 text-center">
                                                <table class="table table-bordered" width="100%" id="detailSerkom">
                                                
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div class="col-sm-5 font-wight-bold pt-3 mx-auto">
                                        <h5 class="text-primary border-bottom border-primary pb-2"><i class="fas fa-file"></i> STRTTK</h5>
                                        <div class="col-sm-12 text-center overflow-auto">
                                            <table class="table table-bordered" width="100%" id="detailSTR">
                                            
                                            </table>
                                        </div>
                                    </div>

                                    <div class="col-sm-11 font-wight-bold pt-3 mx-auto">
                                        <h5 class="text-primary border-bottom border-primary pb-2"><i class="fas fa-id-badge"></i> SIPTTK</h5>
                                        <div class="row mb-3">
                                            <div class="col-sm-12 text-center overflow-auto">
                                                <table class="table table-bordered" width="100%" id="detailSIP">
                                                
                                                </table>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                        
                        
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-secondary" id="btnCancel" type="button" data-dismiss="modal">Cancel</button>
                    </div>
                </div>
            </div>
        </div>

        
        <div class="modal fade bd-example-modal" id="modalGenerateReport" tabindex="-1" role="dialog" aria-labelledby="modal" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title text-primary font-weight-bold">Filter Laporan</h5>
                        <button class="close" type="button" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="false" class="text-default">×</span>
                        </button>
                    </div>
                    <div class="modal-body">

                        <div class="row">
                            <div class="col-sm-12 mb-2">
                                <label class="control-label font-weight-bold">Generate Berdasarkan</label>
                                <select id="selectFilter" class="form-control">
                                    <option value=''>Pilih Laporan Berdasarkan</option>
                                    <option value='pendidikan'>Pendidikan</option>
                                    <option value='pekerjaan'>Pekerjaan</option>
                                </select>
                            </div>
                            <div class="col-sm-12 mb-2">
                                <label class="control-label font-weight-bold">Tipe Dokumen</label>
                                <select id="selectDocument" class="form-control">
                                    <option value=''>Pilih Tipe Dokumen</option>
                                    <option value='pdf'>PDF</option>
                                    <option value='excel'>Excel</option>
                                </select>
                            </div>
                            <div class="col-sm-12 mb-2">
                                <button class="btn btn-primary btn-xl" id="btnGenerateReport"><i class="fas fa-download"></i> Download Report</button>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-secondary" id="btnCancel" type="button" data-dismiss="modal">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
        
        
        
        `;
		return view;
	  },

	async afterRender() {
		await AnggotaPCPresenter.init();
        await  ImagePopUPInitiator.init();
	},

	_errorContent(container) {
		const errorContent = document.getElementById('main-content');
		errorContent.innerHTML = `<div class="msg-failed">
                             <span class="fa fa-pencil"></span>
                              <p class="center">Maaf , Aplikasi tidak dapat di tampilkan. Periksa kembali internet anda ya &#128522</p>
                             </div>`;
	},

};

export default AnggotaPC;