import ApiRole from "../../api/api_pengurus_pp/data-role.js";
import NotificationModal from "../../utils/initial_notification.js";

const DownloadSuratMandatPCPresenter = {

	async init() {
		
		await StorageAnggotaPD.makeStore()
		await this._setView();
		await this.renderGetUserByKtan()
		await this._generateSurat();
	},

	async _setView() {
		document.getElementById('main-content').classList.remove('bg-gradient-primary');
		document.getElementById('main-content').style.removeProperty("height");
		document.getElementById('main-content').style.removeProperty("padding-top");
		document.getElementById('content-wrapper').style.removeProperty("overflow-x");
		document.getElementById('appbar').classList.remove('d-none');
		document.getElementById('accordionSidebar').classList.remove('d-none');
		document.getElementById("main-content").style.height = "";
		
		const formattedNumberInput = document.getElementById('textNian');
		const blocks = [4, 8, 1, 6]; // Jumlah digit pada setiap blok
		const totalLength = blocks.reduce((total, num) => total + num, 0);
	  
		formattedNumberInput.addEventListener('input', (event) => {
		  const inputValue = event.target.value;
		  const validInput = inputValue.replace(/\D/g, ''); // Hapus karakter non-digit
	  
		  // Potong input jika panjang melebihi total panjang blok
		  const formattedValue = validInput.slice(0, totalLength);
	  
		  let formattedResult = '';
		  let index = 0;
	  
		  for (const block of blocks) {
			const chunk = formattedValue.slice(index, index + block);
			formattedResult += chunk;
	  
			if (formattedValue.length > index + block) {
			  formattedResult += '.';
			}
	  
			index += block;
		  }
	  
		  event.target.value = formattedResult;
	  
		  // Nonaktifkan input jika format sudah mencapai panjang yang diinginkan
		  if (formattedValue.length >= totalLength) {
			event.preventDefault();
		  }
		});
	},
	async _generateSurat() {
		const eventGenerateSurat = async (e) => {
			e.preventDefault();
			const btnGenerate = document.getElementById("btnGenerate");
			btnGenerate.innerHTML = `<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>`;
	
			let selectJenisSurat = document.getElementById("selectJenisSurat").value;
			let textNomorSurat = document.getElementById("textNomorSurat").value;
			let tanggalSurat = document.getElementById("dateTanggal").value;
	
			// Ambil semua KTAN yang ada di StorageAnggotaPD
			let storedData = await StorageAnggotaPD.getData();
			let listNian = storedData.map(item => item.ktan); // Array berisi semua KTAN
			
			if (selectJenisSurat === "" || textNomorSurat === "" || tanggalSurat === "" || listNian.length === 0) {
				NotificationModal.show("Harap lengkapi semua formulir isian!", "warning");
			} else {
				let response = await ApiRole.downloadSuratMandat({
					jenisSurat: selectJenisSurat,
					nomorSurat: textNomorSurat,
					tanggalSurat: tanggalSurat,
					nian: listNian, // Mengirim array KTAN
					adminType: "admin_pc",
				});
	
				NotificationModal.show("Generate Surat Berhasil!", "success");
			}
	
			btnGenerate.innerHTML = `<i class="fas fa-download"></i> Generate`;
		};
	
		document.getElementById("btnGenerate").addEventListener("click", eventGenerateSurat);
	},	

	async renderGetUserByKtan() {
		const textNian = document.getElementById("textNian");
		const textName = document.getElementById("textName");
		const textEmail = document.getElementById("textEmail");
		const userId = document.getElementById("userId");
		const addToList = document.getElementById("addToList");
	
		const tableAdmin = $("#tableAdmin").DataTable({
			destroy: true, // Agar bisa di-reinitialize
			responsive: true,
			autoWidth: false,
			columns: [
				{ title: "KTAN", data: "ktan" },
				{ title: "Nama", data: "name" },
				{ title: "Opsi", data: "opsi", orderable: false }
			]
		});
	
		const eventGetUser = async () => {
			let ktanValue = textNian.value.trim();
	
			if (ktanValue === "") {
				resetUserFields();
				return;
			}
	
			try {
				textName.setAttribute("placeholder", "Loading...");
				textEmail.setAttribute("placeholder", "Loading...");
				let response = await ApiRole.getUserByKTAN(ktanValue);
	
				if (response.status_code === 200) {
					textName.value = response.data.name || "";
					textEmail.value = response.data.email || "";
					textName.classList.remove("is-invalid");
					textEmail.classList.remove("is-invalid");
					textName.classList.add("is-valid");
					textEmail.classList.add("is-valid");
				} else {
					showUserNotFound();
				}
			} catch (error) {
				console.error("Error fetching user:", error);
				showUserNotFound();
			}
		};
	
		const resetUserFields = () => {
			textName.setAttribute("placeholder", "Nama Lengkap");
			textEmail.setAttribute("placeholder", "Email");
			textName.value = "";
			textEmail.value = "";
			userId.value = "";
			textName.classList.remove("is-invalid", "is-valid");
			textEmail.classList.remove("is-invalid", "is-valid");
		};
	
		const showUserNotFound = () => {
			textName.setAttribute("placeholder", "User Tidak Ditemukan");
			textEmail.setAttribute("placeholder", "User Tidak Ditemukan");
			textName.value = "";
			textEmail.value = "";
			textName.classList.remove("is-valid");
			textEmail.classList.remove("is-valid");
			textName.classList.add("is-invalid");
			textEmail.classList.add("is-invalid");
		};
	
		const renderTableData = async () => {
			let data = await StorageAnggotaPD.getData();
			
			// Tambahkan tombol hapus ke setiap baris data
			let dataWithDelete = data.map(item => ({
				...item,
				opsi: `<button class="btn btn-danger btn-sm delete-btn" data-ktan="${item.ktan}">Hapus</button>`
			}));
	
			tableAdmin.clear().rows.add(dataWithDelete).draw();
			
			// Tambahkan event listener untuk tombol hapus
			$("#tableAdmin tbody").on("click", ".delete-btn", async function () {
				let ktan = $(this).data("ktan");
				await deleteRow(ktan);
			});
		};
	
		const eventPushDataToList = async (e) => {
			e.preventDefault();
			let ktanValue = textNian.value.trim();
			let nameValue = textName.value.trim();
	
			if (ktanValue && nameValue) {
				await StorageAnggotaPD.pushData(ktanValue, nameValue);
				await renderTableData(); // Update tabel setelah menambahkan data
			}
		};
	
		const deleteRow = async (ktan) => {
			await StorageAnggotaPD.deleteData(ktan);
			await renderTableData(); // Perbarui tabel setelah menghapus data
		};
	
		textNian.addEventListener("keyup", eventGetUser);
		addToList.addEventListener("click", eventPushDataToList);
	
		// Load data pertama kali
		renderTableData();
	}
	
	
	



}


const StorageAnggotaPD = {

	async makeStore() {
		this._data = [];
	},

	isReady(kode) {
		const result = this._data.map((e) => { return e.id_pengajuan; }).indexOf(kode);
		let value = false
		if (result != -1) {
			value = true
		}
		return value;
	},

	async pushData(ktan,name) {
		if (this.isReady(ktan, name)) return
		let gold = {
			"ktan": ktan,
			"name": name,
		}
		this._data.push(gold);
	},

	async deleteData(kode) {
		let removeIndex = this._data.map((item) => { return item.ktan; }).indexOf(`${kode}`);
		this._data.splice(removeIndex, 1)
	},

	async size() {
		return this._data.length
	},

	async getData() {
		return this._data;
	}
}



export default DownloadSuratMandatPCPresenter;